import { SHA3 } from "sha3";

export const generateSecureHash = (value, salt) => {
  // salt is 32 bit hexadecimal - 2 char per byte(64)

  // return early if no value, salt or incorrect salt character length
  if (!value || !salt || salt.length != 64) return;

  var hash = new SHA3(256).update(value + salt);

  return hash.digest("hex");
};
