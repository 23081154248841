import React from "react";
import _ from "lodash";

import { truncateString } from "../../utils/data_helpers";

const TableCell = ({ row, column, isOriginalData }) => {
  const getClassName = (row, columnName, originalData) => {
    if (originalData) {
      return getOriginalDataClassName(row, columnName, row.columns_with_errors[row.mapping[columnName]]);
    }

    return getClaimDataClassName(row, columnName, row.columns_with_errors[columnName]);
  };

  const getClaimDataClassName = (row, column, invalidCell) => {
    if (invalidCell) {
      return "data-table__row__item data-table__row__item--invalid";
    } else {
      return "data-table__row__item";
    }
  };

  const getOriginalDataClassName = (row, column, invalidCell) => {
    let isMappedColumn = _.values(row.mapping).includes(column);

    if (row.selected && invalidCell) {
      return "data-table__row__item data-table__row__item--invalid";
    } else if (row.selected && isMappedColumn) {
      return "data-table__row__item data-table__row__item--mapped";
    } else if (row.selected) {
      return "data-table__row__item data-table__row__item--selected";
    } else {
      return "data-table__row__item";
    }
  };

  const getHeaderValue = () => {
    if (isOriginalData) {
      return row.data[column];
    }

    return row.data[column];
  };

  return (
    <td className={getClassName(row, column, isOriginalData)}>
      <div className="t--sm t--500">{truncateString(getHeaderValue(), 18)}</div>
    </td>
  );
};

export default TableCell;
