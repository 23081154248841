import React, { useState, useEffect, useRef } from "react";
import illustration from "~/images/invite_or_find_illustration.png";
import _ from "lodash";
import AbbvieDesignations from "../manufacturers/abbvie/designations/AbbvieDesignations";
import AmgenDesignations from "../manufacturers/amgen/designations/AmgenDesignations";
import BauschDesignations from "../manufacturers/bausch/designations/BauschDesignations";
import BayerDesignations from "../manufacturers/bayer/designations/BayerDesignations";
import BiDesignations from "../manufacturers/bi/designations/BiDesignations";
import BiogenDesignations from "../manufacturers/biogen/designations/BiogenDesignations";
import BmsDesignations from "../manufacturers/bms/designations/BmsDesignations";
import EmdSeronoDesignations from "../manufacturers/emd_serono/designations/EmdSeronoDesignations";
import ExelixisDesignations from "../manufacturers/exelixis/designations/ExelixisDesignations";
import GileadDesignations from "../manufacturers/gilead/designations/GileadDesignations";
import GskDesignations from "../manufacturers/gsk/designations/GskDesignations";
import JohnsonNJohnsonDesignations from "../manufacturers/johnson_n_johnson/designations/JohnsonNJohnsonDesignations";
import MerckDesignations from "../manufacturers/merck/designations/MerckDesignations";
import NovoNordiskDesignations from "../manufacturers/novo_nordisk/designations/NovoNordiskDesignations";
import NovartisDesignations from "../manufacturers/novartis/designations/NovartisDesignations";
import PfizerDesignations from "../manufacturers/pfizer/designations/PfizerDesignations";
import SanofiDesignations from "../manufacturers/sanofi/designations/SanofiDesignations";
import UcbDesignations from "../manufacturers/ucb/designations/UcbDesignations";
import OrganonDesignations from "../manufacturers/organon/designations/OrganonDesignations";
import TevaDesignations from "../manufacturers/teva/designations/TevaDesignations";
import AstrazenecaDesignations from "../manufacturers/astrazeneca/designations/AstrazenecaDesignations";
import AstellasDesignations from "../manufacturers/astellas/designations/AstellasDesignations";

const PublicAssignOneContractPharmacies = (props) => {
  const [manufacturerFormDetails, setManufacturerFormDetails] = useState({
    abbvie: {},
    amgen: {},
    boehringer_ingelheim: {},
    bristol_myers_squibb: {},
    exelixis: {},
    gilead: {},
    glaxosmithkline: {},
    johnson_n_johnson: {},
    merck: {},
    pfizer: {},
    pharmacyclics: {},
    sanofi: {},
    ucb: {},
    bausch: {},
    biogen: {},
    novo_nordisk: {},
    novartis: {},
    bayer: {},
    emd_serono: {},
    organon: {},
    teva: {},
    astrazeneca: {},
    astellas: {},
  });

  useEffect(() => {
    props.setCompletedDesignations(isComplete());
  }, [manufacturerFormDetails]);

  const isComplete = () => {
    var completed = false;

    var openForms = _.map(manufacturerFormDetails, (manufacturer) => {
      return _.filter(manufacturer, (form) => {
        return form.open;
      });
    });

    // remove empty arrays and flatten
    var filteredForms = openForms.filter((e) => e.length).flat();

    if (filteredForms.length) {
      completed = _.every(filteredForms, ["completed", true]);
    }

    return completed;
  };

  return (
    <div className="designation_form__wrapper">
      <img src={illustration} className={"abs"} />
      <p>
        <span className="bold">Note: </span>
        You can only designate a single contract pharmacy for pharmaceutical manufacturers that participate on the 340B
        ESP™ platform.
        <br />
        <br />
        Please designate a single contract pharmacy based on the specific policies of each pharmaceutical manufacturer.
        Some manufacturers may provide the option to designate multiple contract pharmacies based on their policies and
        product portfolio. Designations may differ across pharmaceutical manufacturers.
      </p>
      <AbbvieDesignations entities={props.entities} setCompletedDesignations={props.setCompletedDesignations} />
      <AmgenDesignations entities={props.entities} setCompletedDesignations={props.setCompletedDesignations} />
      <AstellasDesignations entities={props.entities} setCompletedDesignations={props.setCompletedDesignations} />
      <AstrazenecaDesignations entities={props.entities} setCompletedDesignations={props.setCompletedDesignations} />
      <BauschDesignations entities={props.entities} setCompletedDesignations={props.setCompletedDesignations} />
      <BayerDesignations entities={props.entities} setCompletedDesignations={props.setCompletedDesignations} />
      <BiDesignations entities={props.entities} setCompletedDesignations={props.setCompletedDesignations} />
      <BiogenDesignations entities={props.entities} setCompletedDesignations={props.setCompletedDesignations} />
      <BmsDesignations entities={props.entities} setCompletedDesignations={props.setCompletedDesignations} />
      <EmdSeronoDesignations entities={props.entities} setCompletedDesignations={props.setCompletedDesignations} />
      <ExelixisDesignations entities={props.entities} setCompletedDesignations={props.setCompletedDesignations} />
      <GileadDesignations entities={props.entities} setCompletedDesignations={props.setCompletedDesignations} />
      <GskDesignations entities={props.entities} setCompletedDesignations={props.setCompletedDesignations} />
      <JohnsonNJohnsonDesignations
        entities={props.entities}
        setCompletedDesignations={props.setCompletedDesignations}
      />
      <MerckDesignations entities={props.entities} setCompletedDesignations={props.setCompletedDesignations} />
      <NovartisDesignations entities={props.entities} setCompletedDesignations={props.setCompletedDesignations} />
      <NovoNordiskDesignations entities={props.entities} setCompletedDesignations={props.setCompletedDesignations} />
      <OrganonDesignations entities={props.entities} setCompletedDesignations={props.setCompletedDesignations} />
      <PfizerDesignations entities={props.entities} setCompletedDesignations={props.setCompletedDesignations} />
      <SanofiDesignations entities={props.entities} setCompletedDesignations={props.setCompletedDesignations} />
      <TevaDesignations entities={props.entities} setCompletedDesignations={props.setCompletedDesignations} />
      <UcbDesignations entities={props.entities} setCompletedDesignations={props.setCompletedDesignations} />
    </div>
  );
};

export default PublicAssignOneContractPharmacies;
