import React, { useRef, useState, useEffect } from "react";
import _ from "lodash";
import Notices from "../../components/Notices";
import TableFilter from "./SubmissionTableFilter";
import ToggleView from "./ToggleView";
import { validationDescriptions } from "../../notices/validationDescriptions";
import SubmissionTable from "./submission_table";
import { columnDetails } from "../../schemas/schema_helper";

import { TableData } from "../../processors/TableData";

const DataOutput = (props) => {
  var descriptions = useRef(_.cloneDeep(validationDescriptions()));
  const [viewOriginal, setViewOriginal] = useState(false);
  const [displayNotice, setDisplayNotice] = useState(false);
  const [filter, setFilter] = useState("all");
  const [headers, setHeaders] = useState([]);
  const [mapping, setMapping] = useState({});
  const [columnMappingWarnings, setColumnMappingWarnings] = useState({});
  const [data, setData] = useState([]);
  var isValidSubmission = useRef();
  var filteredDataRows = useRef(0);

  useEffect(() => {
    var { data, headers, columnMappingWarnings, mapping } = TableData(props.data, viewOriginal);

    setData(data);
    setHeaders(headers);
    setMapping(mapping);
    setColumnMappingWarnings(columnMappingWarnings);
  }, [props.data, viewOriginal]);

  const getHeaders = () => {
    if (headers.length) {
      return headers;
    }

    return props.headers;
  };

  const filterData = (filter, data) => {
    switch (filter) {
      case "all":
        return data;
      case "errors":
        return data.filter((row) => row.hasErrors);
      case "no_errors":
        return data.filter((row) => !row.hasErrors);
      case "selected":
        return data.filter((row) => row.selected);
      case "not_selected":
        return data.filter((row) => !row.selected);
      default:
        return [];
    }
  };

  return (
    <div>
      <div className="data-table__actionbar data-table__actionbar--space-between">
        <div>
          <Notices data={props.data} />
        </div>
        <div style={{ display: "flex" }}>
          <ToggleView viewOriginal={viewOriginal} setViewOriginal={setViewOriginal} />

          <TableFilter filter={filter} setFilter={setFilter} viewOriginal={viewOriginal} />
        </div>
      </div>
      <div className="data-table__container">
        <SubmissionTable
          key={Math.random()}
          viewOriginal={viewOriginal}
          type={props.type}
          // tableData={TableData(props.data, viewOriginal)}
          tableData={filterData(filter, data)}
          headers={getHeaders()}
          columnDetails={columnDetails(props.type)}
          mapping={mapping}
          columnMappingWarnings={columnMappingWarnings}
          setScrollTo={props.setScrollTo}
        />
      </div>
    </div>
  );
};

export default DataOutput;
