import React, { Component } from "react";
import SubmissionActionDetails from "./SubmissionDetailsCard";
import CalendarCard from "./CalendarCard";
import Announcement from "./Announcement";
import Credit from "./Credit";

const Draw = (props) => {
  const announcements = [
    {
      title: "Policy update",
      content: "Based on a comparison of your 340B purchase volume and 340B claims submissions.",
    },
    {
      title: "Policy update",
      content:
        "Based on a comparison of your 340B purchase volume and 340B claims submissions, your entity has been identified as having incomplete 340B claims submissions. To ensure your continued participation.",
    },
  ];

  return (
    <div>
      <div className="draw__item__container" style={{ marginBottom: 30 }}>
        <SubmissionActionDetails
          links={props.links}
          organization={props.organizationID}
          atRisk={props.atRisk}
          pendingRegistrations={props.pendingRegistrations}
          pendingRegistrationIDs={JSON.parse(props.pendingRegistrationIDs)}
          entity={props.entity}
          transferDetails={props.transfer_details}
        />
      </div>
      {/* 
      <div className="draw__item__container" style={{ marginBottom: 20 }}>
        <Credit />
      </div>

      <div className="draw__item__container">
        <Announcement announcements={announcements} />
      </div> */}
    </div>
  );
};

export default Draw;
