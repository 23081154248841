import _ from "lodash";

export const validateHashedRow = (row, filesWithErrors, position) => {
  // move to next data attachment if attachment has errors
  if (filesWithErrors.includes(position)) {
    return;
  }

  _.forEach(row, (value, key) => {
    switch (key) {
      case "date_of_service":
        if (filesWithErrors.includes(position)) {
          return;
        }

        if (!value || value.length != 64) {
          filesWithErrors.push(position);
        }
        break;
      case "date_prescribed":
        if (filesWithErrors.includes(position)) {
          return;
        }

        if (!value || value.length != 64) {
          filesWithErrors.push(position);
        }
        break;
      case "rx_number":
        if (filesWithErrors.includes(position)) {
          return;
        }

        if (!value || value.length != 64) {
          filesWithErrors.push(position);
        }
        break;
      case "formatted_rx_number":
        if (filesWithErrors.includes(position)) {
          return;
        }

        if (!value || value.length != 64) {
          filesWithErrors.push(position);
        }
        break;
    }
  });
};
