import axios from "axios";
import React, { useState, useEffect } from "react";
import verifyImage from "~/images/verify_account_illustration.png";

const AnnouncementFeature = (props) => {
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = () => {
    if (!submitting) {
      setSubmitting(true);

      axios
        .post(
          props.confirmation_path,
          {},
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
            },
          }
        )
        .then(() => {
          location.reload();
        })
        .catch(() => {
          location.reload();
        });
    }
  };

  const renderLoader = () => {
    if (submitting) {
      return <div className="loading-indicator"></div>;
    }
  };

  return (
    <div className="modal__background">
      <div
        className="modal__body modal__body--no-side-padding animated fadeInUp"
        style={{ paddingTop: 20, paddingBottom: 30 }}
      >
        <div className="modal__body__title" style={{ marginBottom: 20 }}>
          New Announcement
        </div>
        <div className="modal__body__spotlight">
          <img src={verifyImage} width={130} />
        </div>
        <div className="modal__body__container" style={{ paddingBottom: 10 }}>
          <div className="modal__body__container__note" style={{ fontSize: 15 }}>
            Certain pharmaceutical manufacturers have made 340B purchasing data available that will allow Covered
            Entities to review claim submission balances in 340B ESP for specific contract pharmacies and products.
            <br />
            <br />
            Claim submission balances represent the difference between 340B replenishment orders of a specific product
            that were shipped to a specific contract pharmacy and the conforming 340B claim submissions for that same
            product and contract pharmacy.
            <br />
            <br />
            Although the claim submission balances presented in 340B ESP are not currently used to determine access to
            340B pricing, we encourage users to review their claim submission balances and raise any questions or
            concerns with the 340B ESP team by using our Intake Form. Pharmaceutical manufacturers may use this
            information in the future to determine access to 340B pricing for contract pharmacies.
          </div>
        </div>
        <div className="modal__body__footer">
          <div
            className="btn"
            style={{ flexGrow: 1 }}
            onClick={() => {
              handleSubmit();
            }}
          >
            {renderLoader()}
            Continue
          </div>
        </div>
      </div>
    </div>
  );
};
export default AnnouncementFeature;
