import React from "react";

import noTasks from "~/images/empty.png";

const EmptyFilters = (type) => {
  return (
    <div className="data-table__message">
      <div className="data-table__message__image">
        <img src={noTasks} />
      </div>
      <div className="data-table__message__content">
        <div className="data-table__message__content__title">No Matching {type == "medical" ? "HCPCS Codes" : "NDCs"}</div>
        <div className="data-table__message__content__subtitle">
          It looks like there are no matching {type == "medical" ? "HCPCS Codes" : "NDCs"} in this file.
          <br />
          Please confirm that your <span style={{ fontWeight: 500, textDecoration: "underline" }}>
            Column Mappings
          </span>{" "}
          are correct.
        </div>
      </div>
    </div>
  );
};

export default EmptyFilters;
