import React, { useState } from "react";
import _ from "lodash";

import { Droppable } from "react-beautiful-dnd";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import { CloseOutlined, DragIndicator, DeleteOutline as Delete } from "@material-ui/icons";
import Validations from "../../processors/Validations";
import ConfirmModal from "../../../modals/ConfrimModal";

const FieldMappingGroup = ({
  position,
  saveMapping,
  orderedFields,
  inEditMode,
  disabled,
  editState,
  setEditState,
  closeNewMapping,
  removeMapping,
  deleteFieldMappingGroup,
  mapping = {},
  isDeleting,
}) => {
  const [displayModal, setDisplayModal] = useState(false);
  const missingRequiredFields = orderedFields.some((field) => field.required && !editState.mappings[field.name]);
  const hasBlankName = !editState.name;

  const getClassName = () => {
    if (inEditMode) {
      return "mappings__group--edit-mode";
    }
    if (disabled) {
      return "mappings__group--disabled-mode";
    }

    return null;
  };

  const saveDisabled = () => {
    return hasBlankName || missingRequiredFields;
  };

  const onDeleteFieldMappingGroup = async () => {
    await deleteFieldMappingGroup(mapping.id);
    setDisplayModal(false);
  };

  const renderActions = (mapping) => {
    if (inEditMode) {
      return (
        <div className="grid" style={{ gap: 20, gridTemplateColumns: "2fr 1fr" }}>
          <div className="flex-col" style={{ position: "relative" }}>
            <input
              type="text"
              value={editState.name}
              onChange={handleEditMappingName}
              className={`input input--outlined t--md t--500 ${!editState.name && "input--invalid"}`}
            />
            {hasBlankName && (
              <div style={{ position: "absolute", top: -14 }} className="t--xsm t--red">
                Please enter mapping name
              </div>
            )}
          </div>

          <div className="flex justify-end p-0" style={{ gap: 10 }}>
            <button
              className="btn btn--sqr"
              style={{ padding: 5, height: "100%", width: "auto" }}
              onClick={() => toggleEditMapping(mapping)}
            >
              <CloseOutlined />
            </button>
            <div className="tt__container">
              <button disabled={saveDisabled()} onClick={() => saveMapping()} className="btn btn--small">
                Save
              </button>

              {saveDisabled() && (
                <div className="tt">
                  <div className="tt__title">Complete the following:</div>
                  {hasBlankName && <div className="tt__subtitle">- Enter mapping name</div>}
                  {missingRequiredFields && <div className="tt__subtitle">- Map all required fields</div>}
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="flex" style={{ gap: 20 }}>
        <div className="flex">
          <p className="t--md t--500 p-1 mb-0">{mapping.name}</p>
        </div>

        <div className="flex justify-end p-0" style={{ gap: 10, padding: 10 }}>
          <button
            className={`btn btn--sqr ${disabled ? "btn--disabled" : ""}`}
            style={{ padding: 5, height: "auto", width: "auto" }}
            onClick={() => toggleEditMapping(mapping)}
            disabled={disabled}
          >
            <EditOutlinedIcon />
          </button>
          <button
            className={`btn btn--sqr ${disabled ? "btn--disabled" : ""}`}
            onClick={() => {
              setDisplayModal(true);
            }}
            style={{ padding: 5, height: "auto", width: "auto" }}
            disabled={disabled}
          >
            <DeleteOutlineOutlinedIcon />
          </button>
        </div>
      </div>
    );
  };

  const renderMappingTag = (id, fieldName, mappingName, canEdit) => {
    if (!mappingName) {
      return <div className="mappings__group__item__assignment__dropzone__contents--empty">Drop your column here</div>;
    }

    if (mappingName) {
      return (
        <div
          key={id}
          // className={`mappings__group__item__assignment__dropzone__item flex justify-between ${
          //   !Validations.isValidColumnMapping(fieldName, mappingName) &&
          //   "mappings__group__item__assignment__dropzone__item--warning"
          // }`}
          className="mappings__group__item__assignment__dropzone__item flex justify-between"
        >
          <div className="flex">
            <div style={{ fontSize: 10 }}>{mappingName}</div>
          </div>
          {canEdit && (
            <div
              className="btn"
              style={{
                border: "none",
                background: "none",
                padding: 0,
                alignSelf: "center",
              }}
              onClick={() => removeMapping(fieldName)}
            >
              <div className="btn btn--sqr">
                <Delete style={{ fontSize: 16 }} />
              </div>
            </div>
          )}
        </div>
      );
    }
  };

  const toggleEditMapping = (mapping) => {
    if (closeNewMapping) {
      closeNewMapping();
      return;
    }

    if (inEditMode) {
      setEditState({ id: null, position: null, name: "", mappings: {} });
      return;
    } else {
      setEditState({
        position: position,
        id: mapping.id,
        name: mapping.name || "",
        mappings: mapping.mappings,
      });
    }
  };

  const handleEditMappingName = (e) => {
    setEditState({ ...editState, name: e.target.value });
  };

  return (
    <div className={getClassName() ? `mappings__group ${getClassName()}` : "mappings__group"}>
      {renderActions(mapping)}
      {orderedFields.map((field) => {
        return (
          <div key={`mapping_group-${mapping.id}-${field.title}`} className="mappings__group__item">
            <div className="mappings__group__item__title-container">
              <div className="mappings__group__item__title">{field.title}</div>
              {field.required ? <div className="mappings__group__item__required-tag">*Required</div> : null}
            </div>
            <div className="mappings__group__item__subtitle">{field.description}</div>

            <Droppable droppableId={`existing#${field.name}#${mapping.id}`}>
              {(provided, snapshot) => {
                return (
                  <div
                    ref={provided.innerRef}
                    className="mappings__group__item__assignment__dropzone"
                    style={snapshot.isDraggingOver ? { borderColor: "#3246d3" } : null}
                  >
                    {renderMappingTag(
                      mapping.id,
                      field.name,
                      inEditMode ? editState.mappings[field.name] : mapping.mappings[field.name],
                      inEditMode
                    )}
                    {provided.placeholder}
                  </div>
                );
              }}
            </Droppable>
          </div>
        );
      })}

      <ConfirmModal
        displayModal={displayModal}
        setDisplayModal={setDisplayModal}
        title="Delete Mapping"
        body="Please confirm you want to delete this mapping. This action cannot be undone"
        primaryAction={onDeleteFieldMappingGroup}
        isDeleting={isDeleting}
        secondaryAction={() => setDisplayModal(false)}
      />
    </div>
  );
};

export default FieldMappingGroup;
