import _ from "lodash";
import moment from "moment";
import debounce from "debounce-promise";
import axios from "axios";

export default class Http {
  getQueries = (searchQuery) => {
    var queries = {};

    _.each(searchQuery, (value, key) => {
      if (value && value.length) _.assign(queries, { [key]: value });
    });

    return queries;
  };

  static newFetchQuery = (path, searchQuery) => {
    return new Promise((resolve, reject) => {axios
      .get(path, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
        },
        params: {
          ...searchQuery,
        },
      }).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error);
      });
    })
  };

  static fetchQuery = (path, searchQuery) => {
    return axios
      .get(path, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
        },
        params: {
          ...searchQuery,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {});
  };
}
