import React, { useState } from "react";
import _ from "lodash";
import Validations from "../../processors/Validations";
import { Droppable } from "react-beautiful-dnd";
import { CloseOutlined } from "@material-ui/icons";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import { DragIndicator } from "@material-ui/icons";

const CreateFieldMappings = (props) => {
  const [deleting, setDeleting] = useState(
    _.map(props.mappings, (details) => ({
      id: details.id,
      items: _.map(details.mappings, (value, key) => ({
        name: key,
        deleting: false,
      })),
    }))
  );

  const removeMapping = (id, fieldName, mappingName) => {
    var cloneDeleting = _.cloneDeep(deleting);
    var details = _.find(_.find(cloneDeleting, ["id", id]).items, ["name", fieldName]);
    details.deleting = true;

    setDeleting(cloneDeleting);
    props.deleteFieldMapping(id, fieldName, mappingName);
  };

  const renderDeleting = (id, fieldName) => {
    var deletingDetails = _.find(_.find(deleting, ["id", id]).items, ["name", fieldName]);

    if (deletingDetails && deletingDetails.deleting) {
      return <div className="loading-indicator loading-indicator--blue loading-indicator--no-margin"></div>;
    } else {
      return <div className="solid solid-budicon-trash" />;
    }
  };

  const renderMappingTag = (id, fieldName, mappingName, canEdit) => {
    if (!mappingName) {
      return <div className="mappings__group__item__assignment__dropzone__contents--empty">Drop your column here</div>;
    }

    if (mappingName) {
      return (
        <div
          key={id}
          className={`mappings__group__item__assignment__dropzone__item ${
            !Validations.isValidColumnMapping(fieldName, mappingName) &&
            "mappings__group__item__assignment__dropzone__item--warning"
          }`}
        >
          {canEdit && <DragIndicator fontSize="small" />}
          <div>{mappingName}</div>
          {canEdit && (
            <div
              className="btn mapping__group__assignment__dropzone__items__btn-action btn--default-align"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => {
                // removeMapping(id, fieldName, mappingName);
              }}
            >
              {/* {renderDeleting(id, fieldName)} */}
            </div>
          )}
        </div>
      );
    }
  };

  const handleEditMappingName = (e) => {
    props.setNewMapping({ ...props.newMapping, name: e.target.value });
  };

  const renderActions = () => {
    return (
      <div className="grid" style={{ gap: 20, gridTemplateColumns: "2fr 1fr" }}>
        <div className="flex">
          <input
            type="text"
            value={props.editing.name}
            onChange={handleEditMappingName}
            className="input input--outlined t--md t--500"
          />
        </div>

        <div className="flex justify-end p-0" style={{ gap: 10 }}>
          <button
            className="btn btn--sqr"
            style={{ padding: 5, height: "auto", width: "auto" }}
            onClick={() => {
              props.setNewMapping({ ...props.newMapping, isActive: false });
            }}
          >
            <CloseOutlined />
          </button>
          <button disabled={false} onClick={() => props.createMapping()} className="btn btn--small">
            Save
          </button>
        </div>
      </div>
    );
  };

  const renderMappingGroup = () => {
    return (
      <div className={`mappings__group`}>
        {renderActions()}
        {props.orderedFields.map((field) => {
          return (
            <div key={`mapping_group-${field.title}`} className="mappings__group__item">
              <div className="mappings__group__item__title-container">
                <div className="mappings__group__item__title">{field.title}</div>
                {field.required ? <div className="mappings__group__item__required-tag">*Required</div> : null}
              </div>
              <div className="mappings__group__item__subtitle">{field.description}</div>

              <div className="mappings__group__item__assignment__container">
                <Droppable droppableId={`existing#${field.name}`}>
                  {(provided, snapshot) => {
                    return (
                      <div
                        ref={provided.innerRef}
                        className="mappings__group__item__assignment__dropzone"
                        style={snapshot.isDraggingOver ? { borderColor: "#3246d3" } : null}
                      >
                        {renderMappingTag(null, field.name, props.editing.mappings[field.name], true)}
                        {provided.placeholder}
                      </div>
                    );
                  }}
                </Droppable>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return <>{renderMappingGroup()}</>;
};

export default CreateFieldMappings;
