import React from "react";
import _ from "lodash";

import Errors from "../Errors";

import { formatFilterKey } from "../utils/data_helpers";

const Notices = ({ data }) => {
  if (!data) {
    return null;
  }

  const renderWarnings = () => {
    if (!_.isEmpty(data.column_mapping_warnings)) {
      return (
        <div className="notify__error notify__error--warning animated fadeInUp">
          <div className="notify__error__col">
            <div className="notify__error__error-title notify__error__error-title--warning">Warning</div>
          </div>

          <div className="notify__error__col notify__error__col--spread">
            <div className="notify__error__description-title">Confirm Column Mappings</div>
            <div className="notify__error__description">
              Please ignore if you have confirmed that your mappings names are correct
            </div>
          </div>
        </div>
      );
    }
  };

  const renderNotice = () => {
    if (data && data.formattedData.length === 0) {
      return renderMessageNothingToSubmit();
    } else {
      return (
        <Errors
          columns={data.columnNames}
          errors={data.columns_with_errors}
          mappingWarnings={data.column_mapping_warnings}
        />
      );
    }
  };

  const renderMessageNothingToSubmit = () => {
    return (
      <div className="notify__banner">
        <div className="notify__banner__icon">
          <i className="solid solid-budicon-notification"> </i>
        </div>
        <div className="notify__banner__notice__title">
          <strong>Nothing to submit -</strong>
        </div>
        <div className="notify__banner__notice">
          {`It looks like there are no matching ${formatFilterKey(data.filterKey)} in this file.`}
        </div>
      </div>
    );
  };

  return (
    <div>
      {renderWarnings()}
      {renderNotice()}
    </div>
  );
};

export default Notices;
