import React, { useState, useEffect } from "react";
import verifyImage from "~/images/verify_account_illustration.png";

const ModalFileMappings = (props) => {
  const [displayModal, setDisplayModal] = useState(true);

  const renderModal = () => {
    if (displayModal) {
      return (
        <div className="modal__background">
          <div
            className="modal__body modal__body--no-side-padding animated fadeInUp"
            style={{ paddingTop: 30, paddingBottom: 30 }}
          >
            <div className="modal__body__title">Confirm column mapping</div>
            <div className="modal__body__subtitle" style={{ marginBottom: 30 }}>
              In order to meet HIPAA compliance standards, 340B ESP™ only collects a subset of your 340B contract
              pharmacy claims data.
              <br />
              <br />
              You are required to indicate which fields in your 340B claims data represent the specific data elements we
              collect.
            </div>
            <div className="modal__body__spotlight">
              <img src={verifyImage} width={220} />
            </div>
            <div className="modal__body__container">
              <div className="modal__body__container__note__title">Required</div>
              <div className="modal__body__container__note">
                Attach a sample 340B claims data file to select your field mapping. The sample file should have the same
                fields as the data files you will upload to 340B ESP™. Please ensure the first row of the sample file
                contains column headings and subsequent rows contain claims data.
              </div>
            </div>
            <div className="modal__body__footer">
              <div
                className="btn btn--white btn--outline"
                onClick={() => {
                  setDisplayModal(false);
                }}
              >
                Cancel Selection
              </div>
              <a href={props.path} style={{ display: "flex", flexGrow: 1 }}>
                <div className="btn" style={{ flexGrow: 1, marginLeft: 30 }} onClick={() => {}}>
                  Continue
                </div>
              </a>
            </div>
          </div>
        </div>
      );
    }
  };

  return renderModal();
};
export default ModalFileMappings;
