import React from "react";

const TableRow = ({ row, viewOriginal, style, children }) => {
  const getClassName = () => {
    if (row.hasErrors && viewOriginal) {
      return "data-table__row data-table__row--invalid";
    } else {
      return "data-table__row";
    }
  };

  return (
    <tr className={getClassName()} style={style}>
      {children}
    </tr>
  );
};

export default TableRow;
