import React, { useRef, useEffect, useState, useMemo } from "react";
import _ from "lodash";
import debounce from "debounce-promise";
import moment from "moment";
import Draw from "../ClaimDetailsDraw";
import ClaimDetailsTable from "./ClaimsDetailsTable";
import Notice from "../../Notice";
import Http from "../http";
import StatCards from "../StatCards";

const ReportsIndex = (props) => {
  const [notice, setNotice] = React.useState({
    kind: "error",
    open: false,
    message: "",
  });

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const entityID = urlParams.get("id");
  const [issueList, setIssueList] = useState([]);
  const [stats, setStats] = useState({});

  const [filterQuery, setFilterQuery] = useState({
    product_name: null,
    status: null,
  });
  const [arrayFilterQuery, setArrayFilterQuery] = useState({});
  // TODO: We currently end up running the query on first load 4 times, once with the initial values and then for each update
  // to the filters (id_340b, manufacturer, date_range). We should get this so it only needs to happen once.
  const [searchQuery, setSearchQuery] = useState({
    submission_guid: props.submission?.value,
    id_340b: entityID ? entityID : props.entities[0].value,
    manufacturer: props.manufacturers[0].value,
    date_range: {
      start: moment().startOf("month").format("YYYY-MM-DD"),
      end: moment().format("YYYY-MM-DD"),
    },
  });
  const [claims, setClaims] = useState([]);
  const [isClaimsLoading, setIsClaimsLoading] = useState(false);

  const isMounted = useRef(false);

  const memoQuery = useMemo(() => {
    return searchQuery;
  }, [props, searchQuery]);

  useEffect(() => {
    setIsClaimsLoading(true);
    debouncedFetchHandler(props.query_path, memoQuery).then((res) => {
      setClaims(res.claims);
      setStats(res.stats);
      setIsClaimsLoading(false);
    });
    //fetch();
  }, [memoQuery]);

  useEffect(() => {
    if ((isMounted.current && !claims) || (isMounted.current && claims.length == 0)) {
      setNotice({
        kind: "success",
        open: true,
        message: "The current selection and filters returned no results",
      });
    }
    var issues = _.uniqWith(_.flatten(_.map(claims, (c) => c.issues)), _.isEqual);
    setIssueList(issues);

    isMounted.current = true;
  }, [claims]);

  const debouncedFetchHandler = debounce(Http.newFetchQuery, 100);

  const handleFilter = (name, value) => {
    setFilterQuery({ ...filterQuery, [name]: value });
  };

  const handleArrayFilter = (name, value) => {
    setArrayFilterQuery({ ...arrayFilterQuery, [name]: value });
  };

  const handleSearch = (name, value) => {
    setSearchQuery({ ...searchQuery, [name]: value });
  };

  const getFilters = () => {
    var filters = {};

    _.each(filterQuery, (value, key) => {
      if (value && value.length) _.assign(filters, { [key]: value });
    });

    return filters;
  };

  const filterArrayFields = () => {
    var filtClaims = claims;
    _.each(arrayFilterQuery, (value, key) => {
      if (value && value.length) {
        filtClaims = _.filter(filtClaims, (c) => {
          return _.includes(
            c[key].map((i) => i.value),
            value
          );
        });
      }
    });
    return filtClaims;
  };

  const filteredClaims = () => {
    var filtClaims = filterArrayFields();
    return _.filter(filtClaims, getFilters());
  };

  return (
    <div style={{ display: "flex" }}>
      <div className="content__container">
        <div className="page-details__container">
          <div className="page-details__container__info">
            <div className="page-details__title">Pharmacy Eligibility Data</div>
            <div className="breadcrumbs__container">
              <div className="breadcrumbs__crumb breadcrumbs__crumb__active">Reports</div>
            </div>
          </div>
          <StatCards stats={stats} />
        </div>
        <ClaimDetailsTable claims={filteredClaims()} isLoading={isClaimsLoading} />
      </div>
      <div className="draw__container">
        <Draw
          kind="encounter"
          credit={true}
          submission={props.submission}
          entities={props.entities}
          issueList={issueList}
          setSearchQuery={setSearchQuery}
          searchQuery={searchQuery}
          handleSearch={handleSearch}
          handleFilter={handleFilter}
          handleArrayFilter={handleArrayFilter}
          manufacturers={props.manufacturers}
          products={props.products}
        />
      </div>
      <Notice details={notice} />
    </div>
  );
};

export default ReportsIndex;
