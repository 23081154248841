import React, { useState, useRef, useEffect, Fragment, useMemo } from "react";

export default function ReportCard(props) {
  return (
    <>
      {props.reports.map((report) => (
        <a href={report.link}>
          <div className="report-card report-card--hover" key={report.name}>
            <div className="report-card__content">
              <i className="solid solid-budicon-graphic-moderate report-card--icon"></i>
              <div className="report-card--text">
                <div className="t--md t--500">{report.name}</div>
                <div className="t--sm t--gray">{report.subtitle}</div>
              </div>

              {report.badge && <div className="pill pill--info pill--info--blue"> {report.badge} </div>}
            </div>
            <div className="report-card__link">
              <div className="t--md t--500">
                <a href={report.link}>
                  <div className="btn btn--light-outline t--bold">
                    View Report
                  </div>
                </a>
              </div>
            </div>
          </div>
        </a>
      ))}
    </>
  );
}
