import React, { useState, useRef, useEffect } from "react";
import _ from "lodash";
import Validations from "../../processors/Validations";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { Add } from "@material-ui/icons";
import { DragIndicator, Delete } from "@material-ui/icons";
import FieldMappingGroup from "./FieldMappingGroup";

const CustomTextField = withStyles({
  root: {
    backgroundColor: "#ffffff",
    margin: "10px 0 20px 0",
    "& label.Mui-focused": {
      color: "#3D71FF",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#DEE5EB",
      },
      "&:hover fieldset": {
        borderColor: "#3D71FF",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#3D71FF",
      },
    },
  },
})(TextField);

const FieldMappings = (props) => {
  const [displayMappingsDropdown, setDisplayMappingsDropdown] = useState(
    _.map(props.mappings, (mapping) => ({ id: mapping.id, display: false }))
  );
  const [displayDropDownForm, setDisplayDropDownForm] = useState(false);
  const inputRefValue = useRef();
  const columnDetails = _.cloneDeep(props.columnDetails);
  const [loading, setLoading] = useState(
    _.map(props.mappings, (mapping) => ({ id: mapping.id, loading: false }))
  );
  const [deleting, setDeleting] = useState(
    _.map(props.mappings, (details) => ({
      id: details.id,
      items: _.map(details.mappings, (value, key) => ({
        name: key,
        deleting: false,
      })),
    }))
  );
  const [newMapping, setNewMapping] = useState(false);

  const orderedFields = columnDetails.sort((a, b) =>
    a.title.localeCompare(b.title)
  );
  const newMappingPosition = props.mappings.length + 1;

  // reset loading and deleteing state on updated mapping props
  useEffect(() => {
    setLoading(
      _.map(props.mappings, (mapping) => ({ id: mapping.id, loading: false }))
    );
    setDeleting(
      _.map(props.mappings, (details) => ({
        id: details.id,
        items: _.map(details.mappings, (value, key) => ({
          name: key,
          deleting: false,
        })),
      }))
    );
  }, [props.mappings]);

  const postUpdateMappingName = (id, inputValue) => {
    var cloneLoading = _.cloneDeep(deleting);
    var details = _.find(cloneLoading, ["id", id]);
    details.loading = true;

    setLoading(cloneLoading);
    props.updateFieldMapping(id, "name", inputValue);

    setMappingEditFormDisplay(id);
  };

  // const removeMapping = (id, fieldName) => {
  //   props.removeFieldFromMapping(id, fieldName);
  // };

  // const removeMapping = (id, fieldName, mappingName) => {
  //   var cloneDeleting = _.cloneDeep(deleting);
  //   var details = _.find(_.find(cloneDeleting, ["id", id]).items, ["name", fieldName]);
  //   details.deleting = true;

  //   setDeleting(cloneDeleting);
  //   props.deleteFieldMapping(id, fieldName, mappingName);
  // };

  const renderLoading = (mappingID) => {
    var loadingDetails = _.find(loading, ["id", mappingID]);

    if (loadingDetails && loadingDetails.loading) {
      return (
        <div className="loading-indicator loading-indicator--blue loading-indicator--no-margin"></div>
      );
    } else {
      return <div className="solid solid-budicon-pen-writing"></div>;
    }
  };

  // const renderDeleting = (id, fieldName) => {
  //   var deletingDetails = _.find(_.find(deleting, ["id", id]).items, ["name", fieldName]);

  //   if (deletingDetails && deletingDetails.deleting) {
  //     return <div className="loading-indicator loading-indicator--blue loading-indicator--no-margin"></div>;
  //   } else {
  //     return <div className="solid solid-budicon-trash" />;
  //   }
  // };

  // const renderExistingMappings = (id, fieldName, fieldMapping) => {
  //   return (
  //     <div key={fieldName + id} className="mapping__group__column">
  //       <div
  //         className="mapping__group__assignment__container"
  //         id={fieldName}
  //         data-fieldname={fieldName}
  //       >
  //         <div className="mapping__group__assignment__title">{fieldName}</div>
  //         <Droppable droppableId={`existing#${fieldName}#${id}`}>
  //           {(provided, snapshot) => (
  //             <div
  //               ref={provided.innerRef}
  //               className="mapping__group__assignment__dropzone"
  //               style={
  //                 snapshot.isDraggingOver ? { borderColor: "#3246D3" } : null
  //               }
  //             >
  //               {renderMappingTag(id, fieldName, fieldMapping)}
  //               {provided.placeholder}
  //             </div>
  //           )}
  //         </Droppable>
  //       </div>
  //     </div>
  //   );
  // };

  // const hasColumnWarning = (columnName) => {
  //   var selectedMappings = _.map(
  //     props.mappings,
  //     (mappingDetails) => mappingDetails.mappings[columnName]
  //   );
  //   var validations = _.map(selectedMappings, (value, key) =>
  //     Validations.isValidColumnMapping(columnName, value)
  //   );

  //   return validations.includes(false);
  // };

  const renderDropDownEditForm = (id) => {
    var mapping = _.find(displayMappingsDropdown, ["id", id]);

    if (mapping && mapping.display) {
      return (
        <div className="dropdown__container">
          <div className="dropdown__header">
            <div className="dropdown__header__title">Edit mapping name</div>
            <div
              className="dropdown__header__close"
              onClick={() => setMappingEditFormDisplay(id)}
            >
              <div className="solid solid-budicon-cross-ui" />
            </div>
          </div>
          <CustomTextField
            label="Mapping Name"
            margin="none"
            variant="outlined"
            autoComplete="off"
            size="small"
            id={`mappingName-${id}`}
            onChange={(el) => (inputRefValue.current = el.target.value)}
            name="field_mappings[name][id]"
            fullWidth
            required
          />
        </div>
      );
    }
  };

  const setMappingEditFormDisplay = (id) => {
    var cloneMappingDisplay = _.cloneDeep(displayMappingsDropdown);
    var mapping = _.find(cloneMappingDisplay, ["id", id]);

    mapping.display = !mapping.display;

    setDisplayMappingsDropdown(cloneMappingDisplay);
  };

  const renderHeaders = () => {
    return props.mappings.map((fieldMapping, index) => (
      <div key={index} className="mapping__group__column">
        <div className="mapping__group__header mapping__group__header--padding-sm">
          <div
            className="btn btn--sq btn--outline-subtle-white btn--small--fixed btn--default-align btn--lowercase"
            style={{
              fontSize: 12,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => {
              setMappingEditFormDisplay(fieldMapping.id);
            }}
          >
            {renderLoading(fieldMapping.id)}
          </div>
          {fieldMapping.name ? fieldMapping.name : `Mapping-${index + 1}`}
          <div
            className="btn btn--outline-subtle-white btn--small--fixed btn--default-align btn--lowercase"
            style={{ fontSize: 12 }}
            onClick={() => {
              props.deleteAllFieldMappings(fieldMapping.id);
            }}
          >
            Delete All
          </div>
        </div>
        {renderDropDownEditForm(fieldMapping.id)}
      </div>
    ));
  };

  const renderDropDownForm = () => {
    if (displayDropDownForm) {
      return (
        <div className="dropdown__container">
          <div className="dropdown__header">
            <div className="dropdown__header__title">Mappings</div>
            <div
              className="dropdown__header__close"
              onClick={() => setDisplayDropDownForm(false)}
            >
              <div className="solid solid-budicon-cross-ui" />
            </div>
          </div>
          <CustomTextField
            label="Mapping Name"
            margin="normal"
            variant="outlined"
            id="mappingName"
            size="small"
            autoComplete="off"
            onChange={(el) => (inputRefValue.current = el.target.value)}
            name="field_mappings[name]"
            fullWidth
            required
          />
          <div
            className="btn btn--small"
            onClick={() => createMapping(inputRefValue.current)}
          >
            Create Mapping
          </div>
        </div>
      );
    }
  };

  const createNewFieldMappingGroup = () => {
    setNewMapping(true);
    props.setEditState({ ...props.editState, position: newMappingPosition });
  };

  const renderNewFieldMappingsBtn = () => {
    const editStateHasMappings = Object.values(props.editState.mappings);
    const isEditStateMapping = editStateHasMappings.some(
      (item) => item !== null
    );

    const editorOpen = !!props.editState.position;
    return (
      <button
        className={`btn btn--light-outline mappings__header__button ${
          editorOpen || isEditStateMapping ? "btn--disabled" : "isDisabled"
        }`}
        onClick={createNewFieldMappingGroup}
        disabled={editorOpen || isEditStateMapping || props.isSaving}
      >
        <Add fontSize="small" />
        <span>Create a New mapping</span>
      </button>
    );
  };

  const renderMappingTag = (id, fieldName, mappingName, canEdit) => {
    if (!mappingName) {
      return (
        <div className="mappings__group__item__assignment__dropzone__contents--empty">
          Drop your column here
        </div>
      );
    }

    if (mappingName) {
      return (
        <div
          key={id}
          className={`mappings__group__item__assignment__dropzone__item ${
            !Validations.isValidColumnMapping(fieldName, mappingName) &&
            "mappings__group__item__assignment__dropzone__item--warning"
          }`}
        >
          {canEdit && <DragIndicator fontSize="small" />}
          <div>{mappingName}</div>
          {canEdit && (
            <div
              className="btn mapping__group__assignment__dropzone__items__btn-action btn--default-align"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => {
                removeMapping(fieldName);
              }}
            >
              <div className="btn btn--sqr"><Delete style={{ fontSize: 16 }} /></div>
            </div>
          )}
        </div>
      );
    }
  };

  const toggleEditMapping = (mapping) => {
    if (props.editState.id === mapping.id) {
      props.setEditState({ id: null, name: "", mappings: {} });
      return;
    } else {
      props.setEditState({
        id: mapping.id,
        name: mapping.name || "",
        mappings: mapping.mappings,
      });
    }
  };

  const handleEditMappingName = (e) => {
    props.setEditState({ ...props.editState, name: e.target.value });
  };

  const saveMapping = () => {
    props.updateFieldMapping(props.editState);
    props.setEditState({ id: null, position: null, name: "", mappings: {} });
  };

  const removeMapping = (fieldName) => {
    props.setEditState({
      ...props.editState,
      mappings: { ...props.editState.mappings, [fieldName]: null },
    });
  };

  const saveDisabled = () => {
    const mappingValues = _.values(props.editState.mappings);

    return (
      mappingValues.some((value) => !value) ||
      mappingValues.length < columnDetails.length
    );
  };

  const createMapping = () => {
    props.createFieldMapping(props.editState);
    props.setEditState({ id: null, position: null, name: "", mappings: {} });
    setNewMapping(false);
  };

  const closeNewMapping = () => {
    setNewMapping(false);
    props.setEditState({ id: null, position: null, name: "", mappings: {} });
  };

  const renderOverlayLoading = () => {
    if (props.isSaving) {
      return (
        <div className="overlay-loading">
          <div
            className="overlay-loading__inner"
            style={{
              width: "60vw",
              height: "60vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              alignSelf: "flex-start",
            }}
          >
            <div className="loading-indicator loading-indicator--blue loading-indicator--no-margin" />
          </div>
        </div>
      );
    }

    return null;
  };

  const renderMappingGroup = (mapping, index) => {
    return (
      <FieldMappingGroup
        key={index}
        position={index}
        mapping={mapping}
        orderedFields={orderedFields}
        inEditMode={props.editState.position === index}
        disabled={
          props.editState.position !== null &&
          props.editState.position !== index
        }
        saveMapping={saveMapping}
        deleteFieldMappingGroup={props.deleteFieldMappingGroup}
        editState={props.editState}
        setEditState={props.setEditState}
        removeMapping={removeMapping}
        isDeleting={props.isDeleting}
      />
    );
  };

  const renderMappings = () => {
    return props.mappings.map((mapping, index) =>
      renderMappingGroup(mapping, index)
    );
  };

  return (
    <div className="pill__container">
      <div className="mappings__container">
        <div className="mappings__header">
          <div className="mappings__header__title">Column Mapping Library</div>
          {renderNewFieldMappingsBtn()}
        </div>
        <div className="mappings__groups-container">
          {renderMappings()}
          {renderOverlayLoading()}

          {newMapping && (
            <FieldMappingGroup
              position={newMappingPosition}
              mapping={{}}
              orderedFields={orderedFields}
              inEditMode={true}
              disabled={false}
              saveMapping={createMapping}
              closeNewMapping={closeNewMapping}
              editState={props.editState}
              setEditState={props.setEditState}
              removeMapping={removeMapping}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default FieldMappings;
