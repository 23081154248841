import PharmacyClaimsEngine from "./pharmacy_claims_engine";
import MedicalClaimsEngine from "./medical_claims_engine";
import EncounterClaimsEngine from "./encounter_claims_engine";

const engines = {
  pharmacy: PharmacyClaimsEngine,
  medical: MedicalClaimsEngine,
  encounter: EncounterClaimsEngine,
};

const EngineHandler = (kind, options) => {
  if (!kind || !engines[kind]) {
    throw new Error(`Invalid or unsupported engine kind: ${kind}`);
  }

  if (!options || typeof options !== "object") {
    throw new Error("Invalid options provided");
  }

  return engines[kind](options);
};

export default EngineHandler;
