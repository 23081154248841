import _ from "lodash";

const dataTableProcessor = (data) => {
  const output = {
    startIndexes: {},
    data: [],
    length: 0,
  };

  if (!data.length) {
    return output;
  }

  let tableIndex = 0;

  _.forEach(data, (file) => {
    _.forEach(file.data, (row, index) => {
      if (index === 0) {
        output.startIndexes[file.id] = tableIndex;
      }
      output.data.push(row);
      tableIndex += 1;
    });
  });

  output.length = output.data.length;

  return output;
};

export default dataTableProcessor;
