import React from "react";
import Button from "./Button";

const ButtonGroup = ({ buttons, activeButton, setActiveButton, disabled }) => {
  return (
    <div className="btn__option__group btn__option__group--even-width btn__option__group--mb">
      {buttons.map((btn) => (
        <Button
          isDisabled={disabled}
          variant="link"
          key={btn}
          className={`${btn === activeButton ? "btn btn--sm btn--even-width btn--selected" : "btn btn--sm btn--even-width"} ${disabled ? "btn--disabled" : ""}`}
          onClick={() => setActiveButton(btn)}
        >
          {btn}
        </Button>
      ))}
    </div>
  );
};

export default ButtonGroup;