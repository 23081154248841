import React, { useState, useRef, useEffect } from "react";
import _ from "lodash";
import RetailDesignationForm from "./RetailDesignationsForm";
import FlolanDesignationForm from "./FlolanDesignationsForm";
import BenlystaDesignationForm from "./BenlystaDesignationsForm";
import NucalaDesignationForm from "./NucalaDesignationsForm";
import ZejulaDesignationForm from "./ZejulaDesignationsForm";

import Gsk from "~/images/GSK.png";

const GskDesignations = (props) => {
  const manufacturer_name = "glaxosmithkline";
  const vanity_manufacturer_name = "GlaxoSmithKline";
  const [retailHasSelectedSpecialtyPharmacy, setRetailHasSelectedSpecialtyPharmacy] = useState(false);
  const [retailSelectedSpecialtyPharmacy, setRetailSelectedSpecialtyPharmacy] = useState();
  const [manufacturerFormDetails, setManufacturerFormDetails] = useState({
    glaxosmithkline: {},
  });

  useEffect(() => {
    props.setCompletedDesignations(isComplete());
  }, [manufacturerFormDetails]);

  const isComplete = () => {
    var completed = true;

    var openForms = _.map(manufacturerFormDetails, (manufacturer) => {
      return _.filter(manufacturer, (form) => {
        return form.open;
      });
    });

    // remove empty arrays and flatten
    var filteredForms = openForms.filter((e) => e.length).flat();

    if (filteredForms.length) {
      completed = _.every(filteredForms, ["completed", true]);
    }

    return completed;
  };

  const renderRetail = () => {
    return (
      <RetailDesignationForm
        entities={props.entities}
        kind={"retail"}
        manufacturerFormDetails={manufacturerFormDetails}
        setManufacturerFormDetails={setManufacturerFormDetails}
        manufacturer={manufacturer_name}
        manufacturerName={vanity_manufacturer_name}
        setRetailSelectedSpecialtyPharmacy={setRetailSelectedSpecialtyPharmacy}
        setRetailHasSelectedSpecialtyPharmacy={setRetailHasSelectedSpecialtyPharmacy}
      />
    );
  };

  const renderBenlysta = () => {
    return (
      <BenlystaDesignationForm
        entities={props.entities}
        kind={"benlysta"}
        manufacturerFormDetails={manufacturerFormDetails}
        setManufacturerFormDetails={setManufacturerFormDetails}
        manufacturer={manufacturer_name}
        manufacturerName={vanity_manufacturer_name}
        setRetailSelectedSpecialtyPharmacy={setRetailSelectedSpecialtyPharmacy}
        setRetailHasSelectedSpecialtyPharmacy={setRetailHasSelectedSpecialtyPharmacy}
        isBlocked={_.some(props.entities, (e) => e.blocked.includes("benlysta"))}
      />
    );
  };

  const renderFlolan = () => {
    return (
      <FlolanDesignationForm
        entities={props.entities}
        kind={"flolan"}
        manufacturerFormDetails={manufacturerFormDetails}
        setManufacturerFormDetails={setManufacturerFormDetails}
        manufacturer={manufacturer_name}
        manufacturerName={vanity_manufacturer_name}
        setRetailSelectedSpecialtyPharmacy={setRetailSelectedSpecialtyPharmacy}
        setRetailHasSelectedSpecialtyPharmacy={setRetailHasSelectedSpecialtyPharmacy}
        isBlocked={_.some(props.entities, (e) => e.blocked.includes("flolan"))}
      />
    );
  };

  const renderNucala = () => {
    return (
      <NucalaDesignationForm
        entities={props.entities}
        kind={"nucala"}
        manufacturerFormDetails={manufacturerFormDetails}
        setManufacturerFormDetails={setManufacturerFormDetails}
        manufacturer={manufacturer_name}
        manufacturerName={vanity_manufacturer_name}
        setRetailSelectedSpecialtyPharmacy={setRetailSelectedSpecialtyPharmacy}
        setRetailHasSelectedSpecialtyPharmacy={setRetailHasSelectedSpecialtyPharmacy}
        isBlocked={_.some(props.entities, (e) => e.blocked.includes("nucala"))}
      />
    );
  };

  const renderZejula = () => {
    return (
      <ZejulaDesignationForm
        entities={props.entities}
        kind={"zejula"}
        manufacturerFormDetails={manufacturerFormDetails}
        setManufacturerFormDetails={setManufacturerFormDetails}
        manufacturer={manufacturer_name}
        manufacturerName={vanity_manufacturer_name}
        setRetailSelectedSpecialtyPharmacy={setRetailSelectedSpecialtyPharmacy}
        setRetailHasSelectedSpecialtyPharmacy={setRetailHasSelectedSpecialtyPharmacy}
        isBlocked={_.some(props.entities, (e) => e.blocked.includes("zejula"))}
      />
    );
  };

  return (
    <div>
      <div className="designation_form__section__header">
        <div className="designation_form__section__header__logo">
          <img src={Gsk} />
        </div>
        <div className="designation_form__section__header__title">{vanity_manufacturer_name}</div>
      </div>
      {renderRetail()}
      {renderBenlysta()}
      {renderZejula()}
      {renderNucala()}
      {renderFlolan()}
    </div>
  );
};

export default GskDesignations;
