import React, { useState, useRef, useEffect } from "react";
import _ from "lodash";
import Billboard from "./Billboard";
import AgreementGilead from "./AgreementGilead";
import AgreementGsk from "./AgreementGsk";
import AgreementUcb from "./AgreementUcb";
import AgreementBms from "./AgreementBms";
import AgreementPfizer from "./AgreementPfizer";
import AgreementExelixis from "./AgreementExelixis";
import AgreementEmdSerono from "./AgreementEmdSerono";
import AgreementNovartis from "./AgreementNovartis";
import AgreementNovoNordisk from "./AgreementNovoNordisk";
import PharmacyInputs from "./PharmacyInputs";
import WholesalerInputs from "./WholesalerInputs";
import ContactDetails from "./ContactDetails";
import SelectManufacturers from "./SelectManufacturers";
import Attachments from "./Attachments";
import Signature from "./Signature";
import SelectEntity from "./SelectEntity";
import AgreementBiogen from "./AgreementBiogen";

const PublicWhollyOwnedExemptionsForm = (props) => {
  const [completedSelectManufacturers, setCompletedSelectManufacturers] = useState(false);
  const [completedFirstName, setCompletedFirstName] = useState(false);
  const [completedLastName, setCompletedLastName] = useState(false);
  const [completedContactEmail, setCompletedContactEmail] = useState(false);
  const [completedSelectedEntity, setCompletedSelectedEntity] = useState(false);
  const [completedWholesaler, setCompletedWholesaler] = useState(false);
  const [completedPharmacyDetails, setCompletedPharmacyDetails] = useState(false);
  const [completedAttachedDocuments, setCompletedAttachedDocuments] = useState(false);
  const [completedSignedAgreement, setCompletedSignedAgreement] = useState(false);
  const [selectedManufacturer, setSelectedManufacturer] = useState(null);
  const [wholesalers, setWholesalers] = useState([{ id: 1, name: null }]);
  const [pharmacies, setPharmacies] = useState([
    { id: 1, name: null, address: null, state: null, city: null, zip: null, hrsaId: null, dea: null, hin: null },
  ]);
  const [attachments, setAttachments] = useState([{ id: 1, filename: null, filesize: null, file: null }]);

  useEffect(() => {
    if (
      [
        "gilead",
        "glaxosmithkline",
        "ucb",
        "bristol_myers_squibb",
        "pfizer",
        "exelixis",
        "biogen",
        "novartis",
        "novo_nordisk",
        "emd_serono",
      ].includes(selectedManufacturer)
    ) {
      setCompletedSelectManufacturers(true);
    } else {
      if (completedSelectManufacturers) setCompletedSelectManufacturers(false);
    }
  }, [selectedManufacturer]);

  const hasEmptyValues = (data) => {
    let values;

    if (["pfizer"].includes(selectedManufacturer)) {
      // exclude HIN for pfizer
      var omittedHin = data.map((obj) => _.omit(obj, "hin"));

      values = _.flatten(omittedHin.map((obj) => _.values(obj)));
    } else {
      values = _.flatten(data.map((obj) => _.values(obj)));
    }

    return values.includes(null) || values.includes("");
  };

  useEffect(() => {
    if (hasEmptyValues(wholesalers)) {
      setCompletedWholesaler(false);
    } else {
      setCompletedWholesaler(true);
    }
  }, [wholesalers]);

  useEffect(() => {
    if (hasEmptyValues(pharmacies)) {
      setCompletedPharmacyDetails(false);
    } else {
      setCompletedPharmacyDetails(true);
    }
  }, [pharmacies]);

  useEffect(() => {
    if (hasEmptyValues(attachments)) {
      setCompletedAttachedDocuments(false);
    } else {
      setCompletedAttachedDocuments(true);
    }
  }, [attachments]);

  const enableSubmit = () => {
    return (
      completedSelectManufacturers &&
      completedFirstName &&
      completedLastName &&
      completedContactEmail &&
      completedSelectedEntity &&
      completedWholesaler &&
      completedPharmacyDetails &&
      completedAttachedDocuments &&
      completedSignedAgreement
    );
  };

  const renderWholesalersForm = () => {
    return wholesalers.map((item, i) => {
      return (
        <div key={item.id}>
          <WholesalerInputs id={item.id} name={item.name} wholesalers={wholesalers} setWholesalers={setWholesalers} />
        </div>
      );
    });
  };

  const renderPharmacyForm = () => {
    return pharmacies.map((item, i) => {
      return (
        <div key={item.id}>
          <PharmacyInputs
            id={item.id}
            name={item.name}
            address={item.address}
            state={item.state}
            city={item.city}
            zip={item.zip}
            hrsaId={item.hrsaId}
            dea={item.dea}
            hin={item.hin}
            pharmacies={pharmacies}
            selectedManufacturer={selectedManufacturer}
            setPharmacies={setPharmacies}
          />
        </div>
      );
    });
  };

  const renderAttachments = () => {
    return attachments.map((item, i) => {
      return (
        <div key={item.id}>
          <Attachments
            id={item.id}
            filename={item.filename}
            filesize={item.filesize}
            file={item.file}
            attachments={attachments}
            setAttachments={setAttachments}
          />
        </div>
      );
    });
  };

  const renderStepUploadTitle = () => {
    return "6. Upload Proof Of Ownership";
  };

  const renderNote = () => {
    return (
      <p>
        <span className="bold">Note: </span>
        In order to submit an exemption request, please provide proof of ownership documents. Examples of proof of
        ownership documents include:
        <span className="bold"> Certificate of liability insurance </span>
        or
        <span className="bold"> State business licenses</span>
      </p>
    );
  };

  return (
    <div className="register__wrapper register__wrapper--large">
      <form
        method="POST"
        action="/wholly_owned_application"
        id="wholly_owned_application_form"
        encType="multipart/form-data"
      >
        <div className="register__form__container register__form__container--large">
          <div className="register__step__title">1. Select Manufacturer</div>
          <div className="register__form register__form--large">
            <div className="register__form__requirements" style={{ marginTop: 0 }}>
              <p>
                <span className="bold">Note: </span>
                Please select one manufacturer -
                <span className="bold"> you can only submit exemption applications for one manufacturer at a time</span>
              </p>
            </div>
            <SelectManufacturers
              gsk={props.gsk}
              selectedManufacturer={selectedManufacturer}
              setSelectedManufacturer={setSelectedManufacturer}
            />
            <input type="hidden" name="application[manufacturer]" defaultValue={selectedManufacturer} />
          </div>
          <div className="register__step__title">2. Enter Contact Details</div>
          <div className="register__form register__form--large">
            <ContactDetails
              setCompletedFirstName={setCompletedFirstName}
              setCompletedLastName={setCompletedLastName}
              setCompletedContactEmail={setCompletedContactEmail}
            />
          </div>
          <div className="register__step__title">3. Select your Covered Entity</div>
          <div className="register__form register__form--large">
            <SelectEntity setCompletedSelectedEntity={setCompletedSelectedEntity} />
          </div>
          <div className="register__step__title">4. Wholesalers</div>
          <div className="register__form register__form--large">
            <div className="register__form__requirements" style={{ marginTop: 0 }}>
              <p>
                <span className="bold">Note: </span>
                Please provide your wholesaler's name - you can add multiple wholesalers below
              </p>
            </div>

            <div className="register__divider" />
            {renderWholesalersForm()}
            <div
              style={{
                margin: "15px 0",
                textDecoration: "underline",
                color: "#3246D3",
                cursor: "pointer",
                textAlign: "right",
              }}
              onClick={() => {
                setWholesalers([...wholesalers, { id: _.maxBy(wholesalers, "id").id + 1, name: null }]);
              }}
            >
              + Add Another Wholesaler
            </div>
          </div>
          <div className="register__step__title">5. Wholly Owned Contract Pharmacy(s)</div>
          <div className="register__form register__form--large">
            <div className="register__form__requirements" style={{ marginTop: 0 }}>
              <p>
                <span className="bold">Note: </span>
                Please provide your wholly owned contract pharmacy details - you can add multiple pharmacies below
              </p>
            </div>
            <div className="register__divider" />
            {renderPharmacyForm()}
            <div
              style={{
                margin: "15px 0",
                textDecoration: "underline",
                color: "#3246D3",
                cursor: "pointer",
                textAlign: "right",
              }}
              onClick={() => {
                setPharmacies([
                  ...pharmacies,
                  {
                    id: _.maxBy(pharmacies, "id").id + 1,
                    name: null,
                    address: null,
                    state: null,
                    city: null,
                    zip: null,
                    hrsaId: null,
                    dea: null,
                    hin: null,
                  },
                ]);
              }}
            >
              + Add Another Pharmacy
            </div>
          </div>
          <div className="register__step__title">{renderStepUploadTitle()}</div>
          <div className="register__form register__form--large">
            <div className="register__form__requirements" style={{ marginTop: 0 }}>
              {renderNote()}
            </div>
            {renderAttachments()}
            <div
              style={{
                margin: "15px 0",
                textDecoration: "underline",
                color: "#3246D3",
                cursor: "pointer",
                textAlign: "right",
              }}
              onClick={() => {
                setAttachments([
                  ...attachments,
                  { id: _.maxBy(attachments, "id").id + 1, filename: null, filesize: null, file: null },
                ]);
              }}
            >
              + Add Another File
            </div>
          </div>
          <div className="register__step__title">7. Sign</div>
          <div className="register__form register__form--large">
            <AgreementGilead selectedManufacturer={selectedManufacturer} />
            <AgreementGsk selectedManufacturer={selectedManufacturer} />
            <AgreementPfizer selectedManufacturer={selectedManufacturer} />
            <AgreementUcb selectedManufacturer={selectedManufacturer} />
            <AgreementBms selectedManufacturer={selectedManufacturer} />
            <AgreementExelixis selectedManufacturer={selectedManufacturer} />
            <AgreementBiogen selectedManufacturer={selectedManufacturer} />
            <AgreementEmdSerono selectedManufacturer={selectedManufacturer} />
            <AgreementNovartis selectedManufacturer={selectedManufacturer} />
            <AgreementNovoNordisk selectedManufacturer={selectedManufacturer} />
            <Signature
              selectedManufacturer={selectedManufacturer}
              setCompletedSignedAgreement={setCompletedSignedAgreement}
            />
          </div>
        </div>
        <Billboard
          selectedManufacturer={selectedManufacturer}
          completedSelectManufacturers={completedSelectManufacturers}
          completedFirstName={completedFirstName}
          completedLastName={completedLastName}
          completedContactEmail={completedContactEmail}
          completedSelectedEntity={completedSelectedEntity}
          completedWholesaler={completedWholesaler}
          completedPharmacyDetails={completedPharmacyDetails}
          completedAttachedDocuments={completedAttachedDocuments}
          completedSignedAgreement={completedSignedAgreement}
          enableSubmit={enableSubmit}
        />
      </form>
    </div>
  );
};

export default PublicWhollyOwnedExemptionsForm;
