export const claimSchema = {
  date_of_service: null,
  date_prescribed: null,
  rx_number: null,
  ndc: null,
  quantity: null,
  prescriber_id: null,
  prescriber_id_qualifier: null,
  service_provider_id: null,
  service_provider_id_qualifier: null,
  wholesaler_invoice_number: null,
  contracted_entity_id: null,
  claim_conforms_flag: null,
  formatted_rx_number: null,
};

export const pharmacyClaim = {
  original: claimSchema,
  errors: false,
  columns_with_errors: {},
  data: claimSchema,
};

export const vanityColumnSchema = {
  date_of_service: "Date of Service",
  date_prescribed: "Date Prescribed",
  rx_number: "Rx Number",
  ndc: "NDC",
  quantity: "Quantity",
  prescriber_id: "Prescriber ID",
  prescriber_id_qualifier: "Prescriber ID Qualifier",
  service_provider_id: "Service Provider ID",
  service_provider_id_qualifier: "Service Provider ID Qualifier",
  wholesaler_invoice_number: "Wholesaler Invoice Number",
  contracted_entity_id: "Contracted Entity ID",
};
