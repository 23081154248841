import React from "react";
import CustomToggle from "./CustomToggle";
import _ from "lodash";

const PharmaciesTable = (props) => {
  const [masterToggle, setMasterToggle] = React.useState(false);
  const [pharmaciesChecked, setPharmaciesChecked] = React.useState(_.mapValues(_.keyBy(props.pharmacies, "id"), "has_data"));

  const handleToggleAll = (checked, ids) => {
    setMasterToggle(checked);
    setPharmaciesChecked(_.mapValues(pharmaciesChecked, () => checked));
  };

  const handleToggleState = (checked, id) => {
    setPharmaciesChecked({
      ...pharmaciesChecked,
      [id]: checked,
    });
  };

  const descriptions = (status, manufacturer) => {
    var details;

    switch (status) {
      case 'eligible':
        if (manufacturer == 'novartis') {
          details = { title: "Entity Exempted", description: "Entity type is excluded from manufacturer policy" }
        } else {
          details = { title: "Eligible", description: "CP arrangement on open contract" }
        }
        break;
      case 'ineligible':
        details = { title: "Ineligible", description: "CP arrangement not on open contract" }
        break;
      case 'exempt':
        details = { title: "Entity Exempted", description: "Entity type is excluded from manufacturer policy" }
        break;
      case 'in_range':
        details = { title: "Eligible", description: "CP arrangement within accepted distance threshold" }
        break;
      case 'out_of_range':
        details = { title: "Ineligible", description: "CP arrangement outside of accepted distance threshold" }
        break;
    }
    return details
  }

  const status = (status) => {
    var pretty_status;

    switch (status) {
      case 'eligible':
        pretty_status = 'eligible'
        break;
      case 'ineligible':
        pretty_status = 'ineligible'
        break;
      case 'exempt':
        pretty_status = 'exempt'
        break;
      case 'in_range':
        pretty_status = 'eligible'
        break;
      case 'out_of_range':
        pretty_status = 'ineligible'
        break;
    }
    return pretty_status
  }

  const isPharmacyEligible = (pharmacy) => {
    var status = [];
    _.forEach(pharmacy.eligibilities, (eligibility) => status.push(_.map(eligibility, 'status')))

    return !['out_of_range', 'ineligible'].some((value) => _.flatten(status).includes(value))
  }

  const renderSanofiDetails = (pharmacy) => {
    return (
      <div className={"table__row__item"}>
        {pharmacy.eligibilities.sanofi && pharmacy.eligibilities.sanofi.map((eligibility, index) => {
          return <div key={index} className={["eligible", "exempt", "in_range"].includes(eligibility.status) ? "tag tag--small tag--with-tooltip tag--square tag--light-green" : "tag tag--small tag--with-tooltip tag--square tag--red"} style={{ width: 82 }}>
            <div className="tag__info">
              <div className="tooltip tooltip--fast tooltip--info-icon">
                <div className="tooltip__container">
                  <div className="tooltip__container__title">{descriptions(eligibility.status, 'sanofi').title}</div>
                  <div className="tooltip__container__subtitle">{descriptions(eligibility.status, 'sanofi').description}</div>
                </div>
              </div>
            </div>

            {status(eligibility.status)}
          </div>
        }
        )}
      </div>
    )
  }

  const renderNovartisDetails = (pharmacy) => {
    return (
      <div className={"table__row__item"}>
        {pharmacy.eligibilities.novartis && pharmacy.eligibilities.novartis.map((eligibility, index) => {
          return <div key={index} className={["eligible", "exempt", "in_range"].includes(eligibility.status) ? "tag tag--small tag--with-tooltip tag--square tag--light-green" : "tag tag--small tag--with-tooltip tag--square tag--red"} style={{ width: 82 }}>
            <div className="tag__info">
              <div className="tooltip tooltip--fast tooltip--info-icon">
                <div className="tooltip__container">
                  <div className="tooltip__container__title">{descriptions(eligibility.status, 'novartis').title}</div>
                  <div className="tooltip__container__subtitle">{descriptions(eligibility.status, 'novartis').description}</div>
                </div>
              </div>
            </div>

            {status(eligibility.status)}
          </div>
        }
        )}
      </div>
    )
  }

  const renderSelectedManufacturerBody = (pharmacy) => {
    switch (props.selectedManufacturer) {
      case 'sanofi':
        return renderSanofiDetails(pharmacy)
      case 'novartis':
        return renderNovartisDetails(pharmacy)
      default:
        return (
          <div className={"table__row__item"}>
            <div style={{ fontWeight: 500 }}>--</div>
          </div>
        )
    }
  }

  const listPharmacies = () => {
    return props.pharmacies.map((pharmacy, i) => (
      <div key={pharmacy.id} className={isPharmacyEligible(pharmacy) ? "table__row table__row--grey table__row--status-green" : "table__row table__row--grey table__row--status-red"}>
        <div className="table__row__item" style={{ width: 320 }}>
          <div className="table__row__item__info">
            <div className="table__row__item__info__subtitle" style={{ marginBottom: 2 }}>ID - {pharmacy.pharmacy_id}</div>
            <div className="table__row__item__info__title">{pharmacy.pharmacy_name}</div>
            <div className="table__row__item__info__subtitle">{pharmacy.address}</div>
          </div>
        </div>
        <div className="table__row__item" style={{ width: 100 }}>
          <div style={{ fontWeight: 500 }}>{pharmacy.hin ? pharmacy.hin : "--"}</div>
        </div>
        {renderSelectedManufacturerBody(pharmacy)}
        <div className="table__row__item" style={{ width: 155 }}>
          <CustomToggle name="has_data" setState={handleToggleState} checked={pharmaciesChecked[pharmacy.id]} submitPath={`/organization/${props.organizationID}/pharmacies/${pharmacy.id}`} pharmacyIDs={pharmacy.id} />
        </div>
      </div>
    ));
  };

  const renderSelectedManufacturerHeader = () => {
    return (
      <div className="table__head__column">
        {props.selectedManufacturer}
      </div>
    )
  }

  return (
    <div className="table__container table__container--nested table__container--small">
      <div className="table__head table__head--small">
        <div className="table__head__column" style={{ width: 320 }}>Details</div>
        <div className="table__head__column" style={{ width: 100 }}>HIN</div>
        {renderSelectedManufacturerHeader()}
        <div className="table__head__column" style={{ width: 155 }}>
          Providing Data?
          <CustomToggle setState={handleToggleAll} name="has_data" checked={masterToggle} submitPath={`/organization/${props.organizationID}/pharmacies/update_all`} pharmacyIDs={_.map(props.pharmacies, "id")} />
        </div>
      </div>
      {listPharmacies()}
    </div>
  );
};

export default PharmaciesTable;
