import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core";
import { Refresh, Close } from "@material-ui/icons";
import "react-datepicker/dist/react-datepicker.css";
import _ from "lodash";
import ChipSelector from "../Draw/ChipSelector";
import DateSelector from "./RebateMedicalClaimDetails/DateSelector";
import debounce from "debounce-promise";
import AsyncSelect from "react-select/async";
import axios from "axios";

const customSelectStyles = {
  container: (base) => ({
    ...base,
    width: "100%",
    margin: 0,
  }),
  placeholder: (base) => ({
    ...base,
    color: "#718291",
    fontSize: 14,
  }),
  control: (base, state) => ({
    ...base,
    minHeight: 42,
    borderColor: `${state.isFocused ? "#3246D3" : "#DEE5EB"}`,
    borderRadius: 8,
    boxShadow: "none",
    "&:hover": {
      borderColor: `${state.isFocused ? "#3246D3" : "#DEE5EB"}`,
    },
  }),
  menu: (base) => ({
    ...base,
    borderRadius: 8,
    position: "relative",
    top: 0,
    marginBottom: 0,
    color: "#718291",
    fontSize: 14,
    border: "1px solid #DEE5EB",
    boxShadow: "none",
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: 210,
    borderRadius: 8,
    padding: 8,
  }),
  option: (base, state) => ({
    ...base,
    fontSize: 14,
    borderRadius: 8,
    cursor: "pointer",
    color: "#0A162F",
    backgroundColor: state.isSelected ? "#3246D3" : "#ffffff",
    "&:hover": {
      opacity: state.isFocused ? 0.7 : 1,
    },
  }),
  singleValue: (base) => ({
    ...base,
    fontSize: 14,
  }),
  input: (base) => ({
    ...base,
    fontSize: 14,
  }),
  valueContainer: (base) => ({
    ...base,
    padding: 6,
  }),
};

const ReportsDraw = (props) => {
  const [submission, setSubmission] = useState(props.submission);
  const [selectedEntity, setSelectedEntity] = useState(props.searchQuery.id_340b);
  const [selectedManufacturer, setSelectedManufacturer] = useState(props.searchQuery.manufacturer);
  const [submissionGuid, setSubmissionGuid] = useState(props.submission?.value);
  const [selectedProductName, setSelectedProductName] = useState(null);
  const [quality, setQuality] = useState(null);
  const [issue, setIssue] = useState(null);
  const [dateRange, setDateRange] = useState(props.searchQuery.date_range);

  useEffect(() => {
    props.handleSearch("date_range", dateRange);
  }, [dateRange]);

  useEffect(() => {
    props.handleSearch("submission_guid", submissionGuid);
  }, [submissionGuid]);

  useEffect(() => {
    props.handleSearch("id_340b", selectedEntity);
  }, [selectedEntity]);

  useEffect(() => {
    props.handleFilter("quality", quality);
  }, [quality]);

  useEffect(() => {
    props.handleArrayFilter("issues", issue);
  }, [issue]);

  useEffect(() => {
    setSelectedProductName(null);
    props.handleSearch("manufacturer", selectedManufacturer);
  }, [selectedManufacturer]);

  useEffect(() => {
    props.handleFilter("product_name", selectedProductName);
  }, [selectedProductName]);

  const fetchSearchTerm = async (query) => {
    let url = new URL(`/api/submissions/${props.kind}`, window.location.origin);
    url.search = new URLSearchParams({ query: query, credit: props.credit }).toString();

    return axios.get(url);
  };

  const debouncedSubmissionNameHandler = debounce(fetchSearchTerm, 500);

  const loadOptions = (query) => {
    return debouncedSubmissionNameHandler(query)
      .then((response) => response.data)
      .catch((error) => {});
  };

  const renderIndicator = () => {
    if (!submissionGuid) {
      return <div className="indicator indicator--blue indicator--pulse--blue"></div>;
    }
  };

  const formatOptionLabel = ({ created_at, label }) => {
    return (
      <>
        <div className="t--sm">{created_at}</div>
        <div className="t--500">{label}</div>
      </>
    );
  };

  const renderSelectedValue = () => {
    return (
      <div className="flex label label--full-width label--col label--align-l" style={{ alignContent: "space-between" }}>
        <div className="">
          <div className="label__subtitle">Name</div>
          <div className="label__value">{submission?.label}</div>
          <div className="label__subtitle">Date</div>
          <div className="label__value">{submission?.created_at}</div>
        </div>
        {renderClearButton()}
      </div>
    );
  };

  const renderClearButton = () => {
    if (submissionGuid) {
      return (
        <div
          className="btn btn--sqr"
          onClick={clearSelection}
          style={{ position: "absolute", top: "15px", right: "15px" }}
        >
          <Close fontSize="small" />
        </div>
      );
    }
  };

  const handleSelection = (option) => {
    setSubmission(option);
    setSubmissionGuid(option.value);
  };

  const clearSelection = () => {
    setSubmission(null);
    setSubmissionGuid(null);
  };

  const renderIssues = () => {
    if (!props.credit) {
      return null;
    }

    return (
      <>
        <div className="draw__item">
          <div className="draw__item__title draw__item__title--mb-sm t--upcase t--sm">Issues</div>
          <div className="chip__well">
            <ChipSelector items={props.issueList} selected={issue} setSelected={setIssue} />
          </div>
        </div>
        <div className="draw__divider" />
      </>
    );
  };

  return (
    <div>
      <div className="draw__tools__container">
        <div className="btn btn--sqr">
          <Refresh fontSize="small" />
        </div>
        <div className="btn btn--light">Download CSV</div>
      </div>
      <DateSelector setDateRange={setDateRange} disabled={props.searchQuery.submission_guid != null} />
      <div className="draw__divider" />
      <div className="draw__item">
        <div
          className="draw__item__title draw__item__title--mb-sm t--upcase t--sm"
          style={{ display: "flex", alignItems: "center" }}
        >
          Submission
        </div>
        <div className="chip__well" style={{ position: "relative" }}>
          {renderSelectedValue()}
          <AsyncSelect
            components={{ IndicatorSeparator: null }}
            cacheOptions
            defaultOptions
            isClearable={false}
            name="created_at"
            placeholder={"Search Submission Name"}
            styles={customSelectStyles}
            loadOptions={loadOptions}
            formatOptionLabel={formatOptionLabel}
            controlShouldRenderValue={false}
            hideSelectedOptions={true}
            onChange={handleSelection}
          />
        </div>
      </div>
      <div className="draw__divider" />
      <div className="draw__item" style={{ marginTop: 20 }}>
        <div className="draw__item__title draw__item__title--mb-sm t--upcase t--sm">Covered Entity</div>
        <div className="chip__well">
          <ChipSelector
            disabled={props.searchQuery.submission_guid != null}
            items={props.entities}
            selected={selectedEntity}
            setSelected={setSelectedEntity}
            required={true}
          />
        </div>
      </div>

      <div className="draw__divider" />
      <div className="draw__item">
        <div className="draw__item__title draw__item__title--mb-sm t--upcase t--sm">Result</div>
        <div className="chip__well">
          <ChipSelector
            items={[
              { value: "conforming", label: "Conforming" },
              { value: "non-conforming", label: "Non-Conforming" },
            ]}
            selected={quality}
            setSelected={setQuality}
          />
        </div>
      </div>
      <div className="draw__divider" />
      {renderIssues()}
      <div className="draw__item">
        <div className="draw__item__title draw__item__title--mb-sm t--upcase t--sm">Manufacturers</div>
        <div className="chip__well">
          <ChipSelector
            items={props.manufacturers}
            selected={selectedManufacturer}
            setSelected={setSelectedManufacturer}
            required={true}
          />
        </div>
      </div>
      <div className="draw__divider" />
      <div className="draw__item">
        <div className="draw__item__title draw__item__title--mb-sm t--upcase t--sm">Products</div>
        <div className="chip__well">
          <ChipSelector
            items={props.products ? props.products[selectedManufacturer] : []}
            selected={selectedProductName}
            setSelected={setSelectedProductName}
          />
        </div>
      </div>
    </div>
  );
};

export default ReportsDraw;
