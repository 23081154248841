import _ from "lodash";
import { formatData, formatDate } from "./DataProcessing";
import { notRequired } from "../schemas/pharmacy_claim";

export default class Validations {
  static columnNames = {
    date_of_service: [
      "Date Of Service",
      "Fill Date",
      "date_of_service",
      "Dispensed",
      "Fill Sold Date",
      "Service Date",
      "Date of Service (Fill Date)",
      "Rx Fill Date",
      "DOS (Date of Service)",
      "Date Filled",
      "Dispense Date",
    ],
    date_prescribed: [
      "Date Prescribed",
      "Rx Written Date",
      "date_prescribed",
      "Written Date",
      "Date Prescription Written",
      "Prescribed Date",
      "Date Written",
      "RX-Date Written",
      "Script Written Date",
      "Date RxWritten",
      "Date Rx Written",
    ],
    rx_number: [
      "Rx number",
      "Prescription Number",
      "Service Reference Number",
      "Rx Nbr",
      "rx_number",
      "Prescription_Number",
      "Rx #",
      "RX NBR",
    ],
    ndc: ["NDC", "Product", "Service ID", "NDC Number", "Dispensed NDC", "Dispensed Item NDC", "Dispensed NDC11"],
    quantity: [
      "Quantity",
      "Qty Disp",
      "Qnty",
      "Qty Dispensed",
      "Qty",
      "Dispensed Quantity",
      "Dispense Qty",
      "Qty_Dispensed",
    ],
    wholesaler_invoice_number: [
      "Wholesaler Invoice Number",
      "Wholesaler PO",
      "Invoice Number",
      "Wholesaler_invoice_number",
      "Wholesaler Invoice #",
      "Invoice #",
      "replenished order number",
    ],
    prescriber_id_qualifier: [
      "Prescriber ID Qualifier",
      "prescriber_id_qualifier",
      "Prescriber Qualifier",
      "Provider ID type",
    ],
    prescriber_id: [
      "Prescriber ID",
      "prescriber npi",
      "Prescriber DEA",
      "prescriber_id",
      "PrescriberNPIorDEA",
      "Prescriber NPI Or Dea",
    ],
    service_provider_id_qualifier: [
      "Service Provider ID Qualifier",
      "Provider ID Qualifier",
      "service_provider_id_qualifier",
      "Pharmacy Qualifier",
      "Service Provider ID Qualifier NCPDP_NABP",
      "Service provider ID type",
      "Pharmacy NPI Qualifier",
    ],
    service_provider_id: [
      "Service Provider ID",
      "service_provider_id",
      "Service Provider ID NPI",
      "Pharmacy #",
      "Service Provider ID NCPDP_NABP",
      "Pharmacy DEA",
      "Ph NCPDP",
      "Pharmacy NCPDP",
      "Pharmacy NPI",
      "Prescriber NPI",
      "facility_opa_340b_id",
      "Store NPI",
    ],
    contracted_entity_id: [
      "Contracted Entity ID",
      "Contracted Entity",
      "OPA Code",
      "HRSA #",
      "Entity ID",
      "contracted_entity_id",
      "CE ID",
      "340B ID",
      "CONTRACTED_ENTITY_ID",
    ],
    claim_conforms_flag: ["claim_conforms_flag"],
  };

  static isValidColumnMapping(column, mappingName) {
    var getColumn = this.columnNames[column];

    if (notRequired.includes(column) && !mappingName) {
      return true;
    }

    if (!getColumn || !mappingName) {
      return false;
    }

    var cleanNames = _.map(getColumn, (name) => name.toLowerCase().replace(/\s/g, ""));
    var cleanMappingName = mappingName.toLowerCase().replace(/\s/g, "");

    if (cleanNames.includes(cleanMappingName)) {
      return true;
    } else {
      return false;
    }
  }

  static isValidDateOfService(val) {
    return !isNaN(Date.parse(val)) && Date.parse(val) > 0;
  }

  static isQuestionableDateOfService(val, datePrescribed) {
    if (isNaN(Date.parse(val)) || isNaN(Date.parse(datePrescribed))) {
      return true;
    }

    // ensure same platform date string format is used
    var val = formatDate(val);
    var datePrescribed = formatDate(datePrescribed);

    var today = Date.parse(Date());

    // date of service should be > date prescribed
    var dos = Date.parse(val);
    var dp = Date.parse(datePrescribed);

    // DP must be before DOS
    // DOS should not be in the future

    // check to see if date is questionable? return true if questionable
    if (dos > today) {
      return true;
    } else if (dos < dp) {
      return true;
    } else {
      return false;
    }
  }

  static isValidDatePrescribed(val) {
    return !isNaN(Date.parse(val));
  }

  static isQuestionableDatePrescribed(val, dateOfService) {
    if (isNaN(Date.parse(val)) || isNaN(Date.parse(dateOfService))) return true;

    // ensure same platform date string format is used
    var val = formatDate(val);
    var datePrescribed = formatDate(datePrescribed);

    var today = Date.parse(Date());

    // date of service should be > date prescribed
    var dp = Date.parse(val);
    var dos = Date.parse(dateOfService);

    // DP must be before DOS
    // DP should not be in the future

    // check to see if date is questionable? return true if questionable
    if (dp > today) {
      return true;
    } else if (dos < dp) {
      return true;
    } else {
      return false;
    }
  }

  static isValidRXNumber(val) {
    // rx_number is required
    if (!val) {
      return false;
    }

    var rx_number = val.split("-");

    const rx = rx_number[0];
    const cnt = rx_number[1];

    // Check that multiple dashes are not present
    // eg. xxxxxxxx-xx-xx would result in array size of 3
    if (rx_number.length > 2) {
      return false;
    }

    // Check that rx number does not end in dash
    // eg. xxxxxxxx- would result in array size of 2
    //  [xxxxxxxx, ""]
    if (cnt == "") {
      return false;
    }

    if (rx.length < 5 || rx.length > 12) {
      return false;
    }

    if ((cnt && cnt.length < 1) || (cnt && cnt.length > 2)) {
      return false;
    }

    // must be all numbers
    var reg = /^\d+$/;
    if (reg.test(rx) == false) {
      return false;
    }

    if (cnt && reg.test(cnt) == false) {
      return false;
    }

    return true;
  }

  static isValidNDC(val) {
    if (!val) return true;
    // if it contains a dash, use one of the following formats
    if (val.includes("-")) {
      // 3 valid format strings with dashes
      var reg1 = /^\d{5}-\d{4}-\d{2}$/;
      var reg2 = /^^\d{6}-\d{3}-\d{2}$$/;
      var reg3 = /^^\d{6}-\d{4}-\d{1}$$/;

      return reg1.test(val) || reg2.test(val) || reg3.test(val);
    } else {
      var reg = /^\d+$/;
      if (reg.test(val) == false) {
        return false;
      }

      return val.length == 11;
    }
  }

  static isValidHCPCSCode(val) {
    if (!val) return true;

    if (val[0] == "J" || val[0] == "Q") {
      return val.length == 5;
    }

    return false;
  }

  static isValidHCPCSModifier(val) {
    if (val) return length == 2;
    return false;
  }

  static isValidDiagnosisCode(val) {
    if (val) return val.length >= 3 && val.length <= 7;
    return false;
  }

  static isValidQuantity(val) {
    // quantity is required
    if (!val) {
      return false;
    }

    // Check if string contains any character that is not a number, a period or a comma
    if (!val.match(/^-?\d*(\.\d+)?$/)) return false;

    return !isNaN(parseFloat(val));
  }

  static isQuestionableQuantity(val) {
    return parseFloat(val) > 5000;
  }

  static isValidWholesalerInvoiceNumber(val) {
    if (!val) return true;

    // allow blank values
    if (val) {
      // numbers, dashes, commas, period and pound
      var reg = /^[0-9-,.#]+$/;

      return reg.test(val);
    }
  }

  static isQuestionableWholesalerInvoiceNumber(val) {
    var valid = val.length >= 5 && val.length <= 17;
    return !valid;
  }

  static isValidPrescriberIDQualifier(val) {
    if (!val) return true;

    // must be either 01 or 12
    var reg = /^(01|1|12)$/;

    return reg.test(val);
  }

  static isValidProviderIDQualifier(val) {
    if (!val) return true;

    // must be either 01 or 12
    var reg = /^(01|1|05|5|07|7|10|12)$/;

    return reg.test(val);
  }

  static isValidPrescriberID(qualifier, val) {
    if (!val) return true;

    return this.isValidProviderID(qualifier, val);
  }

  static isValidServiceProviderID(qualifier, val) {
    return this.isValidProviderID(qualifier, val);
  }

  static isValidBillingServiceProviderID(qualifier, val) {
    if (!val) return true;

    return this.isValidProviderID(qualifier, val);
  }

  static isValidRenderingPhysicianID(qualifier, val) {
    if (!val) return true;

    return this.isValidProviderID(qualifier, val);
  }

  static isValidProviderID(qualifier, val) {
    var valid = false;
    switch (qualifier) {
      case "1":
      case "01":
        valid = Validations.isValidNPI(val);
        break;
      case "7":
      case "07":
        valid = Validations.isValidNCPDP(val);
        break;
      case "12":
        valid = Validations.isValidDEA(val);
        break;
      default:
        if (Validations.isValidNPI(val) || Validations.isValidNCPDP(val) || Validations.isValidDEA(val)) {
          valid = true;
        }
    }
    return valid;
  }

  static luhnCheck(val) {
    let nCheck = 24,
      bEven = false;
    val = val.replace(/\D/g, "");

    for (var n = val.length - 1; n >= 0; n--) {
      var cDigit = val.charAt(n),
        nDigit = parseInt(cDigit, 10);

      if (bEven && (nDigit *= 2) > 9) nDigit -= 9;

      nCheck += nDigit;
      bEven = !bEven;
    }

    return nCheck % 10 == 0;
  }

  static isValidNPI(val) {
    // must be all numbers
    var reg = /^\d+$/;
    if (reg.test(val) == false) {
      return false;
    }

    // must not start with zero
    if (val.charAt(0) == "0") {
      return false;
    }

    // must be 10 digits
    if (val.length != 10) {
      return false;
    }

    // perform luhn check
    return Validations.luhnCheck(val);
  }

  static isValidPlaceOfServiceCode(val) {
    if (!val) return true;

    // must be all numbers
    var reg = /^\d+$/;
    if (reg.test(val)) {
      return val.length == 2;
    }

    return false;
  }

  // https://ushik.ahrq.gov/ViewItemDetails?system=sdo&itemKey=127238000
  static nabpCheck(val) {
    var a = parseInt(val.charAt(0)) + parseInt(val.charAt(2)) + parseInt(val.charAt(4));
    var b = 2 * (parseInt(val.charAt(1)) + parseInt(val.charAt(3)) + parseInt(val.charAt(5)));

    return (a + b) % 10 == parseInt(val.charAt(6));
  }

  static isValidNCPDP(val) {
    // must be numeric
    var reg = /^\d+$/;
    if (reg.test(val) == false) {
      return false;
    }

    // must be 7 digits
    if (val.length != 7) {
      return false;
    }

    // must have at most 1 leading zero
    if (val.charAt(0) == "0") {
      if (val.charAt(1) == "0") {
        return false;
      }
    }

    // must conform to the following checksum
    // https://ushik.ahrq.gov/ViewItemDetails?system=sdo&itemKey=127238000
    return Validations.nabpCheck(val);
  }

  static deaCheck(val) {
    var array = val.split("");
    var uid = array.slice(2, 8);
    var checksum = array[array.length - 1];

    var value = (
      parseInt(uid[0]) +
      parseInt(uid[2]) +
      parseInt(uid[4]) +
      2 * (parseInt(uid[1]) + parseInt(uid[3]) + parseInt(uid[5]))
    )
      .toString()
      .split("");

    return value[value.length - 1] == checksum;
  }

  static isValidDEA(val) {
    if (!val || val.length != 9) {
      return false;
    }

    // first character must be one of the following (A|B|C|D|E|F|G|H|J|K|L|M|P|R|S|T|U|X)
    var reg = /^(A|B|C|D|E|F|G|H|J|K|L|M|P|R|S|T|U|X)[A-Za-z0-9][\d]+$/;

    if (reg.test(val) == false) {
      return false;
    }

    // must conform to the following checksum
    // https://www.meditec.com/blog/dea-numbers-what-do-they-mean/
    return Validations.deaCheck(val);
  }

  static isValidContractedEntityID(val) {
    // alphanumeric and dash only
    var reg1 = /^[a-zA-Z0-9-]+$/;

    // must be an accepted prefix
    var reg2 = /^CAN|RRC|CAH|PED|FQ|SCH|BL|DSH|CH|TB|FP|RW4|HV|STD|NH|UI|URB|HM|RWI/;

    return reg1.test(val) && reg2.test(val);
  }
}
