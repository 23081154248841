import React, { useState } from "react";
import _ from "lodash";
import { Check } from "@material-ui/icons";

const ChipSelector = (props) => {
  const renderCheck = (item) => {
    if (props.selected == item) {
      return (
        <span style={{ display: "inline-flex", marginRight: 5, fontSize: 14 }}>
          <Check fontSize="inherit" />
        </span>
      );
    }
  };

  const handleSelection = (value) => {
    if (!props.required && props.selected && props.selected == value) {
      props.setSelected("");
    } else {
      props.setSelected(value);
    }
  };

  const renderChips = () => {
    if (props.items == null || props.items.length == 0) {
      return <div className="chip chip--disabled">No items to select</div>;
    }
    if (props.disabled) {
      return _.map(props.items, (item, index) => {
        return (
          <div
            key={index}
            className="chip chip--disabled"
          >
            {renderCheck(item.value)}
            {item.label}
          </div>
        );
      });
    }
    return _.map(props.items, (item, index) => {
      return (
        <div
          key={index}
          className={props.selected == item.value ? "chip chip--selected" : "chip"}
          onClick={() => {
            handleSelection(item.value);
          }}
        >
          {renderCheck(item.value)}
          {item.label}
        </div>
      );
    });
  };

  return renderChips();
};

export default ChipSelector;
