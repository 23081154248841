import React, { useState, useRef, useEffect } from "react";
import _ from "lodash";
import Ucb from "~/images/UCB.png";

const AgreementUcb = (props) => {
  const renderAgreement = () => {
    if (props.selectedManufacturer == "ucb") {
      return (
        <div>
          <div className="logo__container">
            <div className="logo" style={{ backgroundColor: "#0054a4" }}>
              <img src={Ucb} />
            </div>
            <div className="logo__info">
              <div className="logo__info__title">UCB</div>
              <div className="logo__info__subtitle">Wholly Owned Contract Pharmacy Exemption Form</div>
            </div>
          </div>
          <p>
            For orders placed on or after December 13, 2021, wholesalers on behalf of UCB Inc. (UCB) will provide
            products purchased at the 340B price only to locations registered within the Health Resources and Services
            Administration (HRSA) database as a 340B covered entity or child site location, subject to limited
            exceptions detailed below. Pharmacies registered within the HRSA database as a contract pharmacy will no
            longer be provided Bill To / Ship To replenishment orders. To ensure all 340B covered entities can access
            UCB products at the 340B price, any covered entity that does not have an in-house pharmacy capable of
            dispensing 340B purchased drugs to its patients may designate a single contract pharmacy location by
            visiting www.340Besp.com/designations.
          </p>
          <p>
            Under UCB’s new 340B policy, contract pharmacies that are wholly owned by a 340B covered entity hospital or
            have common ownership with a health system will continue to be provided Bill To / Ship To replenishment
            orders of drugs purchased at the 340B price. These pharmacies must be registered within the HRSA database as
            a contract pharmacy of the 340B covered entity hospital. If a covered entity or health system is granted an
            exemption from UCB’s 340B policy for its wholly owned contract pharmacy(ies), it may not also designate an
            independent contract pharmacy pursuant to the policy for covered entities that lack an in-house pharmacy.
          </p>
          <p>
            Covered entities must complete the wholly owned contract pharmacy exemption form on the 340B ESP website to
            apply for this exemption. Proof of ownership documents are required in order to submit your request.
            Examples of proof of ownership documents include:
          </p>
          <ul>
            <li>Certificate of liability insurance</li>
            <li>State business licenses</li>
          </ul>
          <p>
            Requests for Wholly Owned Contract Pharmacy Exemptions will be processed within five (5) business days of
            receipt of the documents. Please note that failure to populate all required fields will delay the processing
            of Wholly Owned Contract Pharmacy Exemption requests. Exemptions will be valid for twelve (12) months from
            approval date and after the initial 12-month period, UCB may require covered entities to resubmit proof of
            ownership documents to validate the continuation of existing exemptions.
          </p>
          <p>
            By signing this form the authorized representative of the covered entity certifies that the covered entity
            and contract pharmacy(ies) included in this Exemption request: (i) have the same corporate ownership, OR
            (ii) that the covered entity wholly owns the identified contract pharmacy(ies); AND (iii) the covered entity
            qualifies for the exemption as described in this form. If there are any changes to the ownership structure
            of any of the contract pharmacies identified in this form, the covered entity agrees to notify UCB at
            support@340besp.com within five (5) business days of such change of ownership.
          </p>
          <div className="register__divider" />
        </div>
      );
    } else {
      return null;
    }
  };

  return renderAgreement();
};

export default AgreementUcb;
