import React, { useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import inviteImage from "~/images/invite_or_find_illustration.png";
import { AsYouType, parsePhoneNumberFromString } from "libphonenumber-js";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const CustomTextField = withStyles({
  root: {
    backgroundColor: "#ffffff",
    borderRadius: 8,
    "& label.Mui-focused": {
      color: "#3D71FF",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
      "& fieldset": {
        borderColor: "#DEE7EE",
      },
      "&:hover fieldset": {
        borderColor: "#DEE7EE",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#3D71FF",
      },
    },
  },
})(TextField);

const UserRegistrationForm = (props) => {
  const [validPhoneType, setValidPhoneType] = React.useState();
  const [passwordIsMasked, setPasswordIsMasked] = React.useState(true);
  const [enableMFA, setEnableMFA] = React.useState(true);
  const [userDetails, setUserDetails] = React.useState({
    phoneNumber: "",
    password: "",
  });

  var phoneformater = new AsYouType("US");

  const fetchPhoneType = () => {
    fetch(`/api/phone/details?number=${userDetails.phoneNumber}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setValidPhoneType(data.value);
      });
  };

  const hasAtLeaseOneLower = (val) => {
    return val.match(/.*[a-z].*/) != null;
  };

  const hasAtLeaseOneUpper = (val) => {
    return val.match(/.*[A-Z].*/) != null;
  };

  const hasAtLeaseOneNumber = (val) => {
    return val.match(/.*[0-9].*/) != null;
  };

  const hasAtLeaseOneSpecial = (val) => {
    return val.match(/.*[\W_].*/) != null;
  };

  const hasAtLeaseEightChars = (val) => {
    return val.length >= 8;
  };

  const isValidPassword = (val) => {
    if (val) {
      return (
        hasAtLeaseOneLower(val) &&
        hasAtLeaseOneUpper(val) &&
        hasAtLeaseOneNumber(val) &&
        hasAtLeaseOneSpecial(val) &&
        hasAtLeaseEightChars(val)
      );
    }
  };

  const isValidPhoneNumber = (val) => {
    if (!enableMFA) return true;

    if (val) {
      var number = parsePhoneNumberFromString("+1" + val);

      if (number && number.isValid()) {
        fetchPhoneType();
      }

      if (number && number.isValid() && validPhoneType) {
        return true;
      } else {
        return false;
      }
    }
  };

  const formatPhoneNumber = (val) => {
    if (val) {
      var number = parsePhoneNumberFromString("+1" + val);
      return number && number.nationalNumber;
    }
  };

  const togglePasswordMask = () => {
    setPasswordIsMasked(!passwordIsMasked);
  };

  const enableSubmit = () => {
    return isValidPassword(userDetails.password) && isValidPhoneNumber(userDetails.phoneNumber);
  };

  const renderPhoneInput = () => {
    if (enableMFA) {
      return (
        <div>
          <CustomTextField
            label="Cell Number"
            margin="normal"
            variant="outlined"
            id="phoneNumber"
            name="phoneNumber"
            fullWidth
            required
            error={validPhoneType == false}
            helperText={validPhoneType == false ? "Please provide your cell number" : null}
            value={userDetails.phoneNumber}
            InputProps={{
              startAdornment: <InputAdornment position="start">+1</InputAdornment>,
            }}
            onChange={(event) => {
              setUserDetails({
                ...userDetails,
                phoneNumber: phoneformater.input(event.target.value),
              });
            }}
          />
          <input type="hidden" value={formatPhoneNumber(userDetails.phoneNumber) || ""} name="user[phone]" />
        </div>
      );
    }
  };

  return (
    <form method="POST" action={props.submission_path}>
      <input type="hidden" name="user_id" value={props.user_id} />
      <input
        type="hidden"
        name="authenticity_token"
        value={document.querySelector('meta[name="csrf-token"]').getAttribute("content")}
      />
      <div className="onboarding__content__form">
        <div className="onboarding__content__form__image">
          <img src={inviteImage} />
        </div>
        <div className="onboarding__content__form__title">Secure your account</div>
        <p>Complete the required fields below to register.</p>
        <div className="register__form__requirements">
          <FormControlLabel
            control={
              <Checkbox
                checked={enableMFA}
                onChange={() => {
                  setEnableMFA(!enableMFA);
                }}
                color="primary"
              />
            }
            label="Two-Factor Authentication"
          />
          <input type="hidden" value={!enableMFA} name="user[disabled_2fa]" />
          <p>
            <span className="bold">Enable Two-Factor Authentication </span>
            to add an extra layer of security. If enabled you will be required to enter an authorization code that will
            be sent to your
            <span className="bold"> Cell Number </span>
            upon login - this option can be updated in your account settings.
          </p>
        </div>
        {renderPhoneInput()}
        <CustomTextField
          label="Password"
          margin="normal"
          variant="outlined"
          id="password"
          name="user[password]"
          type={passwordIsMasked ? "password" : "text"}
          error={userDetails.password ? !isValidPassword(userDetails.password) : false}
          fullWidth
          required
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <div style={{ cursor: "pointer" }} onClick={togglePasswordMask}>
                  <span
                    style={{
                      color: "#718291",
                      fontSize: 14,
                      marginRight: 10,
                      verticalAlign: "top",
                      lineHeight: "20px",
                    }}
                  >
                    {passwordIsMasked ? "show" : "hide"}
                  </span>
                  <i style={{ fontSize: 20 }} className="solid solid-budicon-eye-sight" />
                </div>
              </InputAdornment>
            ),
          }}
          onChange={(event) => {
            setUserDetails({
              ...userDetails,
              password: event.target.value,
            });
          }}
        />
        <div className="register__form__requirements">
          <ul>
            <li className={hasAtLeaseOneLower(userDetails.password) ? "success" : null}>One lowercase character</li>
            <li className={hasAtLeaseOneUpper(userDetails.password) ? "success" : null}>One uppercase character</li>
            <li className={hasAtLeaseOneNumber(userDetails.password) ? "success" : null}>One number</li>
          </ul>
        </div>
        <div className="register__form__requirements">
          <ul>
            <li className={hasAtLeaseOneSpecial(userDetails.password) ? "success" : null}>One special character</li>
            <li className={hasAtLeaseEightChars(userDetails.password) ? "success" : null}>8 characters minimum</li>
          </ul>
        </div>

        <div className="register__form__actions">
          <a href="/logout">
            <div className="btn btn--outline btn--white">Cancel and return home</div>
          </a>
          <button
            className={enableSubmit() ? "btn" : "btn btn--disabled"}
            disabled={enableSubmit() ? false : true}
            style={{ flexGrow: 1, marginLeft: 30 }}
            type="submit"
          >
            Continue
          </button>
        </div>
      </div>
    </form>
  );
};

export default UserRegistrationForm;
