import React, { useState, useEffect, useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import Notice from "./Notice";
import Sanofi from "~/images/Sanofi.png";
import _ from "lodash";

export default function PharmacyDetailsTable(props) {
  const [editing, setEditing] = React.useState(false);
  const [displayConfirmation, setDisplayConfirmation] = React.useState(false);
  const [attestingPharmacies, setAttestingPharmacies] = React.useState([]);
  const [pharmacies, setPharmacies] = React.useState(props.pharmacies);
  const [notice, setNotice] = React.useState({
    kind: "error",
    open: false,
    message: "",
  });

  const renderActionButton = () => {
    if (editing) {
      return (
        <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
          <div
            className={`${attestingPharmacies.length > 0 ? "btn" : "btn btn--disabled"}`}
            onClick={() => {
              if (attestingPharmacies.length > 0) {
                setDisplayConfirmation(true);
              }
            }}
          >
            Save Attestations
          </div>
          <div
            className="btn btn--outline btn--white"
            style={{ marginLeft: 20 }}
            onClick={() => {
              setEditing(!editing);
            }}
          >
            Cancel
          </div>
        </div>
      );
    } else {
      return (
        <div
          className="btn btn--outline btn--white"
          onClick={() => {
            setEditing(!editing);
          }}
        >
          Make Attestations
        </div>
      );
    }
  };

  const renderPharmacyStatus = (pharmacy) => {
    return <div className="tag  tag--small tag--with-tooltip tag--square tag--light-green">Attested</div>;
  };

  const renderPharmacies = () => {
    return pharmacies.map((pharmacy, i) => {
      return (
        <tr key={i} className="sm">
          <td className="t--md">
            <div className="t--sm t--500">{pharmacy.pharmacy_name}</div>
            <div className="t--sm t--subtitle">{pharmacy.address}</div>
          </td>
          <td className="t--md" style={{ width: 80 }}>
            <div className="manufacturer__logo__group__container">
              <div className="manufacturer__logo__group__logo">
                <img src={Sanofi} />
              </div>
            </div>
          </td>

          <td className="t--md" style={{ fontSize: 12 }}>
            {pharmacy.health_industry_number}
          </td>
          <td className="t--md">{renderPharmacyStatus(pharmacy)}</td>
          <td className="t--md">
            <div className="btn btn--sq btn--sq--tiny" style={{ fontSize: 16 }}>
              <div className="solid solid-budicon-chevron-bottom-a" />
            </div>
          </td>
        </tr>
      );
    });
  };
  return (
    <div>
      <div className="table__actionbar table__actionbar--spacebetween table__actionbar--sticky">
        <div className="section__title">Contracted Pharmacies</div>
        {renderActionButton()}
      </div>

      <div className="table__container" style={{ padding: "0 5px", marginBottom: 50 }}>
        <table className="primary">
          <thead>
            <tr className="sm">
              <th>Details</th>
              <th style={{ width: 80 }}>MFG</th>
              <th>HIN</th>
              <th>
                <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>Status</div>
              </th>
              <th>Info</th>
            </tr>
          </thead>

          <tbody>{renderPharmacies()}</tbody>
        </table>
      </div>
      <Notice details={notice} />
    </div>
  );
}
