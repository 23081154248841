import React, { useState } from "react";
import _ from "lodash";
import { ChevronRight } from "@material-ui/icons";
import NotificationsActiveOutlinedIcon from "@material-ui/icons/NotificationsActiveOutlined";

const Announcement = (props) => {
  return (
    <div className="announcements">
      <div className="t--md t--500">Announcements</div>
      <div className="announcements__container">
        {props.announcements.map((a, index) => (
          <div className="tile" key={`${a.title}_${index}`}>
            <div className="tile__header tile__header--spread">
              <div className="t--md t--500">
                <NotificationsActiveOutlinedIcon /> {a.title}
              </div>

              <div className="btn btn--sqr">
                <ChevronRight fontSize="small" />
              </div>
            </div>

            <div className="t--sm">{a.content}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Announcement;
