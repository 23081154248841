import React, { useRef, useState, useEffect } from "react";
import _ from "lodash";

import { validationDescriptions } from "./notices/validationDescriptions";
import { mappingWarnings } from "./notices/mappingWarnings";

const Errors = (props) => {
  const validations = validationDescriptions();
  const warnings = mappingWarnings();

  const renderWarnings = () => {
    // return props.columns.map((columnName, index) => {
    //   if (props.mappingWarnings[columnName]) {
    //     const warning = warnings[columnName];

    //     return (
    //       <div key={index} className="notify__error notify__error--warning animated fadeInUp">
    //         <div className="notify__error__col">
    //           <div className="notify__error__error-title notify__error__error-title--warning">Warning</div>
    //         </div>

    //         <div className="notify__error__col notify__error__col--spread">
    //           <div className="notify__error__description-title">{warning.title}</div>
    //           <div className="notify__error__description">{warning.description}</div>
    //         </div>
    //       </div>
    //     );
    //   }
    // });
    return null;
  };

  const renderErrors = () => {
    // return props.columns.map((columnName) => {
    //   if (props.errors[columnName]) {
    //     const error = validations[columnName];
    //     return (
    //       <div key={columnName} className="notify__error animated fadeInUp">
    //         <div className="notify__error__col">
    //           <div className="notify__error__error-title">Validation Error</div>
    //         </div>

    //         <div className="notify__error__col notify__error__col--spread">
    //           <div className="notify__error__description-title">{error.title}</div>
    //           <div className="notify__error__description">{error.description}</div>
    //         </div>
    //       </div>
    //     );
    //   }
    // });
    return null;
  };

  return (
    <div className="notify__error__container">
      {renderWarnings()}
      {renderErrors()}
    </div>
  );
};

export default Errors;
