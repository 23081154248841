import React, { useState, useEffect, useRef } from "react";
import Notice from "./Notice";
import PharmaciesTable from "./PharmaciesTable";
import Merck from "~/images/Merck.png";
import Sanofi from "~/images/Sanofi.png";
import BoehringerIngelheim from "~/images/BoehringerIngelheim.png";
import Novartis from "~/images/Novartis.png";

import _ from "lodash";
import Select from "react-select";

const customSelectStyles = {
  container: (base) => ({
    ...base,
    margin: "10px 20px 0 0",
  }),
  input: (base) => ({
    ...base,
    fontSize: 14,
  }),
  singleValue: (base) => ({
    ...base,
    fontSize: 12,
  }),
  menu: (base) => ({
    ...base,
    fontSize: 12,
  }),
  menuList: (base) => ({
    ...base,
    fontWeight: 400,
    color: "#0A162F",
  }),
  placeholder: (base) => ({
    ...base,
    fontSize: 12,
    fontStyle: "italic",
    color: "#718291",
  }),
  control: (base) => ({
    ...base,
    minHeight: 36,
    borderColor: "#718291",
  }),
};

const EntityDetails = (props) => {
  const [editing, setEditing] = useState(_.map(props.entities, (e) => ({ id340B: e.id340B, editing: false })));
  const [entities, setEntities] = useState(props.entities);
  const [loading, setLoading] = useState(false);
  const [selectedManufacturer, setSelectedManufacturer] = useState("boehringer_ingelheim");
  const [confirmations, setConfirmations] = useState(props.confirmations);
  const [detailsToSubmit, setDetailsToSubmit] = useState({});
  const [designationsToSubmit, setDesignationsToSubmit] = useState([]);
  const [manufacturers, setManufacturers] = useState([
    {
      name: "boehringer_ingelheim",
      label: "Boehringer Ingelheim",
      hin: true,
      confirmedPolicy: false,
      policy_doc_url: "/Boehringer Ingelheim - Letter to 340B Covered Entities.pdf",
      policy_doc_name: "Boehringer Ingelheim - Letter to 340B Covered Entities",
    },
    {
      name: "merck",
      label: "Merck",
      hin: true,
      confirmedPolicy: false,
      policy_doc_url: "/Merck 340B Program Integrity Initiative Letter - July 31 2023.pdf",
      policy_doc_name: "Merck 340B Program Integrity Initiative Letter - July 31 2023.pdf",
    },
    {
      name: "sanofi",
      label: "Sanofi",
      hin: true,
      confirmedPolicy: false,
      policy_doc_url: "/340B Sanofi Covered Entity Letter.pdf",
      policy_doc_name: "340B Sanofi Covered Entity Letter.pdf",
    },
    {
      name: "novartis",
      hin: false,
      policy_doc_url: "/Novartis_Exception_Policy_11.16.2020.docx",
      policy_doc_name: "Novartis_Exception_Policy-2020-11-16",
    },
  ]);

  const [notice, setNotice] = React.useState({
    kind: "error",
    open: false,
    message: "",
  });

  const tpas = [
    { value: "340Basics", label: "340Basics" },
    { value: "340B Complete", label: "340B Complete" },
    { value: "American Health Care", label: "American Health Care" },
    { value: "Atria", label: "Atria" },
    { value: "CaptureRx", label: "CaptureRx" },
    { value: "Cirrus Pharmacy Systems", label: "Cirrus Pharmacy Systems" },
    { value: "PDMI", label: "PDMI" },
    { value: "Omnicell 340B", label: "Omnicell 340B" },
    { value: "MacroHelix", label: "MacroHelix" },
    { value: "Maxor Pharmacies", label: "Maxor Pharmacies" },
    { value: "Nuvem", label: "Nuvem" },
    { value: "Sentry Data Systems", label: "Sentry Data Systems" },
    { value: "Sun Rx", label: "Sun Rx" },
    { value: "Verity Solutions", label: "Verity Solutions" },
    { value: "Wellpartner", label: "Wellpartner" },
  ];

  const postConfirmPolicy = () => {
    if (loading) return;

    setLoading(true);
    fetch(props.confirmPolicyPath, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
      },
      body: JSON.stringify({ manufacturer: selectedManufacturer }),
    })
      .then((res) => {
        if (res.status >= 200 && res.status <= 299) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((data) => {
        setConfirmations(data.confirmations);
        setNotice({
          kind: "success",
          open: true,
          message: data.notice,
        });
        setLoading(false);
      })
      .catch((response) => {
        response.json().then((res) => {
          setNotice({
            kind: "error",
            open: true,
            message: "error please refresh and try again",
          });
        });
      });
  };

  const putEntityDetails = (entityID) => {
    if (loading) return;

    if (!detailsToSubmit.length && !designationsToSubmit.length) {
      setEditing(_.map(props.entities, (e) => ({ id340B: e.id340B, editing: false })));
    } else {
      setLoading(true);
      fetch(`/organization/${props.organizationID}/entities/${entityID}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
        },
        body: JSON.stringify({ entityDetails: detailsToSubmit, designations: designationsToSubmit }),
      })
        .then((res) => {
          if (res.status >= 200 && res.status <= 299) {
            return res.json();
          } else {
            throw res;
          }
        })
        .then((data) => {
          setEditing(_.map(props.entities, (e) => ({ id340B: e.id340B, editing: false })));
          setEntities(data.entities);
          setDetailsToSubmit({});
          setDesignationsToSubmit([]);
          if (data.invalidDesignations) {
            document.getElementById("noticeBannerHin").style.display = null;
          } else {
            document.getElementById("noticeBannerHin").style.display = "none";
          }
          setNotice({
            kind: "success",
            open: true,
            message: data.notice,
          });
          setLoading(false);
        })
        .catch((response) => {
          setLoading(false);

          response.json().then((res) => {
            setNotice({
              kind: "error",
              open: true,
              message: res.error,
            });
          });
        });
    }
  };

  const assignEntityDetails = (entity, selection) => {
    var entityDetails = _.cloneDeep(detailsToSubmit);
    var details = _.find(entityDetails, { id340B: entity.id340B });

    if (details) {
      details.tpaName = selection.value;
    } else {
      entityDetails.id340B = entity.id340B;
      entityDetails.tpaName = selection.value;
    }

    setDetailsToSubmit(entityDetails);
  };

  const assignEntityDesignations = (entity, manufacturer, selection) => {
    var entityDetails = _.cloneDeep(designationsToSubmit);
    var designation = _.find(entityDetails, { manufacturer: manufacturer, id340B: entity.id340B });

    if (designation) {
      designation.pharmacy_name = selection.label;
      designation.pharmacy_id = selection.value;
      designation.hin = selection.hin;
    } else {
      entityDetails.push({
        manufacturer: manufacturer,
        id340B: entity.id340B,
        pharmacy_name: selection.label,
        pharmacy_id: selection.value,
        hin: selection.hin,
      });
    }

    setDesignationsToSubmit(entityDetails);
  };

  const renderRemainingDesignationCount = (count) => {
    var total = 2;
    var remaining = total - count;

    if (remaining < 0) {
      return 0;
    } else {
      return remaining;
    }
  };

  const renderOneContractPharmacies = (entity) => {
    switch (selectedManufacturer) {
      case "sanofi":
        return (
          <div className="stat-cards__form__fieldset" style={{ padding: "8px 20px" }}>
            <div
              className="stat-cards__form__fieldset__details stat-cards__form__fieldset__details--no-br"
              style={{ display: "inline-flex", alignItems: "center" }}
            >
              <div className="stat-cards__tile__icon stat-cards__tile__icon--border" style={{ marginRight: 10 }}>
                <img src={Sanofi} />
              </div>
              <span>
                <div className="stat-cards__form__fieldset__details__title">Sanofi</div>
                <div className="stat-cards__form__fieldset__details__subtitle">One Contract Pharmacy</div>
              </span>
            </div>

            <div className="stat-cards__form__fieldset__values">
              <div className="stat-cards__form__fieldset__values__note">Available Updates</div>
              <div className="stat-cards__form__fieldset__values__subtitle align-right">
                {renderRemainingDesignationCount(entity.designationsCount.sanofi)} remaining
              </div>
            </div>
            {renderPharmacyDetails(entity, "sanofi")}
          </div>
        );
      case "novartis":
        return <div></div>;
      case "merck":
        return (
          <div className="stat-cards__form__fieldset" style={{ padding: "8px 20px" }}>
            <div
              className="stat-cards__form__fieldset__details stat-cards__form__fieldset__details--no-br"
              style={{ display: "inline-flex", alignItems: "center" }}
            >
              <div className="stat-cards__tile__icon stat-cards__tile__icon--border" style={{ marginRight: 10 }}>
                <img src={Merck} />
              </div>
              <span>
                <div className="stat-cards__form__fieldset__details__title">Merck</div>
                <div className="stat-cards__form__fieldset__details__subtitle">One Contract Pharmacy</div>
              </span>
            </div>

            <div className="stat-cards__form__fieldset__values">
              <div className="stat-cards__form__fieldset__values__note">Available Updates</div>
              <div className="stat-cards__form__fieldset__values__subtitle align-right">
                {renderRemainingDesignationCount(entity.designationsCount.merck)} remaining
              </div>
            </div>
            {renderPharmacyDetails(entity, "merck")}
          </div>
        );
      case "boehringer_ingelheim":
        return (
          <div className="stat-cards__form__fieldset" style={{ padding: "8px 20px" }}>
            <div
              className="stat-cards__form__fieldset__details stat-cards__form__fieldset__details--no-br"
              style={{ display: "inline-flex", alignItems: "center" }}
            >
              <div className="stat-cards__tile__icon stat-cards__tile__icon--border" style={{ marginRight: 10 }}>
                <img src={BoehringerIngelheim} />
              </div>
              <span>
                <div className="stat-cards__form__fieldset__details__title">Boehringer Ingelheim</div>
                <div className="stat-cards__form__fieldset__details__subtitle">One Contract Pharmacy</div>
              </span>
            </div>

            <div className="stat-cards__form__fieldset__values">
              <div className="stat-cards__form__fieldset__values__note">Available Updates</div>
              <div className="stat-cards__form__fieldset__values__subtitle align-right">
                {renderRemainingDesignationCount(entity.designationsCount.boehringer_ingelheim)} remaining
              </div>
            </div>
            {renderPharmacyDetails(entity, "boehringer_ingelheim")}
          </div>
        );
    }
  };

  const customOption = (props) => {
    return (
      <div
        {...props.innerProps}
        style={{ margin: "5px 10px", paddingBottom: 5, borderBottom: "1px solid #F2F5F8", cursor: "pointer" }}
      >
        <div style={{ fontSize: 11, fontWeight: 500 }}>{props.data.label}</div>
        <div style={{ color: "#718291", fontSize: 11 }}>{props.data.address ? props.data.address : "--"}</div>
      </div>
    );
  };

  const renderEditDesignationWithHin = (manufacturer, entity) => {
    return (
      <div>
        {renderProhibitedNotice(entity)}
        <div className="stat-cards__form__fieldset__details">
          <div className="stat-cards__form__fieldset__details__title stat-cards__form__fieldset__details__title--mt">
            Designated pharmacy
          </div>
          <Select
            openMenuOnFocus={false}
            openMenuOnClick={true}
            name={`pharmacy[${manufacturer}][${entity.id340B}]`}
            placeholder={"Search Pharmacies"}
            styles={customSelectStyles}
            options={entity.pharmacyOptionsForSelect}
            components={{ Option: customOption }}
            value={{
              label:
                getDesignation(entity).title == "Not Assigned" ? "Search Pharmacies" : getDesignation(entity).title,
              value: getDesignation(entity).id,
            }}
            onChange={(selection) => assignEntityDesignations(entity, manufacturer, selection)}
            isClearable={false}
          />
        </div>
        <div className="stat-cards__form__fieldset__values">
          <div className="stat-cards__form__fieldset__details__title stat-cards__form__fieldset__details__title--mt">
            Health Industry Number (HIN)
          </div>
          <div className="stat-cards__form__fieldset__values__subtitle">{getDesignation(entity).hin}</div>
        </div>
        {renderHinNotice(entity)}
      </div>
    );
  };

  const renderEditDesignationWithOutHin = (manufacturer, entity) => {
    return (
      <div className="stat-cards__form__fieldset__details stat-cards__form__fieldset__details--no-br">
        {renderProhibitedNotice(entity)}
        <div className="stat-cards__form__fieldset__details__title stat-cards__form__fieldset__details__title--mt">
          Designated pharmacy
        </div>
        <Select
          components={{ DropdownIndicator: null, IndicatorSeparator: null }}
          openMenuOnFocus={false}
          openMenuOnClick={true}
          name={`pharmacy[${manufacturer}][${entity.id340B}]`}
          placeholder={"Search Pharmacies"}
          styles={customSelectStyles}
          options={entity.pharmacyOptionsForSelect}
          onChange={(selection) => assignEntityDesignations(entity, manufacturer, selection)}
          isClearable={false}
        />
      </div>
    );
  };

  const renderPharmaciesForSelect = (entity, manufacturer) => {
    switch (manufacturer) {
      case "sanofi":
        if (confirmations["manufacturers"]["sanofi"] == false) {
          return (
            <div className="stat-cards__confirmation stat-cards__confirmation--disabled" style={{ marginTop: 10 }}>
              <span>
                Sanofi’s 340B integrity initiative permits any 340B covered entity that does not have the ability to
                dispense 340B purchased drugs to its patients to designate a single contract pharmacy for this purpose,
                irrespective of whether the covered entity provides the data Sanofi requests. By designating a contract
                pharmacy in this web form you are acknowledging that your 340B covered entity does not have an in-house
                pharmacy that is capable of dispensing 340B purchased medicines to eligible patients.
              </span>
              <div className="stat-cards__confirmation__footer">
                <span>
                  <div className="indicator indicator--red indicator--pulse" style={{ marginTop: 3 }}></div>
                  Please confirm policy before assigning designation
                </span>
                <span>
                  <div
                    className="btn btn--ib btn--tiny btn--label"
                    style={{ marginRight: 10 }}
                    onClick={() => postConfirmPolicy()}
                  >
                    Confirm
                  </div>
                  <div
                    className="btn btn--ib btn--tiny btn--label color--subtitle"
                    onClick={() => setEditing(_.map(props.entities, (e) => ({ id340B: e.id340B, editing: false })))}
                  >
                    Cancel
                  </div>
                </span>
              </div>
            </div>
          );
        } else {
          var manufacturerDetails = _.find(manufacturers, { name: selectedManufacturer });

          return manufacturerDetails.hin
            ? renderEditDesignationWithHin(manufacturer, entity)
            : renderEditDesignationWithOutHin(manufacturer, entity);
        }
      case "merck":
        if (confirmations["manufacturers"]["merck"] == false) {
          return (
            <div className="stat-cards__confirmation stat-cards__confirmation--disabled" style={{ marginTop: 10 }}>
              <span>
                Merck’s 340B integrity initiative permits any 340B covered entity that does not have the ability to
                dispense 340B purchased drugs to its patients to designate a single contract pharmacy for this purpose,
                irrespective of whether the covered entity provides the data Merck requests. By designating a contract
                pharmacy in this web form you are acknowledging that your 340B covered entity does not have an in-house
                pharmacy that is capable of dispensing 340B purchased medicines to eligible patients.
              </span>
              <div className="stat-cards__confirmation__footer">
                <span>
                  <div className="indicator indicator--red indicator--pulse" style={{ marginTop: 3 }}></div>
                  Please confirm policy before assigning designation
                </span>
                <span>
                  <div
                    className="btn btn--ib btn--tiny btn--label"
                    style={{ marginRight: 10 }}
                    onClick={() => postConfirmPolicy()}
                  >
                    Confirm
                  </div>
                  <div
                    className="btn btn--ib btn--tiny btn--label color--subtitle"
                    onClick={() => setEditing(_.map(props.entities, (e) => ({ id340B: e.id340B, editing: false })))}
                  >
                    Cancel
                  </div>
                </span>
              </div>
            </div>
          );
        } else {
          var manufacturerDetails = _.find(manufacturers, { name: selectedManufacturer });

          return manufacturerDetails.hin
            ? renderEditDesignationWithHin(manufacturer, entity)
            : renderEditDesignationWithOutHin(manufacturer, entity);
        }
      case "boehringer_ingelheim":
        if (confirmations["manufacturers"]["boehringer_ingelheim"] == false) {
          return (
            <div className="stat-cards__confirmation stat-cards__confirmation--disabled" style={{ marginTop: 10 }}>
              <span>
                Boehringer Ingelheim's (BI) contract pharmacy policy permits any 340B covered entity that does not have
                the ability to dispense 340B purchased drugs to its patients to designate a single contract pharmacy for
                this purpose. By designating a contract pharmacy in this web form you are acknowledging that your 340B
                covered entity does not have an in-house pharmacy that is capable of dispensing 340B purchased medicines
                to eligible patients.
                <br />
                <br />
                Your contract pharmacy designation will be valid for 12 months from the date of your first designation.
                You must resubmit your designation after the 12 - month period has expired for your designation to
                remain in effect. You may only change your designation within a 12 - month period if the designated
                contract pharmacy relationship is terminated from the HRSA OPAIS database.
              </span>
              <div className="stat-cards__confirmation__footer">
                <span>
                  <div className="indicator indicator--red indicator--pulse" style={{ marginTop: 3 }}></div>
                  Please confirm policy before assigning designation
                </span>
                <span>
                  <div
                    className="btn btn--ib btn--tiny btn--label"
                    style={{ marginRight: 10 }}
                    onClick={() => postConfirmPolicy()}
                  >
                    Confirm
                  </div>
                  <div
                    className="btn btn--ib btn--tiny btn--label color--subtitle"
                    onClick={() => setEditing(_.map(props.entities, (e) => ({ id340B: e.id340B, editing: false })))}
                  >
                    Cancel
                  </div>
                </span>
              </div>
            </div>
          );
        } else {
          var manufacturerDetails = _.find(manufacturers, { name: selectedManufacturer });

          return manufacturerDetails.hin
            ? renderEditDesignationWithHin(manufacturer, entity)
            : renderEditDesignationWithOutHin(manufacturer, entity);
        }
    }
  };

  const getDesignation = (entity) => {
    var title;
    var id;
    var hin;

    var designation = _.find(entity.designations, { manufacturer: selectedManufacturer, id340B: entity.id340B });
    var selected = _.find(designationsToSubmit, { manufacturer: selectedManufacturer, id340B: entity.id340B });
    designation = selected ? selected : designation;

    if (designation) {
      title = designation.label || designation.pharmacy_name;
      id = designation.value || designation.pharmacy_id;
      hin = designation.hin;
    } else {
      title = "Not Assigned";
      id = "--";
      hin = "--";
    }

    return { title: title, id: id, hin: hin };
  };

  const renderDesignationWithHin = (entity) => {
    return (
      <div>
        <div className="stat-cards__form__fieldset__details">
          <div className="stat-cards__form__fieldset__details__title stat-cards__form__fieldset__details__title--mt color--subtitle">
            Designated pharmacy
          </div>
          <div
            className={
              getDesignation(entity).title == "Not Assigned"
                ? "stat-cards__form__fieldset__details__title color--subtitle"
                : "stat-cards__form__fieldset__details__title color--blue"
            }
          >{`${getDesignation(entity).title}`}</div>
        </div>
        <div className="stat-cards__form__fieldset__values">
          <div className="stat-cards__form__fieldset__values__title stat-cards__form__fieldset__details__title--mt color--subtitle">
            Health Industry Number (HIN)
          </div>
          <div className="stat-cards__form__fieldset__values__subtitle color--subtitle">
            {getDesignation(entity).hin}
          </div>
        </div>
        {renderHinNotice(entity)}
      </div>
    );
  };

  const renderDesignationWithOutHin = (entity) => {
    return (
      <div className="stat-cards__form__fieldset__details stat-cards__form__fieldset__details--no-br">
        <div className="stat-cards__form__fieldset__details__title stat-cards__form__fieldset__details__title--mt color--subtitle">
          Designated pharmacy
        </div>
        <div
          className={
            getDesignation(entity).title == "Not Assigned"
              ? "stat-cards__form__fieldset__details__title color--orange"
              : "stat-cards__form__fieldset__details__title color--blue"
          }
        >{`${getDesignation(entity).title}`}</div>
        {getDesignation(entity).id == "--" ? (
          <div className="stat-cards__form__fieldset__details__subtitle">--</div>
        ) : null}
      </div>
    );
  };

  const renderPharmacyDetails = (entity, manufacturer) => {
    var title;
    var id;

    if (_.find(editing, { id340B: entity.id340B }).editing) {
      return renderPharmaciesForSelect(entity, manufacturer);
    } else {
      var designation = _.find(entity.designations, { manufacturer: manufacturer, id340B: entity.id340B });
      if (designation) {
        title = designation.label || designation.pharmacy_name;
        id = designation.value || designation.pharmacy_id;
        var manufacturerDetails = _.find(manufacturers, { name: selectedManufacturer });
        return manufacturerDetails.hin ? renderDesignationWithHin(entity) : renderDesignationWithOutHin(entity);
      } else {
        return renderDesignationWithHin(entity);
      }
    }
  };

  const renderTpaDetails = (entity) => {
    if (_.find(editing, { id340B: entity.id340B }).editing) {
      return (
        <Select
          components={{ DropdownIndicator: null, IndicatorSeparator: null }}
          openMenuOnFocus={false}
          openMenuOnClick={true}
          name={`tpa_name[${entity.id340B}]`}
          placeholder={"Search TPAs"}
          styles={customSelectStyles}
          options={tpas}
          onChange={(selection) => assignEntityDetails(entity, selection)}
          isClearable={true}
        />
      );
    } else {
      return entity.tpaName ? entity.tpaName : "--";
    }
  };

  const isHinRequired = (designation) => {
    var hinRequired = _.find(manufacturers, { name: selectedManufacturer }).hin == true;

    if (hinRequired) {
      return designation.hin.length > 0;
    } else {
      return false;
    }
  };

  const renderIndicator = (entity) => {
    var designation = _.find(entity.designations, { manufacturer: selectedManufacturer, id340B: entity.id340B });

    if (designation && !isHinRequired(designation)) {
      return <div className="stat-cards__tab__indicator" />;
    } else {
      return <div className="stat-cards__tab__indicator stat-cards__tab__indicator--red" />;
    }
  };

  const renderHinNotice = (entity) => {
    var healthIndustryNumber = getDesignation(entity).hin;
    if (healthIndustryNumber && healthIndustryNumber != "--") return;

    var manufacturerDetails = _.find(manufacturers, { name: selectedManufacturer });

    if (editing && manufacturerDetails.hin && getDesignation(entity).title != "Not Assigned") {
      return (
        <div className="stat-cards__notice" style={{ lineHeight: 1.5 }}>
          Our records indicate the selected pharmacy does not have a HIN. You must register a HIN through
          <a href="https://www.hibcc.org/" target="_blank">
            <span className="color--orange" style={{ fontWeight: 500, textDecoration: "underline" }}>
              {" "}
              HIBCC{" "}
            </span>
          </a>
          in order to ship 340B purchased drugs to this pharmacy.
          <a href="http://help.340besp.com/en/articles/4816982-hin-registration-overview" target="_blank">
            <span className="color--orange" style={{ fontWeight: 500, textDecoration: "underline" }}>
              {" "}
              Learn more.
            </span>
          </a>
        </div>
      );
    }
  };

  const renderProhibitedNotice = (entity) => {
    if (entity.designationPermitted) return;

    switch (entity.designationPermittedStatus[selectedManufacturer]) {
      case "has_grantee_exemption":
        return (
          <div
            className="stat-cards__notice stat-cards__notice--red stat-cards__notice--mb"
            style={{ lineHeight: 1.5 }}
          >
            The selected entity is exempted from this policy and is not required to make a single contract pharmacy
            designation. Any designation you make will not impact your access to 340B pricing.
            <a
              href={_.find(manufacturers, { name: selectedManufacturer }).policy_doc_url}
              download={_.find(manufacturers, { name: selectedManufacturer }).policy_doc_name}
            >
              <span style={{ fontWeight: 500, textDecoration: "underline", margin: "0px 5px" }}>
                Download Policy document
              </span>
            </a>
            Please reach out to
            <span style={{ fontWeight: 500 }}> support@340besp.com </span>
            if you have any additional questions or if you do not have access to 340B pricing for your contract
            pharmacies.
          </div>
        );
      case "has_wholly_owned_exemption":
        return (
          <div
            className="stat-cards__notice stat-cards__notice--red stat-cards__notice--mb"
            style={{ lineHeight: 1.5 }}
          >
            Our records indicate the selected entity has already been granted a wholly owned contract pharmacy
            exemption. As a result of this exemption, the entity is not eligible to designate a single contract pharmacy
            arrangement and any designation you make will not impact access to 340B pricing.
            <a
              href={_.find(manufacturers, { name: selectedManufacturer }).policy_doc_url}
              download={_.find(manufacturers, { name: selectedManufacturer }).policy_doc_name}
            >
              <span style={{ fontWeight: 500, textDecoration: "underline", margin: "0px 5px" }}>
                Download Policy document
              </span>
            </a>
            Please reach out to
            <span style={{ fontWeight: 500 }}> support@340besp.com </span>
            if you have any additional questions or if you do not have access to 340B pricing for your contract
            pharmacies.
          </div>
        );
      case "has_inhouse_pharmacy":
        return (
          <div
            className="stat-cards__notice stat-cards__notice--red stat-cards__notice--mb"
            style={{ lineHeight: 1.5 }}
          >
            Our records indicate the selected entity has a pharmacy capable of purchasing and dispensing 340B drugs to
            its patients. As a result, the entity is not eligible to designate a single contract pharmacy arrangement
            and any designation you make will not impact access to 340B pricing.
            <a
              href={_.find(manufacturers, { name: selectedManufacturer }).policy_doc_url}
              download={_.find(manufacturers, { name: selectedManufacturer }).policy_doc_name}
            >
              <span style={{ fontWeight: 500, textDecoration: "underline", margin: "0px 5px" }}>
                Download Policy document
              </span>
            </a>
            Please reach out to
            <span style={{ fontWeight: 500 }}> support@340besp.com </span>
            for more information or to clarify the entity’s ability to dispense 340B purchased drugs to patients.
          </div>
        );
    }
  };

  const renderDetails = () => {
    return _.map(entities, (entity, index) => {
      if (_.find(editing, { id340B: entity.id340B }).editing) {
        return (
          <div key={index} className="card__container" style={{ borderColor: "#24B668" }}>
            <div className="card">
              <div className="card__entity__header">
                <div className="card__entity__icon">
                  <i className="solid solid-budicon-user-card" />
                </div>
                <div className="card__entity__details">
                  <div className="card__entity__details__item">{entity.id340B}</div>
                  <div className="card__entity__details__title">{entity.entityName}</div>
                  <div className="card__entity__details__subtitle">{entity.address}</div>
                </div>
                {renderActionBtn(entity)}
              </div>
              <div className="card__information__container card__information__container--bt">
                <div className="card__information__item">
                  <div className="card__information__item__title">340B ID</div>
                  <div className="card__information__item__value">{entity.id340B}</div>
                </div>
                <div className="card__information__item">
                  <div className="card__information__item__title">Entity Type</div>
                  <div className="card__information__item__value">{entity.typeCode ? entity.typeCode : "--"}</div>
                </div>
                <div className="card__information__item">
                  <div className="card__information__item__title">Primary Contact</div>
                  <div className="card__information__item__value">
                    {entity.primaryContactName ? entity.primaryContactName : "--"}
                  </div>
                </div>
                <div className="card__information__item">
                  <div className="card__information__item__title">Authorizing Official</div>
                  <div className="card__information__item__value">
                    {entity.authorizingOfficialName ? entity.authorizingOfficialName : "--"}
                  </div>
                </div>
              </div>
              <div className="card__information__container">
                <div className="card__information__item">
                  <div className="card__information__item__title">Managed By TPA</div>
                  <div className="card__information__item__value">{renderTpaDetails(entity)}</div>
                </div>
                <div className="card__information__item">
                  <div className="card__information__item__title">Latest Submission</div>
                  <div className="card__information__item__value">
                    {props.latestSubmission ? props.latestSubmission : "--"}
                  </div>
                </div>
                <div className="card__information__item">
                  <div className="card__information__item__title">Submitted Claims</div>
                  <div className="card__information__item__value">
                    {props.submissionCount ? props.submissionCount : "0"}
                  </div>
                </div>
                <div className="card__information__item">
                  <div className="card__information__item__title">Contracted Pharmacies</div>
                  <div className="card__information__item__value">
                    {entity.pharmacies ? entity.pharmacies.length : "0"}
                  </div>
                </div>
              </div>
              <div className="card__section__title">Pharmacy Details</div>
              <div className="stat-cards__tab__container stat-cards__tab__container--lg">
                <div
                  className={
                    selectedManufacturer == "boehringer_ingelheim"
                      ? "stat-cards__tab stat-cards__tab--lg stat-cards__tab--active"
                      : "stat-cards__tab stat-cards__tab--lg"
                  }
                  onClick={() => setSelectedManufacturer("boehringer_ingelheim")}
                >
                  {renderIndicator(entity)}
                  Boehringer Ingelheim
                </div>
                <div
                  className={
                    selectedManufacturer == "merck"
                      ? "stat-cards__tab stat-cards__tab--lg stat-cards__tab--active"
                      : "stat-cards__tab stat-cards__tab--lg"
                  }
                  onClick={() => setSelectedManufacturer("merck")}
                >
                  {renderIndicator(entity)}
                  Merck
                </div>
                <div
                  className={
                    selectedManufacturer == "sanofi"
                      ? "stat-cards__tab stat-cards__tab--lg stat-cards__tab--active"
                      : "stat-cards__tab stat-cards__tab--lg"
                  }
                  onClick={() => setSelectedManufacturer("sanofi")}
                >
                  {renderIndicator(entity)}
                  Sanofi
                </div>
                <div
                  className={
                    selectedManufacturer == "novartis"
                      ? "stat-cards__tab stat-cards__tab--lg stat-cards__tab--active"
                      : "stat-cards__tab stat-cards__tab--lg "
                  }
                  onClick={() => setSelectedManufacturer("novartis")}
                >
                  {renderIndicator(entity)}
                  Novartis
                </div>
              </div>
              {/* <div className="stat-cards__tile__container" style={{ margin: "20px 0 10px 0" }}>
              {renderOneContractPharmacies(entity)}
            </div> */}
              <PharmaciesTable
                organizationID={props.organizationID}
                pharmacies={entity.pharmacies}
                selectedManufacturer={selectedManufacturer}
              />
            </div>
          </div>
        );
      } else {
        return (
          <div key={index} className="card__container">
            <div className="card">
              <div className="card__entity__header">
                <div className="card__entity__icon">
                  <i className="solid solid-budicon-user-card" />
                </div>
                <div className="card__entity__details">
                  <div className="card__entity__details__item">{entity.id340B}</div>
                  <div className="card__entity__details__title">{entity.entityName}</div>
                  <div className="card__entity__details__subtitle">{entity.address}</div>
                </div>
                {renderActionBtn(entity)}
              </div>
              <div className="card__information__container card__information__container--bt">
                <div className="card__information__item">
                  <div className="card__information__item__title">340B ID</div>
                  <div className="card__information__item__value">{entity.id340B}</div>
                </div>
                <div className="card__information__item">
                  <div className="card__information__item__title">Entity Type</div>
                  <div className="card__information__item__value">{entity.typeCode ? entity.typeCode : "--"}</div>
                </div>
                <div className="card__information__item">
                  <div className="card__information__item__title">Primary Contact</div>
                  <div className="card__information__item__value">
                    {entity.primaryContactName ? entity.primaryContactName : "--"}
                  </div>
                </div>
                <div className="card__information__item">
                  <div className="card__information__item__title">Authorizing Official</div>
                  <div className="card__information__item__value">
                    {entity.authorizingOfficialName ? entity.authorizingOfficialName : "--"}
                  </div>
                </div>
              </div>
              <div className="card__information__container">
                <div className="card__information__item">
                  <div className="card__information__item__title">Managed By TPA</div>
                  <div className="card__information__item__value">{renderTpaDetails(entity)}</div>
                </div>
                <div className="card__information__item">
                  <div className="card__information__item__title">Latest Submission</div>
                  <div className="card__information__item__value">
                    {props.latestSubmission ? props.latestSubmission : "--"}
                  </div>
                </div>
                <div className="card__information__item">
                  <div className="card__information__item__title">Submitted Claims</div>
                  <div className="card__information__item__value">
                    {props.submissionCount ? props.submissionCount : "0"}
                  </div>
                </div>
                <div className="card__information__item">
                  <div className="card__information__item__title">Contracted Pharmacies</div>
                  <div className="card__information__item__value">
                    {entity.pharmacies ? entity.pharmacies.length : "0"}
                  </div>
                </div>
              </div>
              <div className="card__section__title">Pharmacy Details</div>
              <div className="stat-cards__tab__container stat-cards__tab__container--lg">
                <div
                  className={
                    selectedManufacturer == "boehringer_ingelheim"
                      ? "stat-cards__tab stat-cards__tab--lg stat-cards__tab--active"
                      : "stat-cards__tab stat-cards__tab--lg"
                  }
                  onClick={() => setSelectedManufacturer("boehringer_ingelheim")}
                >
                  {renderIndicator(entity)}
                  Boehringer Ingelheim
                </div>
                <div
                  className={
                    selectedManufacturer == "merck"
                      ? "stat-cards__tab stat-cards__tab--lg stat-cards__tab--active"
                      : "stat-cards__tab stat-cards__tab--lg"
                  }
                  onClick={() => setSelectedManufacturer("merck")}
                >
                  {renderIndicator(entity)}
                  Merck
                </div>
                <div
                  className={
                    selectedManufacturer == "sanofi"
                      ? "stat-cards__tab stat-cards__tab--lg stat-cards__tab--active"
                      : "stat-cards__tab stat-cards__tab--lg"
                  }
                  onClick={() => setSelectedManufacturer("sanofi")}
                >
                  {renderIndicator(entity)}
                  Sanofi
                </div>
                <div
                  className={
                    selectedManufacturer == "novartis"
                      ? "stat-cards__tab stat-cards__tab--lg stat-cards__tab--active"
                      : "stat-cards__tab stat-cards__tab--lg "
                  }
                  onClick={() => setSelectedManufacturer("novartis")}
                >
                  {renderIndicator(entity)}
                  Novartis
                </div>
              </div>
              {/* <div className="stat-cards__tile__container" style={{ margin: "20px 0 10px 0" }}>
              {renderOneContractPharmacies(entity)}
            </div> */}
              <PharmaciesTable
                organizationID={props.organizationID}
                pharmacies={entity.pharmacies}
                selectedManufacturer={selectedManufacturer}
              />
            </div>
          </div>
        );
      }
    });
  };

  const setEntityEditingState = (entity) => {
    var editingClone = _.cloneDeep(editing);
    _.find(editingClone, { id340B: entity.id340B }).editing = true;

    setEditing(editingClone);
  };

  const renderLoader = () => {
    if (loading) {
      return <div className="loading-indicator" style={{ margin: "0 10px 0 0" }}></div>;
    } else {
      return <i className="solid solid-budicon-pencil-writing" />;
    }
  };

  const renderActionBtn = (entity) => {
    if (_.find(editing, { id340B: entity.id340B }).editing) {
      return (
        <div style={{ float: "right" }}>
          <div className="btn btn--outline-blue btn--small btn--ib" onClick={() => putEntityDetails(entity.id)}>
            {renderLoader()}
            Save Selections
          </div>
          <div
            className="btn btn--label btn--small btn--ib"
            style={{ marginLeft: 10 }}
            onClick={() => setEditing(_.map(props.entities, (e) => ({ id340B: e.id340B, editing: false })))}
          >
            Cancel
          </div>
        </div>
      );
    } else {
      return (
        <div
          className="btn btn--outline-blue btn--small btn--ib"
          style={{ float: "right" }}
          onClick={() => setEntityEditingState(entity)}
        >
          <i className="solid solid-budicon-pencil-writing" />
          Edit
        </div>
      );
    }
  };

  return (
    <div>
      {renderDetails()}
      <Notice details={notice} />
    </div>
  );
};

export default EntityDetails;
