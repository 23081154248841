import React, { useRef, useState, useEffect } from "react";
import _ from "lodash";

const DropdownSelectMapping = (props) => {
  const [displayDropdown, setDisplayDropdown] = useState(false);

  const btnRef = useRef();

  useEffect(() => {
    const closeDropdown = (e) => {
      if (!btnRef.current.contains(e.target)) {
        setDisplayDropdown(false);
      }
    };
    document.body.addEventListener("click", closeDropdown);
    return () => document.body.removeEventListener("click", closeDropdown);
  }, []);

  // should rename position everywhere to ID - ID alias for position
  const getAttachmentByID = (id) => {
    return _.find(props.attachments, (data) => data.position == id);
  };

  const getMappingDetails = (id) => {
    var attachment = getAttachmentByID(id);

    if (attachment) {
      return attachment.mapping.name;
    } else {
      return "No Mapping Selected";
    }
  };

  const renderDropDown = () => {
    if (displayDropdown) {
      return (
        <div className="attachments__tile__dropdown__container">
          {_.map(props.mappings, (mapping, index) => {
            return (
              <div
                key={index}
                className="attachments__tile__dropdown__item"
                onClick={() => props.setSelectedMapping(mapping.id)}
              >
                {mapping.name}
              </div>
            );
          })}
        </div>
      );
    }
  };

  return (
    <div
      ref={btnRef}
      className={`attachments__tile__dropdown__button ${
        displayDropdown && "attachments__tile__dropdown__button--hover"
      }`}
      onClick={() => {
        setDisplayDropdown(!displayDropdown);
      }}
    >
      {props.selectedMapping?.name || "No Mapping Selected"}
      <i className="solid solid-budicon-chevron-bottom" style={{ fontWeight: 800 }} />
      {renderDropDown()}
    </div>
  );
};

export default DropdownSelectMapping;
