import React, { useState, useEffect, useRef } from "react";
import Notice from "../Notice";
import Merck from "~/images/Merck.png";
import Sanofi from "~/images/Sanofi.png";
import Novartis from "~/images/Novartis.png";
import AbbviePharmacyTable from "../../manufacturers/abbvie/AbbviePharmacyTable";
import AmgenPharmacyTable from "../../manufacturers/amgen/AmgenPharmacyTable";
import BauschPharmacyTable from "../../manufacturers/bausch/BauschPharmacyTable";
import BayerPharmacyTable from "../../manufacturers/bayer/BayerPharmacyTable";
import BiogenPharmacyTable from "../../manufacturers/biogen/BiogenPharmacyTable";
import EmdSeronoPharmacyTable from "../../manufacturers/emd_serono/EmdSeronoPharmacyTable";
import BiPharmacyTable from "../../manufacturers/bi/BiPharmacyTable";
import BmsPharmacyTable from "../../manufacturers/bms/BmsPharmacyTable";
import GileadPharmacyTable from "../../manufacturers/gilead/GileadPharmacyTable";
import GskPharmacyTable from "../../manufacturers/gsk/GskPharmacyTable";
import JnJPharmacyTable from "../../manufacturers/johnson_n_johnson/JnJPharmacyTable";
import ExelixisPharmacyTable from "../../manufacturers/exelixis/ExelixisPharmacyTable";
import LillyPharmacyTable from "../LillyPharmacyTable";
import MerckPharmacyTable from "../../manufacturers/merck/MerckPharmacyTable";
import NovoNordiskPharmacyTable from "../../manufacturers/novo_nordisk/NovoNordiskPharmacyTable";
import NovartisPharmacyTable from "../../manufacturers/novartis/NovartisPharmacyTable";
import PfizerPharmacyTable from "../../manufacturers/pfizer/PfizerPharmacyTable";
import SanofiPharmacyTable from "../../manufacturers/sanofi/SanofiPharmacyTable";
import UcbPharmacyTable from "../../manufacturers/ucb/UcbPharmacyTable";
import OrganonPharmacyTable from "../../manufacturers/organon/OrganonPharmacyTable";
import TevaPharmacyTable from "../../manufacturers/teva/TevaPharmacyTable";
import AstrazenecaPharmacyTable from "../../manufacturers/astrazeneca/AstrazenecaPharmacyTable";
import AstellasPharmacyTable from "../../manufacturers/astellas/AstellasPharmacyTable";

import AllPharmaciesTable from "../AllPharmaciesTable";
import EntityDropDownSelector from "./entityDropDownSelector";
import _ from "lodash";

const EntityDetails = (props) => {
  const manufacturers = [
    "all",
    "abbvie",
    "amgen",
    "astellas",
    "astrazeneca",
    "bausch",
    "bayer",
    "biogen",
    "bi",
    "bms",
    // "lilly",
    "emd serono",
    "exelixis",
    "gilead",
    "gsk",
    "jnj",
    "merck",
    "novo nordisk",
    "novartis",
    "organon",
    "pfizer",
    "sanofi",
    "teva",
    "ucb",
  ];

  const [entities, setEntities] = useState(props.entities);
  const [selectedTab, setSelectedTab] = useState("all");
  const [notice, setNotice] = React.useState({
    kind: "error",
    open: false,
    message: "",
  });

  const getManufacturerComponent = () => {
    switch (selectedTab) {
      case "all":
        return (
          <AllPharmaciesTable
            entity={props.entity}
            pharmacies={props.entity.pharmacies}
            designations={props.designations}
            eligibility={props.eligibility}
            entity_designations_status={props.entity_designations_status}
            claims_count={props.claims_count}
            mfr_designations={props.mfr_designations}
            designations_path={props.designations_path}
          />
        );
      case "abbvie":
        return (
          <AbbviePharmacyTable
            entity={props.entity}
            pharmacies={props.entity.pharmacies}
            eligibility={props.eligibility.abbvie}
            entity_designations_status={props.entity_designations_status}
            designations_path={props.designations_path}
          />
        );
      case "amgen":
        return (
          <AmgenPharmacyTable
            entity={props.entity}
            pharmacies={props.entity.pharmacies}
            eligibility={props.eligibility.amgen}
            entity_designations_status={props.entity_designations_status}
            designations_path={props.designations_path}
          />
        );
      case "astellas":
        return (
          <AstellasPharmacyTable
            entity={props.entity}
            pharmacies={props.entity.pharmacies}
            eligibility={props.eligibility.astellas}
            entity_designations_status={props.entity_designations_status}
            designations_path={props.designations_path}
          />
        );
      case "astrazeneca":
        return (
          <AstrazenecaPharmacyTable
            entity={props.entity}
            pharmacies={props.entity.pharmacies}
            eligibility={props.eligibility.astrazeneca}
            entity_designations_status={props.entity_designations_status}
            designations_path={props.designations_path}
          />
        );
      case "bausch":
        return (
          <BauschPharmacyTable
            entity={props.entity}
            pharmacies={props.entity.pharmacies}
            eligibility={props.eligibility.bausch}
            submitPath={props.attestationSubmitPath}
            entity_designations_status={props.entity_designations_status}
            designations_path={props.designations_path}
          />
        );
      case "bayer":
        return (
          <BayerPharmacyTable
            entity={props.entity}
            pharmacies={props.entity.pharmacies}
            eligibility={props.eligibility.bayer}
            entity_designations_status={props.entity_designations_status}
            designations_path={props.designations_path}
          />
        );
      case "biogen":
        return (
          <BiogenPharmacyTable
            entity={props.entity}
            pharmacies={props.entity.pharmacies}
            eligibility={props.eligibility.biogen}
            submitPath={props.attestationSubmitPath}
            entity_designations_status={props.entity_designations_status}
            designations_path={props.designations_path}
          />
        );
      case "bi":
        return (
          <BiPharmacyTable
            entity={props.entity}
            pharmacies={props.entity.pharmacies}
            eligibility={props.eligibility.boehringer_ingelheim}
            entity_designations_status={props.entity_designations_status}
            designations_path={props.designations_path}
          />
        );
      case "bms":
        return (
          <BmsPharmacyTable
            entity={props.entity}
            pharmacies={props.entity.pharmacies}
            eligibility={props.eligibility.bristol_myers_squibb}
            entity_designations_status={props.entity_designations_status}
            designations_path={props.designations_path}
          />
        );
      case "exelixis":
        return (
          <ExelixisPharmacyTable
            entity={props.entity}
            pharmacies={props.entity.pharmacies}
            eligibility={props.eligibility.exelixis}
            entity_designations_status={props.entity_designations_status}
            designations_path={props.designations_path}
          />
        );
      case "emd serono":
        return (
          <EmdSeronoPharmacyTable
            entity={props.entity}
            pharmacies={props.entity.pharmacies}
            eligibility={props.eligibility.emd_serono}
            entity_designations_status={props.entity_designations_status}
            designations_path={props.designations_path}
          />
        );
      case "gilead":
        return (
          <GileadPharmacyTable
            entity={props.entity}
            pharmacies={props.entity.pharmacies}
            eligibility={props.eligibility.gilead}
            submitPath={props.attestationSubmitPath}
            entity_designations_status={props.entity_designations_status}
            designations_path={props.designations_path}
          />
        );
      case "gsk":
        return (
          <GskPharmacyTable
            entity={props.entity}
            pharmacies={props.entity.pharmacies}
            eligibility={props.eligibility.glaxosmithkline}
            submitPath={props.attestationSubmitPath}
            entity_designations_status={props.entity_designations_status}
            designations_path={props.designations_path}
          />
        );
      case "jnj":
        return (
          <JnJPharmacyTable
            entity={props.entity}
            pharmacies={props.entity.pharmacies}
            eligibility={props.eligibility.johnson_n_johnson}
            geoJson={props.geo_json}
            submitPath={props.attestationSubmitPath}
            entity_designations_status={props.entity_designations_status}
            designations_path={props.designations_path}
          />
        );
      case "merck":
        return (
          <MerckPharmacyTable
            entity={props.entity}
            pharmacies={props.entity.pharmacies}
            eligibility={props.eligibility.merck}
            entity_designations_status={props.entity_designations_status}
            designations_path={props.designations_path}
          />
        );
      case "novartis":
        return (
          <NovartisPharmacyTable
            entity={props.entity}
            pharmacies={props.entity.pharmacies}
            eligibility={props.eligibility.novartis}
            submitPath={props.attestationSubmitPath}
            entity_designations_status={props.entity_designations_status}
            designations_path={props.designations_path}
          />
        );
      case "novo nordisk":
        return (
          <NovoNordiskPharmacyTable
            entity={props.entity}
            pharmacies={props.entity.pharmacies}
            eligibility={props.eligibility.novo_nordisk}
            submitPath={props.attestationSubmitPath}
            entity_designations_status={props.entity_designations_status}
            designations_path={props.designations_path}
          />
        );
      case "organon":
        return (
          <OrganonPharmacyTable
            entity={props.entity}
            pharmacies={props.entity.pharmacies}
            eligibility={props.eligibility.organon}
            submitPath={props.attestationSubmitPath}
            entity_designations_status={props.entity_designations_status}
            designations_path={props.designations_path}
          />
        );
      case "pfizer":
        return (
          <PfizerPharmacyTable
            entity={props.entity}
            pharmacies={props.entity.pharmacies}
            eligibility={props.eligibility.pfizer}
            entity_designations_status={props.entity_designations_status}
            designations_path={props.designations_path}
          />
        );
      case "sanofi":
        return (
          <SanofiPharmacyTable
            entity={props.entity}
            pharmacies={props.entity.pharmacies}
            eligibility={props.eligibility.sanofi}
            submitPath={props.attestationSubmitPath}
            entity_designations_status={props.entity_designations_status}
            designations_path={props.designations_path}
          />
        );
      case "teva":
        return (
          <TevaPharmacyTable
            entity={props.entity}
            pharmacies={props.entity.pharmacies}
            eligibility={props.eligibility.teva}
            submitPath={props.attestationSubmitPath}
            entity_designations_status={props.entity_designations_status}
            designations_path={props.designations_path}
          />
        );
      case "ucb":
        return (
          <UcbPharmacyTable
            entity={props.entity}
            pharmacies={props.entity.pharmacies}
            eligibility={props.eligibility.ucb}
            entity_designations_status={props.entity_designations_status}
            designations_path={props.designations_path}
          />
        );
    }
  };

  const renderTabs = () => {
    return _.map(manufacturers, (name, index) => {
      return renderTab(name, index);
    });
  };

  const renderTab = (name, index) => {
    if (selectedTab == name) {
      return (
        <div key={index} className="details__header__tab_bar__item details__header__tab_bar__item--selected">
          {name}
          {/* {eligibilityCounter(name) > 0 ? (
            <div className="details__header__tab_bar__item__counter">{eligibilityCounter(name)}</div>
          ) : null} */}
        </div>
      );
    } else {
      return (
        <div key={index} className="details__header__tab_bar__item" onClick={() => setSelectedTab(name)}>
          {name}
          {/* {eligibilityCounter(name) > 0 ? (
            <div className="details__header__tab_bar__item__counter">{eligibilityCounter(name)}</div>
          ) : null} */}
        </div>
      );
    }
  };

  const renderManufacturer = () => {
    return getManufacturerComponent();
  };

  const entityDetails = () => {
    return (
      <div className="details__header">
        <div className="details__header__partition">
          <EntityDropDownSelector
            entity={props.entity}
            entities={props.entities}
            path={props.path}
            organization={props.organization}
          />
        </div>
        <div className="details__header__partition">
          <div className="details__header__partition__item">
            <div className="details__header__partition__item__title">Administrator</div>
            <div className="details__header__partition__item__content">
              <div className="profile__avatar">
                <div className="profile__avatar__initials">{props.admin_initials}</div>
              </div>
              <div className="details__header__partition__item__content__details">
                <div className="details__header__partition__item__content__details__title">{props.admin_name}</div>
                <div className="details__header__partition__item__content__details__subtitle">{props.admin_email}</div>
              </div>
            </div>
          </div>
          <div className="details__header__partition__item">
            <div className="details__header__partition__item__title">Eligibility</div>
            <a href={props.eligibility_file_url}>
              <div className="details__header__partition__item__content">
                <div className="details__header__partition__item__content__action-icon">
                  <i className="solid solid solid-budicon-file-download"></i>
                </div>
                <div className="details__header__partition__item__content__details">
                  <div className="details__header__partition__item__content__details__title">Download File</div>
                  <div className="details__header__partition__item__content__details__subtitle">
                    340B ESP Eligibility
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className="details__header__section__info__container details__header__section__info__container--mt">
          <div className="details__header__section__info__item">
            <div className="details__header__section__info__item__title">340B ID</div>
            <div className="details__header__section__info__item__value">{props.entity.id_340b}</div>
          </div>
          <div className="details__header__section__info__item">
            <div className="details__header__section__info__item__title">Entity Type</div>
            <div className="details__header__section__info__item__value">{props.entity.entity_type_code}</div>
          </div>
          <div className="details__header__section__info__item">
            <div className="details__header__section__info__item__title">Primary Contact</div>
            <div className="details__header__section__info__item__value">{props.entity.primary_contact_name}</div>
          </div>
          <div className="details__header__section__info__item">
            <div className="details__header__section__info__item__title">Authorizing Official</div>
            <div className="details__header__section__info__item__value">{props.entity.authorizing_official_name}</div>
          </div>
        </div>
        <div className="details__header__section__info__container details__header__section__info__container--bb">
          <div className="details__header__section__info__item">
            <div className="details__header__section__info__item__title">340B ESP Registration</div>
            <div className="details__header__section__info__item__value">{props.entity.registered_at}</div>
          </div>
          <div className="details__header__section__info__item">
            <div className="details__header__section__info__item__title">Designations</div>
            <div className="details__header__section__info__item__value">{props.entity.designations_count}</div>
          </div>
          <div className="details__header__section__info__item">
            <div className="details__header__section__info__item__title">Contracted Pharmacies</div>
            <div className="details__header__section__info__item__value">{props.entity.pharmacies.length}</div>
          </div>
          <div className="details__header__section__info__item">
            <div className="details__header__section__info__item__title">Users</div>
            <div className="details__header__section__info__item__value">{props.user_count}</div>
          </div>
        </div>
        <div className="details__header__tab_bar">{renderTabs()}</div>
      </div>
    );
  };

  return (
    <>
      {entityDetails()}
      <div className="content__caddy">{renderManufacturer()}</div>
    </>
  );
};

export default EntityDetails;
