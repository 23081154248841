import React, { useState, useEffect, useRef } from "react";
import _, { filter } from "lodash";
import XLSX from "xlsx";
import DataOutput from "./DataOutput";
import FieldMappings from "./FieldMappings";
import Notice from "../../../Notice";
import TabBar from "../../../TabBar";
import DataInput from "./DataInput";
import { DragDropContext } from "react-beautiful-dnd";
import { fetchWrapper } from "../../SubmissionHttp";
import { selectMapping, formatData, filesWithValidationErrors } from "../../processors/DataProcessing";
import FileProcessor from "../../processors/file_processor";
import dataTableProcessor from "../../processors/dataTableProcessor";
import { TableData } from "../../processors/TableData";
import ColumnMappingNotice from "./ColumnMappingNotice";

import useVault from "../../hooks/useVault";

const tabs = ["Data", "Column Mappings"];

const DataSubmission = (props) => {
  const attachment = useRef();
  const fileInputRef = useRef();
  const [submissionData, setSubmissionData] = useState();
  // const [selectedSubmissionId, setSelectedSubmissionId] = useState(
  //   submissionData.length ? submissionData[0].data : null
  // );
  const [scrollTo, setScrollTo] = useState(() => {});
  const [displayTabBarTags, setDisplayTabBarTags] = useState(false);
  const [notice, setNotice] = useState({
    kind: "error",
    open: false,
    message: "",
  });
  const [activeTab, setActiveTab] = useState(["Data"]);
  const [selectedMappingId, setSelectedMappingId] = useState(null);
  const [fieldMappingGroups, setFieldMappingGroups] = useState(props.fieldMappings);
  const [editState, setEditState] = useState({
    id: null,
    position: null,
    name: "",
    mappings: props.columnDetails.reduce((mapping, field) => {
      mapping[field.name] = null;
      return mapping;
    }, {}),
  });
  const [isDeleting, setIsDeleting] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const usedFields = Object.values(editState.mappings);

  const salt = useVault(props.organization, setNotice);

  const filterList = JSON.parse(props.filterList);

  useEffect(() => {
    if (fieldMappingGroups.length === 0) {
      setDisplayTabBarTags(true);
    } else {
      setDisplayTabBarTags(false);
    }

    if (attachment.current) {
      handleFile(attachment.current);
    }
  }, [fieldMappingGroups]);

  // Reads file, adds object to state, and updates position of state.selected file to the new file
  const handleFile = async (file, position) => {
    // attachments.current.push(file);
    attachment.current = file;

    const result = await FileProcessor({
      id: position,
      file: file,
      kind: props.type,
      filterList: filterList,
      filterKey: props.filterKey,
      columnMappings: fieldMappingGroups,
      salt: salt,
      selectedMapping: getSelectedMapping(selectedMappingId),
    });

    setSelectedMappingId(result.mapping.id);

    // const submissionDataCopy = _.cloneDeep(submissionData);

    setSubmissionData(result);
  };

  const getSelectedMapping = (id) => {
    return _.find(fieldMappingGroups, ["id", id]);
  };

  // Reads file, adds object to state, and updates position of state.selected file to the new file
  const handleMappingSelected = async (mappingId) => {
    // attachments.current.push(file);
    if (!attachment.current) {
      setSelectedMappingId(mappingId);
      return;
    }

    const result = await FileProcessor({
      id: 0,
      file: attachment.current,
      data: submissionData.data,
      kind: props.type,
      filterList: filterList,
      filterKey: props.filterKey,
      columnMappings: fieldMappingGroups,
      salt: salt,
      selectedMapping: getSelectedMapping(mappingId),
    });

    setSelectedMappingId(result.mapping.id);

    setSubmissionData(result);
  };

  const createFieldMapping = async (mapping) => {
    setIsSaving(true);
    try {
      await fetchWrapper
        .createFieldMapping(props.organization, props.type, mapping)
        .then((res) => setFieldMappingGroups(res.mappings))
        .catch((error) =>
          setNotice({
            kind: "error",
            open: true,
            message: "Oh no - it looks like something went wrong",
          })
        );
    } catch (error) {
      console.error({ error });
    } finally {
      setIsSaving(false);
    }
  };

  const updateFieldMapping = async (mapping) => {
    setIsSaving(true);
    try {
      await fetchWrapper
        .updateFieldMapping(props.organization, props.type, mapping)
        .then((res) => setFieldMappingGroups(res.mappings))
        .catch((error) =>
          setNotice({
            kind: "error",
            open: true,
            message: "Oh no - it looks like something went wrong",
          })
        );
    } catch (error) {
      console.error({ error });
    } finally {
      setIsSaving(false);
    }
  };

  const deleteFieldMappingGroup = async (id) => {
    setIsDeleting(true);

    try {
      await fetchWrapper
        .deleteFieldMappings(props.organization, props.type, id)
        .then((res) => setFieldMappingGroups(res.mappings))
        .catch((error) =>
          setNotice({
            kind: "error",
            open: true,
            message: "Oh no - it looks like something went wrong",
          })
        );
    } catch (error) {
      console.error({ error });
    } finally {
      setIsDeleting(false);
    }
  };

  const removeFieldFromMapping = (mappingID, field) => {
    var mappingGroups = _.cloneDeep(fieldMappingGroups);
    var index = _.findIndex(mappingGroups, ["id", mappingID]);
    mappingGroups[index].mappings[field] = null;
    setFieldMappingGroups(mappingGroups);
  };

  const reset = () => {
    setSubmissionData(null);
  };

  const enableTab = (tab) => {
    setActiveTab(tab);

    document.querySelector(".tabs__view--active").classList.remove("tabs__view--active");
    document.querySelector(`[data-tab-name="${tab}"]`).classList.add("tabs__view--active");
  };

  const removeSelectedFile = (id) => {
    setSubmissionData(_.filter(submissionData, (submission) => submission.id !== id));
  };

  /* Handles when the card representation of an attachment is clicked.
  Updates position and selectedMapping to the mapping of the newly selected attachment
  */
  const handleSelectedFile = (position) => {
    var clonedAttachments = _.cloneDeep(state.attachments);
    var attachment = _.find(clonedAttachments, (attached) => attached.position == position);
    var mappingObj = _.find(fieldMappingGroups, ["name", attachment.mappingName]);

    setState({
      selectedFile: position,
      selectedMapping: mappingObj.mappings,
      attachments: [...state.attachments],
    });
  };

  const getSelectedFileData = (position) => {
    const attachment = _.find(attachments, (attachment) => attachment.position == position);

    return attachment.data;
  };

  const getSelectedFileDataColumnNames = () => {
    const data = getSelectedFileData(state.selectedFile);
    return _.keys(data[0]);
  };

  const dataView = (tab) => {
    return (
      <div key={tab} className="tabs__view tabs__view--active" data-tab-name={tab}>
        <DataOutput
          salt={salt}
          organizationID={props.organization}
          data={submissionData}
          type={props.type}
          mappings={fieldMappingGroups}
          attachedCount={1}
          selectedMapping={getSelectedMapping(selectedMappingId)}
          setSelectedMapping={handleMappingSelected}
          displayTabBarTags={setTabBarTag}
          setScrollTo={setScrollTo}
          headers={props.headers}
        />
      </div>
    );
  };

  const renderFileNotice = () => {
    if (!submissionData) {
      return (
        <div className="notify__banner" style={{ marginBottom: 20 }}>
          <div className="notify__banner__icon">
            <i className="solid solid-budicon-notification"> </i>
          </div>
          <div className="notify__banner__notice__title">
            <strong>Attach a file - </strong>
          </div>
          <div className="notify__banner__notice">
            In order to update or create column mappings you must attach a data file.
          </div>
        </div>
      );
    }
  };

  const mappingView = (tab) => {
    return (
      <div key={tab} className="tabs__view" data-tab-name={tab}>
        {renderFileNotice()}
        <FieldMappings
          organization={props.organization}
          createFieldMapping={createFieldMapping}
          deleteFieldMappingGroup={deleteFieldMappingGroup}
          removeFieldFromMapping={removeFieldFromMapping}
          updateFieldMapping={updateFieldMapping}
          mappings={fieldMappingGroups}
          setMappings={setFieldMappingGroups}
          columnDetails={props.columnDetails}
          editState={editState}
          setEditState={setEditState}
          isDeleting={isDeleting}
          isSaving={isSaving}
        />
      </div>
    );
  };

  const renderTabViews = () => {
    return tabs.map((tab) => {
      switch (tab) {
        case "Data":
          return dataView(tab);
        case "Column Mappings":
          return mappingView(tab);
      }
    });
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    var dropZoneDetails = destination.droppableId.split("#");

    if (dropZoneDetails[0] === "existing") {
      setEditState({
        ...editState,
        mappings: {
          ...editState.mappings,
          [dropZoneDetails[1]]: result.draggableId,
        },
      });
      // setUsedFields([...usedFields, result.draggableId]);
    }
  };

  const setTabBarTag = (display) => {
    setDisplayTabBarTags(display);
  };

  const handleRemoveFile = () => {
    setSubmissionData();
    fileInputRef.current.value = "";
    attachment.current = null;
  };

  return (
    <div style={{ display: "flex" }}>
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="content__container">
          <div className="page-details__container">
            <div className="page-details__container__info">
              <div className="pill pill--info pill--info--blue mb-2">{`${
                props.type == "encounter" ? "Pharmacy" : props.type
              } ${props.rebate ? "Eligibility Data" : "Claims"}`}</div>
              <div className="page-details__title">{`Submit 340B ${
                props.type == "encounter" ? "Pharmacy" : _.startCase(_.toLower(props.type))
              } ${props.rebate ? "Eligibility" : "Claims"} Data`}</div>
              <div className="breadcrumbs__container">
                <div className="breadcrumbs__crumb">{`${props.rebate ? "Eligibility" : "Claims"} Data >`}</div>
                <div className="breadcrumbs__crumb breadcrumbs__crumb__active">
                  New {props.type == "encounter" ? "Pharmacy Eligibility" : props.type} Submission
                </div>
              </div>
            </div>
          </div>
          <Notice details={notice} />
          <ColumnMappingNotice isVisible={!fieldMappingGroups.length} />
          <TabBar
            tabs={tabs}
            activeTab={activeTab}
            setActiveTab={enableTab}
            displayTagsFor={"Column Mappings"}
            displayTabBarTags={displayTabBarTags}
          />
          {renderTabViews()}
        </div>
        <div className="draw__container">
          <DataInput
            organization={props.organization}
            filterList={filterList}
            reset={reset}
            type={props.type}
            rebate={props.rebate}
            submissionPath={props.submissionPath}
            usedFields={usedFields}
            // fileHeaders={
            //   attachments.length && getSelectedFileData(selectedFile)[0]
            //     ? Object.keys(getSelectedFileData(selectedFile)[0])
            //     : null
            // }
            fieldMappingGroups={fieldMappingGroups}
            requiredShape={props.requiredShape}
            submissionData={submissionData}
            // removeSubmissionError={removeSubmissionError}
            removeSelectedFile={removeSelectedFile}
            handleFile={handleFile}
            handleRemoveFile={handleRemoveFile}
            ref={fileInputRef}
            // selectedSubmissionId={selectedSubmissionId}
            // setSelectedSubmissionId={setSelectedSubmissionId}
            mappingTabSelected={activeTab == "Column Mappings"}
            handleSelectedFile={handleSelectedFile}
            headers={props.headers}
            selectedMapping={getSelectedMapping(selectedMappingId)}
            handleMappingSelected={handleMappingSelected}
            mappings={fieldMappingGroups}
          />
        </div>
      </DragDropContext>
    </div>
  );
};

export default DataSubmission;
