import React, { useState, useRef, useEffect } from "react";
import _ from "lodash";
import Bms from "~/images/BMS.png";

const AgreementBms = (props) => {
  const renderAgreement = () => {
    if (props.selectedManufacturer == "bristol_myers_squibb") {
      return (
        <div>
          <div className="logo__container">
            <div className="logo">
              <img src={Bms} />
            </div>
            <div className="logo__info">
              <div className="logo__info__title">Bristol Myers Squibb</div>
              <div className="logo__info__subtitle">Wholly Owned Contract Pharmacy Exemption Form</div>
            </div>
          </div>
          <p>
            Beginning March 1, 2022, BMS will recognize up to two designated 340B contract pharmacy locations per 340B
            hospital that lacks an entity-owned pharmacy. One for IMiDs, and a second for non-IMiD products. 340B
            grantees may continue to utilize multiple 340B contract pharmacy locations for non-IMiD products, without
            restriction, and may utilize a single contract pharmacy location for the IMiDs. BMS will continue to offer
            340B prices on drugs sold to covered entities and delivered to their entity-owned pharmacies, in compliance
            with applicable law.
          </p>
          <p>
            340B hospitals will be eligible to designate all wholly owned entities as contract pharmacy locations. BMS
            assumes that contract pharmacies are not owned by Covered Entities, absent evidence to the contrary. Such
            wholly owned pharmacies must be registered with HRSA as a contract pharmacy of the 340B hospital. If a
            covered entity or health system is granted an exemption for its wholly owned contract pharmacy(s), it may
            not also designate a contract pharmacy that it doesn't own.
          </p>
          <p>
            To apply for this exemption covered entities must complete this form and submit proof of ownership
            documents. Examples of proof of ownership documents include:
          </p>
          <ul>
            <li>Certificate of liability insurance</li>
            <li>State business licenses</li>
          </ul>
          <p>
            BMS will process wholly owned contract pharmacy application requests within five business days of receipt.
            Exemptions will be valid for twelve (12) months from approval date and after the initial 12-month period,
            BMS may require covered entities to resubmit proof of ownership documents to validate the continued
            eligibility of existing exemptions.
          </p>
          <p>
            The covered entity acknowledges that the covered entity and contract pharmacy identified have the same
            corporate ownership, or that the covered entity wholly owns the identified contract pharmacy and qualifies
            for the exemption as described in this selection form. If there are any changes to the ownership structure
            of any of the identified contract pharmacies, the covered entity agrees to notify BMS within five (5)
            business days.
          </p>
          <div className="register__divider" />
        </div>
      );
    } else {
      return null;
    }
  };

  return renderAgreement();
};

export default AgreementBms;
