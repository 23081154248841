import _ from "lodash";

export const TableData = (attachment, viewOriginal) => {
  const getHeaders = (attachment) => {
    if (!attachment) {
      return [];
    }

    if (viewOriginal) {
      return attachment.originalColumnNames;
    }

    return attachment.columnNames;
  };

  const getData = (attachment) => {
    if (!attachment) {
      return [];
    }

    if (viewOriginal) {
      return attachment.originalData;
    }

    return attachment.formattedData;
  };

  const getColumnMappingWarnings = (attachment) => {
    if (!attachment) {
      return {};
    }

    return attachment.column_mapping_warnings;
  };

  const getMapping = (attachment) => {
    if (!attachment) {
      return {};
    }

    return attachment.mapping;
  };

  return {
    headers: getHeaders(attachment),
    data: getData(attachment),
    columnMappingWarnings: getColumnMappingWarnings(attachment),
    mapping: getMapping(attachment),
  };
};
