import _, { map } from "lodash";
import { SHA3 } from "sha3";
import { columnSchema } from "../schemas/pharmacy_claim";
import Validations from "./Validations";
import { validationDescriptions } from "../notices/validationDescriptions";

/* 
1. Filters our columns that are not read by column mappping
2. Adds additional keys to each row for custom data
*/
export const filterData = (data, ndcList, mapping) => {
  // Filters for rows with valid NDCs
  var filtered = _.filter(data, (row) => _.find(ndcList, ["code", row[mapping["ndc"]]]));

  return filtered.map((row) => {
    var selectedData = _.pick(row, _.values(mapping));

    // include additional keys for custom data
    selectedData["claim_conforms_flag"] = null;
    selectedData["formatted_rx_number"] = null;

    return selectedData;
  });
};

export const generateSecureHash = (value, salt) => {
  // salt is 32 bit hexadecimal - 2 char per byte(64)

  // return early if no value, salt or incorrect salt character length
  if (!value || !salt || salt.length != 64) return;

  var hash = new SHA3(256).update(value + salt);

  return hash.digest("hex");
};

export const formatDate = (value) => {
  if (isNaN(Date.parse(value))) return;

  // JS dates are off by 1 day when yyyy-mm-dd
  // convert date format to yyyy/mm/dd to ensure correct day date
  const regex = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;
  if (regex.test(value)) {
    value = _.replace(value, /-/g, "/");
  }

  // use US date format mm-dd-yyyy
  var dateString = new Date(value).toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" });

  // split formatted date
  var dateParts = dateString.split("/");

  if (dateParts.length === 3) {
    // convert to ISOString manually which will exclude timezone
    // ensure formatted date returned is yyyy-mm-dd
    return `${dateParts[2]}-${dateParts[0]}-${dateParts[1]}`;
  } else {
    // invalid date format
    return false;
  }
};

export const normalizeRxNumber = (value, filesWithErrors, filePosition) => {
  if (!value) {
    // TODO: This is updating state without using the setState hook. Needs refactor or intentional?
    filesWithErrors.push(filePosition);

    return false;
  }

  // remove fill count from rx_number - eg. 12345678-01
  var rx_number = value.split("-")[0];

  if (Validations.isValidRXNumber(rx_number)) {
    return removeLeadingZeros(rx_number);
  } else {
    // TODO: This is updating state without using the setState hook. Needs refactor or intentional?
    filesWithErrors.push(filePosition);

    return false;
  }
};

export const removeLeadingZeros = (value) => {
  return value.replace(/^0+/, "");
};

export const isDateOfServiceWithinLastFortySixDays = (date) => {
  if (isNaN(Date.parse(formatDate(date)))) return;

  // The number of milliseconds in one day
  const timeInDay = 1000 * 60 * 60 * 24;

  const dateToday = formatDate(Date());
  const daysBetween = (Date.parse(dateToday) - Date.parse(formatDate(date))) / timeInDay;

  // daysBetween must be positive number
  if (daysBetween >= 0 && daysBetween <= 45) {
    return "true";
  } else {
    return "false";
  }
};

/* 
1. Assigns boolean value to 'claims_conforms_flag' if date of service is valid date and within 46 days
2. Hashes/de-identifies 'date_of_service', 'date_prescribed', 'rx_number', 'formatted_rx_number'
 */
export const formatValue = (schemaColumnName, value, salt, row, mapping, ndcList, filesWithErrors, filePosition) => {
  if (schemaColumnName == "claim_conforms_flag") {
    var conforms_flag = isDateOfServiceWithinLastFortySixDays(formatDate(row["date_of_service"]));

    return conforms_flag;
  }

  if (["date_of_service", "date_prescribed"].includes(schemaColumnName)) {
    return generateSecureHash(formatDate(value), salt);
  }

  if (["rx_number"].includes(schemaColumnName)) {
    return generateSecureHash(value, salt);
  }

  if (["formatted_rx_number"].includes(schemaColumnName)) {
    return generateSecureHash(normalizeRxNumber(row["rx_number"], filesWithErrors, filePosition), salt);
  }

  return value;
};

/* 
Refactors formatValue to not update filesWithError and exclusively handle value formatting
 */
export const formatValueOnly = (
  schemaColumnName,
  value,
  salt,
  row,
  mapping,
  ndcList,
  filesWithErrors,
  filePosition
) => {
  if (schemaColumnName == "claim_conforms_flag") {
    var conforms_flag = isDateOfServiceWithinLastFortySixDays(formatDate(row[mapping["date_of_service"]]));

    return conforms_flag;
  }

  if (["date_of_service", "date_prescribed"].includes(schemaColumnName)) {
    return generateSecureHash(formatDate(value), salt);
  }

  if (["rx_number"].includes(schemaColumnName)) {
    return generateSecureHash(value, salt);
  }

  if (["formatted_rx_number"].includes(schemaColumnName)) {
    return generateSecureHash(normalizeRxNumber(row[mapping["rx_number"]], filesWithErrors, filePosition), salt);
  }

  return value;
};

// Returns the mapping whose column values most frequently match the attached file's column values
export const selectMapping = (mappings, data) => {
  const fileHeaders = _.keys(data[0]);
  var fieldMappingScores = [];

  _.forEach(mappings, (fieldMapping, index) => {
    var score = 0;

    _.forEach(fieldMapping.mappings, (value, key) => {
      fileHeaders.includes(value) ? (score += 1) : null;
    });

    fieldMappingScores[index] = { id: fieldMapping.id, score: score };
  });

  var scores = _.map(fieldMappingScores, "score");

  if (!scores.length) {
    return [];
  }

  var mappingDetails = _.find(fieldMappingScores, (item) => item.score == Math.max(...scores));

  return _.find(mappings, ["id", mappingDetails.id]);
};

export const getMapping = (mappings, name) => {
  return _.find(mappings, ["name", name]);
};

/*
Summary: Filters out unneccessary columns and formats values for injestion.

1. FilterData
  - Filters out unused columns
  - Adds additional keys ('claims_conforms_flag', 'formatted_rx_number') for custom row data
2. FormatValue
  - Assigns boolean value to 'claims_conforms_flag' if date of service is valid date and within 46 days
  - Hashes/de-identifies 'date_of_service', 'date_prescribed', 'rx_number', 'formatted_rx_number'
 */
export const formatData = (columnMapping, data, ndcList, salt, filesWithErrors, filePosition) => {
  var dataTrimmed = [];

  var filteredData = filterData(data, ndcList, columnMapping);

  _.map(filteredData, (row) => {
    var schemaClone = _.cloneDeep(columnSchema);

    _.map(schemaClone, (value, key) => {
      schemaClone[key] = formatValue(
        key,
        row[columnMapping[key]],
        salt,
        row,
        columnMapping,
        ndcList,
        filesWithErrors,
        filePosition
      );
    });

    dataTrimmed.push(schemaClone);
  });

  return dataTrimmed;
};

// Same thing as formatData but takes in a single row instead of an attachment array
export const formatRow = (columnMapping, data, ndcList, salt, filesWithErrors, filePosition) => {
  var dataTrimmed = [];

  var filteredData = filterData(data, ndcList, columnMapping);

  _.map(filteredData, (row) => {
    var schemaClone = _.cloneDeep(columnSchema);

    _.map(schemaClone, (value, key) => {
      schemaClone[key] = formatValue(
        key,
        row[columnMapping[key]],
        salt,
        row,
        columnMapping,
        ndcList,
        filesWithErrors,
        filePosition
      );
    });

    dataTrimmed.push(schemaClone);
  });

  return dataTrimmed;
};

// Takes in row and validates cell values
const validationsNeeded = (schemaColumn, value, row, mapping) => {
  // if (["claim_conforms_flag", "formatted_rx_number"].includes(requiredColumn)) { return true }
  var isValid = false;
  switch (schemaColumn) {
    case "date_of_service":
      isValid =
        Validations.isValidDateOfService(value) &&
        !Validations.isQuestionableDateOfService(value, row[mapping["date_prescribed"]]);
      break;
    case "date_prescribed":
      isValid =
        Validations.isValidDatePrescribed(value) &&
        !Validations.isQuestionableDatePrescribed(value, row[mapping["date_of_service"]]);
      break;
    case "rx_number":
      isValid = Validations.isValidRXNumber(value);
      break;
    case "ndc":
      isValid = Validations.isValidNDC(value);
      break;
    case "quantity":
      isValid = Validations.isValidQuantity(value) && !Validations.isQuestionableQuantity(value);
      break;
    case "wholesaler_invoice_number":
      isValid = Validations.isValidWholesalerInvoiceNumber(value);
      break;
    case "prescriber_id_qualifier":
      isValid = Validations.isValidPrescriberIDQualifier(value);
      break;
    case "prescriber_id":
      isValid = Validations.isValidPrescriberID(row[mapping["prescriber_id_qualifier"]], value);
      break;
    case "service_provider_id_qualifier":
      isValid = Validations.isValidServiceProviderIDQualifier(value);
      break;
    case "service_provider_id":
      isValid = Validations.isValidServiceProviderID(row[mapping["service_provider_id_qualifier"]], value);
      break;
    case "contracted_entity_id":
      isValid = Validations.isValidContractedEntityID(value);
      break;
    default:
      isValid = false;
  }

  return isValid;
};

export const filesWithValidationErrors = (attachments, fieldMappings, ndcList) => {
  var filesWithErrors = [];

  // loop through all attachments to validate data
  _.forEach(attachments, (attachment) => {
    var mappingObj = _.find(fieldMappings, ["name", attachment.mappingName]);
    var filteredData = filterData(attachment.data, ndcList, mappingObj.mappings);

    if (filteredData.length == 0) {
      filesWithErrors.push(attachment.position);

      // return early if no filtered data is found
      return;
    }

    _.forEach(filteredData, (row) => {
      // move to next data attachment if attachment has errors
      if (filesWithErrors.includes(attachment.position)) {
        return;
      }

      _.forEach(mappingObj.mappings, (value, key) => {
        if (validationsNeeded(key, row[value], row, mappingObj.mappings) == false) {
          filesWithErrors.push(attachment.position);

          // return early if any data value is invalid
          return false;
        }
      });
    });

    // verify data to submit is hashed
    _.forEach(attachment.processedData, (row) => {
      // move to next data attachment if attachment has errors
      if (filesWithErrors.includes(attachment.position)) {
        return;
      }

      _.forEach(row, (value, key) => {
        switch (key) {
          case "date_of_service":
            if (filesWithErrors.includes(attachment.position)) {
              return;
            }

            if (!value || value.length != 64) {
              filesWithErrors.push(attachment.position);
            }
            break;
          case "date_prescribed":
            if (filesWithErrors.includes(attachment.position)) {
              return;
            }

            if (!value || value.length != 64) {
              filesWithErrors.push(attachment.position);
            }
            break;
          case "rx_number":
            if (filesWithErrors.includes(attachment.position)) {
              return;
            }

            if (!value || value.length != 64) {
              filesWithErrors.push(attachment.position);
            }
            break;
          case "formatted_rx_number":
            if (filesWithErrors.includes(attachment.position)) {
              return;
            }

            if (!value || value.length != 64) {
              filesWithErrors.push(attachment.position);
            }
            break;
        }
      });
    });
  });

  return filesWithErrors;
};

export const validateCells = (data, columnMapping) => {
  const dataCopy = _.cloneDeep(data);

  // TODO: Handle validation notices here?
  const processedData = dataCopy.map((row, index) => {
    const processedRow = {
      columnValues: row,
      columnValidation: {},
      valid: true,
    };

    _.forOwn(columnMapping, (customColumn, schemaColumn) => {
      processedRow.columnValidation[schemaColumn] = true;

      if (isValidCell(schemaColumn, row[customColumn], row, columnMapping) === false) {
        processedRow.columnValidation[schemaColumn] = false;
        processedRow.valid = false;
      }
    });

    return processedRow;
  });

  return processedData;
};

// export const frontEndValidationEngine = (data, columnMapping, ndcList, salt, filesWithErrors, position) => {
//   /*
//   1. Filter (`filterData()`) and format (`formatValue()`) data so that we have formatted
//   rows for DataOutput and unformatted rows for DataOutputOriginal, both held in state.
//   2. Validate formatted values and store whether individual cells are valid/invalid. This will
//   need to be used when rendering validation descriptions in DataOutput.
//   3. We determine whether a file has errors by first determining if there are any rows left
//   after filtering/formatting. Then if there are, we run validation check on those rows (`validationsNeeded`).
//   If a single cell is invalid, the entire file is invalid and we push its attachment position into the filesWithErrors array.
//    */

//   // Do all the processing within one iteration?

//   /* 1. Filter (`filterData()`) and format (`formatValue()`) data so that we have formatted
//   rows for DataOutput and unformatted rows for DataOutputOriginal, both held in state. */

//   // validation needs to happen before values are hashed
//   const validatedData = validateCells(data, columnMapping);

//   const formattedData = formatData(columnMapping, data, ndcList, salt, filesWithErrors, position);

//   // const validatedRows = validateCells(data, columnMapping);
//   // return {
//   //   filesWithErrors,
//   // };
//   /*
//   We want to return:
//   1. filesWithErrors (array of file position integers)
//   2. filtered/formatted data for DataOutput
//   3. Unformatted data for DataOutputOriginal
//   4. Information to determine what validation descriptions to render in the DataOutput view
//    */
// };
