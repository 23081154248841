import React from "react";
import Slider from "react-slick";

const CallOut = (props) => {
  const settings = {
    accessibility: false,
    draggable: false,
    swipe: false,
    arrows: false,
    dots: false,
    infinite: true,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 5000,
    autoplay: true,
  };

  return (
    <div className="animated delay slow-1 fadeInDown">
      <Slider {...settings}>
        <div>
          <div className="callout">
            <div className="callout__indicator" />
            <div className="callout__message">AstraZeneca has provided notice of an update to its policy.</div>
            <div className="callout__action">
              <a href="/resources">
                <div className="btn btn--tiny btn--white color-blue">Learn More</div>
              </a>
            </div>
          </div>
        </div>
        <div>
          <div className="callout">
            <div className="callout__indicator" />
            <div className="callout__message">Astellas has provided notice of its policy.</div>
            <div className="callout__action">
              <a href="/resources">
                <div className="btn btn--tiny btn--white color-blue">Learn More</div>
              </a>
            </div>
          </div>
        </div>
        <div>
          <div className="callout">
            <div className="callout__indicator" />
            <div className="callout__message">
              Boehringer Ingelheim (BI) has provided notice of an update to its policy.
            </div>
            <div className="callout__action">
              <a href="/resources">
                <div className="btn btn--tiny btn--white color-blue">Learn More</div>
              </a>
            </div>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default CallOut;
