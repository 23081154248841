import React, { forwardRef, useState, useEffect } from "react";
import _, { last } from "lodash";
import Post from "./Post";

import AttachedFile from "./attached_file";

const SheetJSFT = [".xlsx", ".xls", ".csv"];

const DataInput = forwardRef((props, inputRef) => {
  // const [files, setFiles] = useState({ attached: [], selected: 0 });

  const getFirstPosition = (attachments) => {
    const firstPos = _.minBy(attachments, "position");
    return firstPos.position;
  };

  const getNextPosition = () => {
    const lastPos = _.maxBy(props.submissionData, "position");
    return lastPos ? lastPos.position + 1 : 0;
  };

  // Handles attaching new file
  const handleChange = (e) => {
    const attachments = e.target.files;
    const position = getNextPosition();
    props.handleFile(attachments[0], position);

    // setFiles({ attached: [...files.attached, { position: position, file: attachments[0] }], selected: position });
  };

  // const handleSelectedFile = (position) => {
  //   props.handleSelectedFile(position);

  //   setFiles({ attached: [...files.attached], selected: position });
  // };

  // should rename position everywhere to ID - ID alias for position
  const getAttachmentByID = (id) => {
    return _.find(props.attachments, (data) => data.position == id);
  };

  const getMappingDetails = (id) => {
    var attachment = getAttachmentByID(id);

    if (attachment) {
      return attachment.mappingName;
    }
  };

  const renderAttachments = () => {
    if (props.submissionData) {
      return (
        <AttachedFile
          usedFields={props.usedFields}
          pos={0}
          attachment={props.submissionData}
          handleRemoveFile={props.handleRemoveFile}
          selected
          mappingTabSelected={props.mappingTabSelected}
          setSelectedMapping={props.handleMappingSelected}
          mappings={props.mappings}
          selectedMapping={props.selectedMapping}
        />
      );
    }
  };

  const isValidSubmission = () => {
    if (props.submissionData) {
      return !props.submissionData.hasErrors;
    }
  };

  return (
    <div>
      <div className="draw__item__container">
        <Post
          type={props.type}
          rebate={props.rebate}
          organization={props.organization}
          submissionData={props.submissionData}
          submissionPath={props.submissionPath}
          fieldMappingGroups={props.fieldMappingGroups}
          filesWithErrors={props.filesWithErrors}
          headers={props.headers}
        />
      </div>

      <div className="draw__section__header">
        <div className="draw__section__title" style={{ fontSize: 13 }}>
          Attach File(s) to Submit
        </div>
        <div className="draw__section__caddy">
          <div
            className={props.submissionData ? "btn btn--small" : "btn btn--small btn--pulse--loud"}
            onClick={() => {
              document.getElementById("submissionAttachments").click();
            }}
          >
            Attach
          </div>
          <input
            type="file"
            style={{ display: "none" }}
            className="form-control"
            id="submissionAttachments"
            accept={SheetJSFT}
            // multiple={true}
            multiple={false}
            ref={inputRef}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="draw__section__container">{renderAttachments()}</div>
    </div>
  );
});

export default DataInput;
