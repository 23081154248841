import React, { useState, useRef, useEffect } from "react";
import _ from "lodash";
import Gilead from "~/images/Gilead.png";

const AgreementGilead = (props) => {
  const renderAgreement = () => {
    if (props.selectedManufacturer == "gilead") {
      return (
        <div>
          <div className="logo__container">
            <div className="logo">
              <img src={Gilead} />
            </div>
            <div className="logo__info">
              <div className="logo__info__title">Gilead</div>
              <div className="logo__info__subtitle">Wholly Owned Contract Pharmacy Exemption Form</div>
            </div>
          </div>
          <p>
            Under Gilead Sciences 340B contract pharmacy integrity initiative, contract pharmacies that are wholly owned
            by a 340B covered entity or have common ownership with the covered entity, will remain eligible to receive
            Bill To / Ship To orders of 340B priced drugs irrespective of whether the covered entity provides claims
            level data. These pharmacies must be registered within the HRSA database as a contract pharmacy of the 340B
            covered entity. If a covered entity designates its wholly owned contract pharmacy(ies), it may not also
            designate a third party contract pharmacy unless claims data is provided for such contract pharmacy.
          </p>
          <p>
            Covered entities must complete the wholly owned contract pharmacy form on the 340B ESP website to designated
            wholly owned contract pharmacies. Proof of ownership documents are required to complete the process.
            Examples of proof of ownership documents include:
          </p>
          <ul>
            <li>Certificate of liability insurance</li>
            <li>State business licenses</li>
          </ul>
          <p>
            Wholly Owned Contract Pharmacy Designations will be processed within five (5) business days. Please note
            that failure to populate all required fields will delay the processing of Wholly Owned Contract Pharmacy
            designations. Designations will be valid for twelve (12) months from approval date and after the initial
            12-month period, Gilead Sciences may require covered entities to resubmit proof of ownership documents to
            validate the continued eligibility of existing designations.
          </p>
          <p>
            By signing this form the authorized representative of the covered entity certifies that the covered entity
            and contract pharmacy(ies) included in this form: (i) have the same corporate ownership, OR (ii) that the
            covered entity wholly owns the identified contract pharmacy(ies); AND (iii) the covered entity qualifies for
            the designation as described in this form. If there are any changes to the ownership structure of any of the
            contract pharmacies identified in this form, the covered entity agrees to notify Gilead Sciences at
            support@340besp.com within five (5) business days of such change of ownership.
          </p>
          <div className="register__divider" />
        </div>
      );
    } else {
      return null;
    }
  };

  return renderAgreement();
};

export default AgreementGilead;
