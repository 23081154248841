import _ from "lodash";

export const columnSchema = {
  date_of_service: null,
  date_prescribed: null,
  rx_number: null,
  ndc: null,
  quantity: null,
  prescriber_id: null,
  prescriber_id_qualifier: null,
  service_provider_id: null,
  service_provider_id_qualifier: null,
  wholesaler_invoice_number: null,
  contracted_entity_id: null,
  billing_provider_id: null,
  billing_provider_id_qualifier: null,
  date_of_encounter: null,
  physician_id: null,
  physician_id_qualifier: null,
  hcpcs_code: null,
  hcpcs_code_modifier_1: null,
  hcpcs_code_modifier_2: null,
  diagnosis_code: null,
  place_of_service_code: null,
  fill_number: null,
};

export const columnDetails = [
  {
    name: "date_of_service",
    title: "DOS",
    description: "Date the pharmacy filled the prescription.",
    required: true,
    order: 2,
  },
  {
    name: "date_prescribed",
    title: "Date Prescribed",
    description: "Date the prescriber wrote the prescription.",
    required: true,
    order: 3,
  },
  {
    name: "rx_number",
    title: "Rx Number",
    description: "The native (unmodified) prescription number for the prescription as generated by the pharmacy.",
    required: true,
    order: 1,
  },
  {
    name: "ndc",
    title: "NDC-11",
    description:
      "The 11-digit National Drug Code which indicates the manufacturer, product, and the commercial package size.",
    required: true,
    order: 7,
  },
  {
    name: "quantity",
    title: "Quantity",
    description: "The number of units in the prescription.",
    required: true,
    order: 8,
  },
  {
    name: "service_provider_id",
    title: "Pharmacy ID",
    description:
      "A unique, public ID for the dispensing pharmacy. Accepted IDs include the NPI, DEA, NCPDP, and Medicaid ID.",
    required: true,
    order: 5,
  },
  {
    name: "service_provider_id_qualifier",
    title: "Pharmacy ID Qualifier",
    description:
      "Indicates the type of unique ID provider. “01” for NPI, “05” for Medicaid”, “07” for NCPDP, and “12” for DEA.",
    required: false,
    order: 6,
  },
  {
    name: "contracted_entity_id",
    title: "340B ID",
    description:
      "The 340B ID of the covered entity where the prescription originated. This is usually the 340B ID of the covered entity under which you registered.",
    required: true,
    order: 19,
  },
  {
    name: "prescriber_id",
    title: "Prescriber ID",
    description: "A unique, public ID for the prescribing physician. Accepted IDs include the NPI and DEA ID.",
    required: false,
    order: 9,
  },
  {
    name: "prescriber_id_qualifier",
    title: "Prescriber ID Qualifier",
    description: "Indicates the type of unique ID provided. A value of “01” indicates NPI, “12” indicates DEA.",
    required: false,
    order: 10,
  },
  // {
  //   name: "wholesaler_invoice_number",
  //   title: "Wholesaler Invoice Number",
  //   description:
  //     "The invoice number assigned by the wholesaler for the replenishment order made by the 340B covered entity.",
  //   required: false,
  //   order: 99,
  // },
  {
    name: "billing_provider_id",
    title: "Billing Provider ID",
    description: "A unique, public ID for the healthcare entity that billed for the patient's healthcare encounter.  NPI is required for this field.",
    required: true,
    order: 12,
  },
  // {
  //   name: "billing_provider_id_qualifier",
  //   title: "Billing Service Provider ID Qualifier",
  //   description: "",
  //   required: false,
  //   order: 99,
  // },
  {
    name: "date_of_encounter",
    title: "Encounter DOS",
    description: "Date on which the patient was seen by a healthcare provider and received a healthcare service.",
    required: false,
    order: 11,
  },
  {
    name: "physician_id",
    title: "Rendering Physician ID",
    description: "A unique, public ID for the physician that provided the healthcare service to the patient.  Accepted IDs include the NPI and DEA ID.",
    required: true,
    order: 17,
  },
  {
    name: "physician_id_qualifier",
    title: "Rendering Physician ID Qualifier",
    description: "Indicates the type of Rendering Physician ID provided. A value of “01” indicates NPI, “12” indicates DEA.",
    required: false,
    order: 18,
  },
  {
    name: "hcpcs_code",
    title: "HCPCS Code",
    description: "Five digit healthcare procedure code.  Examples include '99241' for outpatient consultation and '99291' for critical care services.",
    required: true,
    order: 14,
  },
  {
    name: "hcpcs_code_modifier_1",
    title: "HCPCS Code Modifier",
    description: "Two digit code that provides additional context for the HCPCS code assigned to the healthcare encounter.",
    required: false,
    order: 15,
  },
  // {
  //   name: "hcpcs_code_modifier_2",
  //   title: "HCPCS Code Modifier 2",
  //   description: "",
  //   required: false,
  //   order: 99,
  // },
  {
    name: "diagnosis_code",
    title: "Diagnosis Code",
    description: "ICD-10 diagnosis code assigned as the primary diagnosis for the healthcare encounter.",
    required: true,
    order: 16,
  },
  {
    name: "place_of_service_code",
    title: "Place of Service",
    description: "Two digit code indicating the facility type where the healthcare encounter occurred.  Examples include '22' for outpatient hospital, '23' for emergency department and '50' for FQHC.",
    required: true,
    order: 13,
  },
  {
    name: "fill_number",
    title: "Fill Number",
    description: "Indicates the number of times a prescription has been refilled.  A value of 2 would mean the current prescription claim is for the second refill of the prescription.",
    required: true,
    order: 4,
  },
];

export const tableHeaders = columnDetails.sort((a, b) => a.order - b.order).map((column) => column.name);
export const sortedColumnDetails = columnDetails.sort((a, b) => a.order - b.order);
export const required = columnDetails.filter((column) => column.required).map((column) => column.name);
export const notRequired = columnDetails.filter((column) => !column.required).map((column) => column.name);

export class PharmacyEncounterClaim {
  constructor() {
    this.hasErrors = false;

    this.data = {
      date_of_service: null,
      date_prescribed: null,
      rx_number: null,
      ndc: null,
      quantity: null,
      prescriber_id: null,
      prescriber_id_qualifier: null,
      service_provider_id: null,
      service_provider_id_qualifier: null,
      wholesaler_invoice_number: null,
      contracted_entity_id: null,
      billing_provider_id: null,
      billing_provider_id_qualifier: null,
      date_of_encounter: null,
      physician_id: null,
      physician_id_qualifier: null,
      hcpcs_code: null,
      hcpcs_code_modifier_1: null,
      hcpcs_code_modifier_2: null,
      diagnosis_code: null,
      place_of_service_code: null,
      fill_number: null,
      claim_conforms_flag: null,
      encounter_conforms: null,
      formatted_rx_number: null,
    };

    this.columns_with_errors = {
      date_of_service: false,
      date_prescribed: false,
      rx_number: false,
      ndc: false,
      quantity: false,
      prescriber_id: false,
      prescriber_id_qualifier: false,
      service_provider_id: false,
      service_provider_id_qualifier: false,
      wholesaler_invoice_number: false,
      contracted_entity_id: false,
      billing_provider_id: false,
      billing_provider_id_qualifier: false,
      date_of_encounter: false,
      physician_id: false,
      physician_id_qualifier: false,
      hcpcs_code: false,
      hcpcs_code_modifier_1: false,
      hcpcs_code_modifier_2: false,
      diagnosis_code: false,
      place_of_service_code: false,
      fill_number: false,
    };
  }
}
