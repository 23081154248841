import React, { useState, useEffect } from "react";
import ExelixisDesignations from "./ExelixisDesignations";
import illustration from "~/images/invite_or_find_illustration.png";

const ModalDesignationForm = (props) => {
  const [open, setOpen] = useState(false);
  const [completedDesignations, setCompletedDesignations] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = () => {
    setSubmitting(true);
  };

  const isDisabled = () => {
    if (!completedDesignations || submitting) {
      return true;
    }

    return false;
  };

  const renderModal = () => {
    if (open) {
      return (
        <div className="modal__background">
          <div className="modal__body modal__body--no-side-padding modal__body--large">
            <form method="POST" action={props.designations_path} onSubmit={handleSubmit}>
              <div className="modal__body__header-text-container">
                <div className="modal__body__title">Designate a single contract pharmacy</div>
                <div className="modal__body__subtitle" style={{ marginBottom: 30 }}>
                  Designate a single contract pharmacy for participating pharmaceutical manufacturers
                </div>
              </div>

              <div className="modal__body__form_container" style={{ paddingTop: 50, paddingBottom: 30 }}>
                <div className="designation_form__section__body">
                  <div className="designation_form__wrapper">
                    <img src={illustration} className={"abs"} />
                    <p>
                      <span className="bold">Note: </span>
                      You can only designate a single contract pharmacy for pharmaceutical manufacturers that
                      participate on the 340B ESP™ platform.
                      <br />
                      <br />
                      Please designate a single contract pharmacy based on the specific policies of each pharmaceutical
                      manufacturer. Some manufacturers may provide the option to designate multiple contract pharmacies
                      based on their policies and product portfolio. Designations may differ across pharmaceutical
                      manufacturers.
                    </p>
                    <ExelixisDesignations
                      entities={[props.entity_designation]}
                      setCompletedDesignations={setCompletedDesignations}
                    />
                  </div>
                </div>
                <input
                  type="hidden"
                  name="authenticity_token"
                  value={document.querySelector('meta[name="csrf-token"]').getAttribute("content")}
                />
                <input type="hidden" name="entity_id" value={props.entity_id} />
              </div>
              <div className="modal__body__footer">
                <div
                  className="btn btn--white btn--outline"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  Cancel
                </div>
                <button
                  type="submit"
                  className={isDisabled() ? "btn btn--disabled" : "btn"}
                  disabled={isDisabled()}
                  style={{ flexGrow: 1, marginLeft: 30 }}
                >
                  Save Designations
                </button>
              </div>
            </form>
          </div>
        </div>
      );
    }
  };

  return (
    <div>
      <div
        className="btn"
        onClick={(e) => {
          setOpen(!open);
        }}
      >
        <i className="solid solid-budicon-doc-attachment" />
        Make Designations
      </div>
      {renderModal()}
    </div>
  );
};

export default ModalDesignationForm;
