import React, { useState } from "react";
import CofirmationModal from "./library/confirmation_modal";

const ConfirmModal = (props) => {
  const modalBody = () => {
    return <div className="t--md t--gray pt-3 pb-3">{props.body}</div>;
  };

  const modalFooter = () => {
    return (
      <div className="flex justify-end" style={{ gap: 20 }}>
        <button
          className="btn btn--light-outline btn--small"
          style={{ lineHeight: "inherit" }}
          onClick={props.secondaryAction}
        >
          Cancel
        </button>
        <button className="btn btn--small" onClick={props.primaryAction}>
          {props.isDeleting ? (
            <div className="loading-indicator loading-indicator--no-margin"></div>
          ) : (
            "Yes, Delete Mapping"
          )}
        </button>
      </div>
    );
  };

  if (props.displayModal) {
    return (
      <CofirmationModal
        title={props.title}
        footer={modalFooter()}
        body={modalBody()}
        width="400px"
        showCloseIcon={true}
        setDisplayModal={props.setDisplayModal}
      />
    );
  }

  return null;
};

export default ConfirmModal;
