import React from "react";
import dataImage from "~/images/data_illustration.png";
import CustomToggle from "../CustomToggle";
import _ from "lodash";

const PharmacyDataForm = (props) => {
  const [masterToggle, setMasterToggle] = React.useState(true);
  const [pharmaciesChecked, setPharmaciesChecked] = React.useState(
    _.mapValues(_.keyBy(props.pharmacies, "id"), () => {
      return true;
    })
  );

  const handleToggleAll = (checked, ids) => {
    setMasterToggle(checked);
    setPharmaciesChecked(_.mapValues(pharmaciesChecked, () => checked));
  };

  const handleToggleState = (checked, id) => {
    setPharmaciesChecked({
      ...pharmaciesChecked,
      [id]: checked,
    });
  };

  const listPharmacies = () => {
    return props.pharmacies.map((pharmacy, i) => (
      <div key={pharmacy.id} className={"onboarding__content__form__scrollbody__item"}>
        <div className={"onboarding__content__form__scrollbody__item__info"}>
          <div className={"onboarding__content__form__scrollbody__item__info__title"}>{pharmacy.pharmacy_name}</div>

          <div className={"onboarding__content__form__scrollbody__item__info__subtitle"}>
            {pharmacy.pharmacy_address1}
          </div>
        </div>
        <div className={"onboarding__content__form__scrollbody__item__data_toggle"} style={{ width: "50%" }}>
          <CustomToggle
            name={"has_data"}
            setState={handleToggleState}
            checked={pharmaciesChecked[pharmacy.id]}
            submitPath={`/organization/${props.organizationID}/pharmacies/${pharmacy.id}`}
            pharmacyIDs={pharmacy.id}
          />
        </div>
      </div>
    ));
  };

  return (
    <div>
      <div className="onboarding__content__form">
        <div className="onboarding__content__form__image">
          <img src={dataImage} />
        </div>
        <div className="onboarding__content__form__title">Configure contract pharmacies</div>
        <p>Indicate the contract pharmacies for which you will provide 340B claims data.</p>
        <div className="onboarding__content__form__actionbar">
          <div className="onboarding__content__form__actionbar__info"></div>
          <div className="onboarding__content__form__actionbar__data_toggle" style={{ width: "50%" }}>
            Toggle all
            <CustomToggle
              setState={handleToggleAll}
              name={"has_data"}
              checked={masterToggle}
              submitPath={`/organization/${props.organizationID}/pharmacies/update_all`}
              pharmacyIDs={_.map(props.pharmacies, "id")}
            />
          </div>
        </div>

        <div className="onboarding__content__form__scrollbody">
          <div className={"onboarding__content__form__scrollbody__header"}>
            <div className={"onboarding__content__form__scrollbody__header__info"}>Basic Info</div>
            <div className={"onboarding__content__form__scrollbody__header__data_toggle"} style={{ width: "50%" }}>
              Providing Data
            </div>
          </div>
          {listPharmacies()}
        </div>

        <div className="onboarding__content__form__actions">
          <a href={props.path}>
            <div className="btn btn--outline btn--white">Skip and do this later</div>
          </a>
          <a href={props.path} style={{ display: "flex", flexGrow: 1 }}>
            <button className="btn" style={{ flexGrow: 1, marginLeft: 30 }} type="submit">
              Continue
            </button>
          </a>
        </div>
      </div>

      <div className="onboarding__content__footer">
        <div className="onboarding__content__footer__note">
          <a href="/logout">Cancel and return home</a>
        </div>
      </div>
    </div>
  );
};

export default PharmacyDataForm;
