import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import Select from "react-select";
import CeMap from "./CeMap";
import DesignationNotices from "./notices/Notices";
import { isGrantee } from "../../../components/utils/Helpers";
import { manufacturers } from "../../details";
import { customSelect } from "../../../components/utils/StyledSelect";

const PharmacySelect = (props) => {
  const [selectedPharmacy, setSelectedPharmacy] = useState();

  useEffect(() => {
    if (selectedPharmacy) {
      props.setHasSelectedPharmacy(true);
    } else {
      props.setHasSelectedPharmacy(false);
    }
  }, [selectedPharmacy]);

  const setDesignations = (selection) => {
    setSelectedPharmacy(selection);
  };

  const handleSelectedPharmacy = (entity, id) => {
    var pharmacy = _.find(entity.pharmacies.details, (cp) => cp.value == id);

    if (pharmacy) {
      setSelectedPharmacy(pharmacy);
    } else {
      setSelectedPharmacy();
    }
  };

  const customOption = (props) => {
    return (
      <div
        {...props.innerProps}
        style={{ margin: "5px 10px", paddingBottom: 5, borderBottom: "1px solid #F2F5F8", cursor: "pointer" }}
      >
        <div style={{ fontSize: 11, fontWeight: 500 }}>{props.data.label}</div>
        <div style={{ color: "#718291", fontSize: 11 }}>{props.data.address ? props.data.address : "--"}</div>
      </div>
    );
  };

  const renderSelectForm = (pharmacies) => {
    return (
      <div>
        <div className="stat-cards__form__fieldset__details">
          <div className="stat-cards__form__fieldset__details__title stat-cards__form__fieldset__details__title--mt">
            Designated pharmacy
          </div>
          <Select
            isClearable
            placeholder={"Search Pharmacies"}
            styles={customSelect}
            value={selectedPharmacy}
            options={pharmacies}
            components={{ Option: customOption }}
            onChange={(selection) => {
              setDesignations(selection);
            }}
          />
        </div>
        <div className="stat-cards__form__fieldset__values">
          <div
            className="stat-cards__form__fieldset__details__title stat-cards__form__fieldset__details__title--mt"
            style={{ textAlign: "right" }}
          >
            Health Industry Number (HIN)
          </div>
          <div className="stat-cards__form__fieldset__values__subtitle" style={{ textAlign: "right" }}>
            {props.selectedPharmacy ? props.selectedPharmacy.hin : "--"}
          </div>
        </div>
      </div>
    );
  };

  const renderForm = () => {
    return (
      <>
        <DesignationNotices
          kind={props.kind}
          hasMap={props.renderMap}
          hasHin={props.selectedPharmacy.hin}
          emptyCps={props.pharmacies?.length === 0}
          isGrantee={isGrantee(props.entity.entity_type_code)}
          policy_doc_name={_.find(manufacturers, { name: props.manufacturer }).policy_doc_name}
          policy_doc_url={_.find(manufacturers, { name: props.manufacturer }).policy_doc_url}
          hasInHouseCp={props.entity.designation_permitted_status[props.manufacturer] == "has_inhouse_pharmacy"}
          hasWoDesignation={
            props.entity.designation_permitted_status[props.manufacturer] == "has_wholly_owned_exemption"
          }
          disableGranteeNotice={props.disableGranteeNotice}
          disableWhollyOwnedNotice={props.disableWhollyOwnedNotice}
          disableInHouseSpecialtyNotice={props.disableInHouseSpecialtyNotice}
          disableInHouseNotice={props.disableInHouseNotice}
        />
        {renderSelectForm(props.pharmacies)}
      </>
    );
  };

  const renderHiddenInputs = () => {
    if (selectedPharmacy) {
      return (
        <div>
          <input type="hidden" name={`designations[][external_contact_email]`} value={props.externalEmailContact} />
          <input type="hidden" name={`designations[][secondary_contact_email]`} value={props.secondaryContactEmail} />
          <input type="hidden" name={`designations[][manufacturer]`} value={props.manufacturer} />
          <input type="hidden" name={`designations[][manufacturer_name]`} value={props.manufacturer} />
          <input type="hidden" name={`designations[][kind]`} value={props.kind} />
          <input type="hidden" name={`designations[][id340B]`} value={props.entity.id_340b} />
          <input type="hidden" name={`designations[][pharmacy_id]`} value={selectedPharmacy.value} />
        </div>
      );
    }
  };

  const renderMap = () => {
    if (props.renderMap) {
      return (
        <CeMap
          entity={props.entity}
          kind={"retail"}
          manufacturer={props.manufacturer}
          selectedPharmacy={selectedPharmacy}
          handleSelectedPharmacy={handleSelectedPharmacy}
        />
      );
    }
  };

  const renderEntityForm = () => {
    return (
      <div>
        {renderMap()}
        <div className="stat-cards__form__fieldset">
          <div className="stat-cards__form__fieldset__details stat-cards__form__fieldset__details--no-br">
            <div className="stat-cards__form__fieldset__details__title">{props.entity.entity_name}</div>
            <div className="stat-cards__form__fieldset__details__subtitle">{props.entity.id_340b}</div>
          </div>
          {renderForm()}
          {renderHiddenInputs()}
        </div>
      </div>
    );
  };

  return renderEntityForm();
};

export default PharmacySelect;
