import React, { useState, useEffect, useCallback } from "react";
import { fetchWrapper } from "../SubmissionHttp";

const useVault = (organization, setNotice) => {
  const [salt, setSalt] = useState();

  useEffect(() => {
    fetchVault();
  }, [organization]);

  const fetchVault = useCallback(() => {
    if (salt && salt.length == 64) return;

    fetchWrapper
      .vault(organization)
      .then((res) => setSalt(res.value))
      .catch((error) =>
        setNotice({ kind: "error", open: true, message: "Oh no - it looks like something went wrong" })
      );
  }, [salt, organization, setNotice]);

  return salt;
};

export default useVault;
