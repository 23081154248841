import React, { useState } from "react";
import { Pie } from "@nivo/pie";
import Popover from "@material-ui/core/Popover";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

const StatCards = ({ stats, hideTotal = false, hideCredits = false, hideIssues = false }) => {
  return (
    <div className="reports__grid" style={{ marginTop: 20, gridTemplateRows: "auto" }}>
      {!hideTotal ? <TotolClaimsCard stats={stats} /> : null}
      {!hideIssues ? <CommonIssuesCard stats={stats} /> : null}
      {/* {!hideCredits ? <CreditsCard stats={stats} /> : null} */}
    </div>
  );
};

const TotolClaimsCard = ({ stats }) => {
  const [currentValue, setCurrentValue] = useState(null);

  const colors = ["#25265e", "#FF681F"];
  const CustomTooltip = () => null;

  const CenteredMetric = ({ dataWithArc, centerX, centerY }) => {
    return (
      <text x={centerX} y={centerY} textAnchor="middle" dominantBaseline="central" className="t--500 t--lg">
        {
          // refactor to use currentValue
          currentValue
            ? Math.round((currentValue * 100) / stats.total_claims)
            : _.isNaN(Math.round((stats.total_conforming_claims * 100) / stats.total_claims))
            ? 0
            : Math.round((stats.total_conforming_claims * 100) / stats.total_claims)
        }
        %
      </text>
    );
  };

  return (
    <div className="div1">
      <div className="tile__well" style={{ padding: 0, border: "none", height: "100%" }}>
        <div className="tile flex flex-col item-center">
          <div className="tile__header tile__header--spread">
            <div className="flex flex-col item-start">
              <div className="t--lg">Total Claims</div>
              <div className="t--sm t--gray mt-1" style={{ fontStyle: "italic" }}>
                Calculated based on current filter
              </div>
            </div>
          </div>
          <div className="flex__container flex__container__col item-start" style={{ gap: 0 }}>
            <div className="t-500 t--xl">{stats.total_claims}</div>
          </div>

          <Pie
            onMouseEnter={(node) => {
              setCurrentValue(node.value);
            }}
            onMouseLeave={() => setCurrentValue(null)}
            data={[
              {
                label: "Conforming",
                value: Number(stats.total_conforming_claims),
                color: colors[0],
                id: "Conforming",
              },
              {
                label: "Non-Conforming",
                value: Number(stats.total_non_conforming_claims),
                color: colors[1],
                id: "Non Conforming",
              },
            ]}
            theme={{ tooltip: { container: { fontSize: "12px" } } }}
            startAngle={-180}
            width={200}
            height={200}
            margin={{ top: 5, bottom: 60 }}
            innerRadius={0.6}
            activeInnerRadiusOffset={4}
            activeOuterRadiusOffset={4}
            enableArcLabels={false}
            enableArcLinkLabels={false}
            colors={colors}
            //tooltip={CustomTooltip}
            legends={[
              {
                anchor: "bottom",
                direction: "row",
                justify: false,
                translateX: 0,
                translateY: 40,
                itemWidth: 100,
                itemHeight: 14,
                symbolSize: 8,
                symbolShape: "circle",
              },
            ]}
            layers={["arcs", "legends", CenteredMetric]}
          />
        </div>
      </div>
    </div>
  );
};

const CreditsCard = ({ stats }) => {
  console.log(stats);
  const currencyFormat = (value) => {
    return value.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  };

  return (
    <div className="div2">
      <div className="tile__well" style={{ padding: 0, border: "none", height: "100%" }}>
        <div className="tile">
          <div className="tile__header tile__header--spread">
            <div className="flex flex-col item-start">
              <div className="t--lg">Credits</div>
              <div className="t--sm t--gray mt-1" style={{ fontStyle: "italic" }}>
                Calculated based on current filter
              </div>
            </div>
          </div>
          <div className="flex flex-col item-start justify-start mt--2">
            <div className="t-500 t--xl">{currencyFormat(stats.credits ? stats.credits.total : 0)}</div>
            <div className="t--sm">Total Credits</div>
          </div>

          <div className="flex flex-col item-start justify-start mt--16">
            <div className="t-500 t--lg">{currencyFormat(stats.credits ? stats.credits.paid : 0)}</div>
            <div className="flex">
              <div className="indicator"></div>
              <div className="t--sm">Paid Credits</div>
            </div>
          </div>

          <div className="flex flex-col item-start justify-start mt--16">
            <div className="t-500 t--lg">{currencyFormat(stats.credits ? stats.credits.pending : 0)}</div>

            <div className="flex">
              <div className="indicator indicator--blue"></div>
              <div className="t--sm">Pending payments</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CommonIssuesCard = ({ stats, show }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const classes = useStyles();

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="div2">
      <div className="tile__well" style={{ padding: 0, border: "none", height: "100%" }}>
        <div className="tile">
          <div className="tile__header tile__header--spread">
            <div className="flex flex-col item-start">
              <div className="t--lg">Top Issues</div>
              <div className="t--sm t--gray mt-1" style={{ fontStyle: "italic" }}>
                Calculated based on current filter
              </div>
            </div>
          </div>
          <div className="flex__container flex__container--align-center flex-col">
            {stats.issue_tally
              ?.sort((a, b) => b.count - a.count)
              .slice(0, 4)
              .map((item) => (
                <div className="progress_bar__wrapper mt--16 flex-col item-start">
                  <div className="t--sm ml-2"> {item.issue.label} </div>
                  <div className="flex">
                    <div className="progress_bar__container progress_bar__container--t-fixed" style={{ width: "100%" }}>
                      <div
                        className="progress_bar__fill"
                        style={{
                          background: "#FF681F",
                          width: `${Math.trunc((item.count / stats.total_claims) * 100)}%`,
                        }}
                      />
                    </div>
                    <div className="progress_bar__value">{`${Math.trunc(
                      (item.count / stats.total_claims) * 100
                    )}%`}</div>
                  </div>
                </div>
              ))}

            <div
              className="flex t--500 t--sm mt--16"
              aria-owns={open ? "reasoncodestooltip" : undefined}
              aria-haspopup="true"
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
            >
              All others {`   (${stats.issue_tally ? Math.max(stats.issue_tally.length - 4, 0) : 0})`}
            </div>

            <Popover
              id="reasoncodestooltip"
              className={classes.popover}
              classes={{
                paper: classes.paper,
              }}
              open={open}
              anchorEl={anchorEl}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              {stats.issue_tally
                ?.sort((a, b) => b.count - a.count)
                .slice(4, stats.issue_tally.length)
                .map((item) => (
                  <div className="t--sm p--5">{`${item.issue.label} (${Math.trunc(
                    (item.count / stats.total_claims) * 100
                  )}%)`}</div>
                ))}
            </Popover>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatCards;
