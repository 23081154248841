import Validations from "./Validations";

// Takes in row and validates cell values
export const isValidCell = (column, value, row) => {
  // if (["claim_conforms_flag", "formatted_rx_number"].includes(requiredColumn)) { return true }
  var isValid = false;
  switch (column) {
    case "date_of_service":
      isValid =
        Validations.isValidDateOfService(value) &&
        !Validations.isQuestionableDateOfService(value, row["date_prescribed"]);
      break;
    case "date_prescribed":
      isValid =
        Validations.isValidDatePrescribed(value) &&
        !Validations.isQuestionableDatePrescribed(value, row["date_of_service"]);
      break;
    case "rx_number":
      isValid = Validations.isValidRXNumber(value);
      break;
    case "ndc":
      isValid = Validations.isValidNDC(value);
      break;
    case "quantity":
      isValid = Validations.isValidQuantity(value) && !Validations.isQuestionableQuantity(value);
      break;
    case "wholesaler_invoice_number":
      isValid = Validations.isValidWholesalerInvoiceNumber(value);
      break;
    case "prescriber_id_qualifier":
      isValid = Validations.isValidPrescriberIDQualifier(value);
      break;
    case "prescriber_id":
      isValid = Validations.isValidPrescriberID(row["prescriber_id_qualifier"], value);
      break;
    case "service_provider_id_qualifier":
      isValid = Validations.isValidProviderIDQualifier(value);
      break;
    case "service_provider_id":
      isValid = Validations.isValidServiceProviderID(row["service_provider_id_qualifier"], value);
      break;
    case "contracted_entity_id":
      isValid = Validations.isValidContractedEntityID(value);
      break;

    // Medical Columns
    case "date_of_service":
      isValid = true;
      break;
    case "plan_id_qualifier":
      isValid = true;
      break;
    case "plan_id_code":
      isValid = true;
      break;
    case "plan_name":
      isValid = true;
      break;
    case "product_service_id_qualifier":
      isValid = true;
      break;
    case "product_service_id":
      isValid = true;
      break;
    case "billed_hcpcs_quantity":
      isValid = true;
      break;
    case "billed_hcpcs_unit_of_measure":
      isValid = true;
      break;
    case "prescription_type":
      isValid = true;
      break;
    case "provider_claim_number":
      isValid = true;
      break;
    case "payor_claim_number":
      isValid = true;
      break;
    case "hcpcs_code":
      isValid = true;
      break;
    case "hcpcs_code_modifier_1":
      isValid = true;
      break;
    case "hcpcs_code_modifier_2":
      isValid = true;
      break;

    // Encounter Columns
    case "billing_service_provider_id":
      isValid = Validations.isValidBillingServiceProviderID(row["billing_prescriber_id_qualifier"], value);
      break;
    case "billing_service_provider_id_qualifier":
      isValid = Validations.isValidProviderIDQualifier(value);
      break;
    case "date_of_encounter":
      isValid = Validations.isValidDateOfService(value)
      break;
    case "rendering_physician_id":
      isValid = Validations.isValidRenderingPhysicianID(row["rendering_physician_id_qualifier"], value);
      break;
    case "rendering_physician_id_qualifier":
      isValid = Validations.isValidProviderIDQualifier(value);
      break;
    case "hcpcs_code":
      isValid = Validations.isValidHCPCSCode(value);
      break;
    case "hcpcs_code_modifier_1":
      isValid = Validations.isValidHCPCSModifier(value);
      break;
    case "hcpcs_code_modifier_2":
      isValid = Validations.isValidHCPCSModifier(value);
      break;
    case "diagnosis_code":
      isValid = Validations.isValidDiagnosisCode(value);
      break;
    case "place_of_service_code":
      isValid = Validations.isValidPlaceOfServiceCode(value);
      break;

    default:
      isValid = false;
  }

  return isValid;
};
