import _ from "lodash";
import { columnSchema } from "../schemas/pharmacy_claim";
import { isValidCell } from "./isValidCell";
import { validateHashedRow } from "./validateHashedRow";
import { formatValueOnly } from "./DataProcessing";

export const frontEndValidationEngine = (data, columnMapping, ndcList, salt, filesWithErrors, position) => {
  /* 
  1. Filter (`filterData()`) and format (`formatValue()`) data so that we have formatted 
  rows for DataOutput and unformatted rows for DataOutputOriginal, both held in state.
  2. Validate formatted values and store whether individual cells are valid/invalid. This will 
  need to be used when rendering validation descriptions in DataOutput.
  3. We determine whether a file has errors by first determining if there are any rows left 
  after filtering/formatting. Then if there are, we run validation check on those rows (`validationsNeeded`).
  If a single cell is invalid, the entire file is invalid and we push its attachment position into the filesWithErrors array.
   */

  // Do all the processing within one iteration?

  const processedData = [];
  const newFilesWithErrors = [...filesWithErrors];
  const showValidationError = _.reduce(
    _.cloneDeep(columnSchema),
    (obj, value, key) => {
      obj[key] = false;
      return obj;
    },
    {}
  );

  /* 1. Filter (`filterData()`) and format (`formatValue()`) data so that we have formatted 
  rows for DataOutput and unformatted rows for DataOutputOriginal, both held in state. */

  _.forEach(data, (row, index) => {
    /* 

    1. FILTER DATA

     */
    // if row doesn't have a valid ndc, exclude from further processing
    if (!_.some(ndcList, ["code", row[columnMapping["ndc"]]])) {
      return;
    }

    // remove unmapped keys
    let filteredRow = _.pick(row, _.values(columnMapping));

    // include additional keys for custom data
    filteredRow["claim_conforms_flag"] = null;
    filteredRow["formatted_rx_number"] = null;

    /* 

    2. VALIDATE DATA

      - Do we want a hash of which validation notices to display on a file-by-file basis
    
     */
    const processedRow = {
      values: filteredRow,
      validations: {},
      valid: true,
    };

    _.forOwn(columnMapping, (customColumn, schemaColumn) => {
      processedRow.validations[schemaColumn] = true;

      if (isValidCell(schemaColumn, filteredRow[customColumn], filteredRow, columnMapping) === false) {
        showValidationError[schemaColumn] = true;
        processedRow.validations[schemaColumn] = false;
        processedRow.valid = false;

        // adds file position to files with errors if a single row is invalid
        if (!newFilesWithErrors.includes(position)) {
          newFilesWithErrors.push(position);
        }
      }
    });

    /* 

    3. FORMAT DATA (hash/de-identify)
    
     */

    var schemaClone = _.cloneDeep(columnSchema);

    _.map(schemaClone, (value, key) => {
      schemaClone[key] = formatValueOnly(
        key,
        filteredRow[columnMapping[key]],
        salt,
        filteredRow,
        columnMapping,
        ndcList
      );
    });

    processedRow.values = schemaClone;

    processedData.push(processedRow);

    /* 

    4. Set files with validation errors ()
    
     */

    // verify data to submit is hashed
    validateHashedRow(row, newFilesWithErrors, position);
  });

  return {
    processedData,
    showValidationError,
    newFilesWithErrors,
  };

  /*
  We want to return:
  1. filesWithErrors (array of file position integers)
  2. filtered/formatted data for DataOutput
  3. Unformatted data for DataOutputOriginal
  4. Information to determine what validation descriptions to render in the DataOutput view
   */
};
