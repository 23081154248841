import React, { useState, useEffect, useRef } from "react";
import Notice from "../../components/Notice";
import Bayer from "~/images/Bayer.png";
import _ from "lodash";
import { search, filterByStatus } from "../../components/Pharmacies/utils";
import PharmacySearch from "../../components/Pharmacies/PharmacySearch";

import ModalDesignationForm from "./designations/ModalDesignationForm";

export default function BayerPharmacyTable(props) {
  const [editing, setEditing] = React.useState(false);
  const [displayConfirmation, setDisplayConfirmation] = React.useState(false);
  const [attestingPharmacies, setAttestingPharmacies] = React.useState([]);
  const [pharmacies, setPharmacies] = React.useState(props.pharmacies);
  const [notice, setNotice] = React.useState({
    kind: "error",
    open: false,
    message: "",
  });
  const [eligibility, setEligibility] = useState(props.eligibility);
  const [searchTerms, setSearchTerms] = useState({ searchQuery: "", filterQuery: [] });

  useEffect(() => {
    filterPharmacies();
  }, [searchTerms]);

  const filterPharmacies = () => {
    if (searchTerms.filterQuery.length > 0) {
      return filterByStatus(pharmacies, searchTerms, eligibility, ["retail", "adempas"]);
    } else {
      return search(pharmacies, searchTerms);
    }
  };

  const renderEligibilityTag = (status, date) => {
    switch (status) {
      case "ELIGIBLE":
        return (
          <div className="badge">
            <div className="badge__indicator badge__indicator--green" />
            ELIGIBLE
          </div>
        );
      case "INELIGIBLE":
        return (
          <div className="badge">
            <div className="badge__indicator badge__indicator--red" />
            INELIGIBLE
          </div>
        );
      case "INELIGIBLE_MISSING_CLAIMS":
        return (
          <div className="badge">
            <div className="badge__indicator badge__indicator--red" />
            INELIGIBLE - MISSING CLAIMS
          </div>
        );
      case "IN_PROCESS":
        return (
          <div className="badge">
            <div className="badge__indicator badge__indicator--green" />
            IN PROCESS
          </div>
        );
      case "AT_RISK":
        return (
          <div className="badge">
            <div className="badge__indicator badge__indicator--orange" />
            AT RISK
          </div>
        );
    }
  };

  const renderPharmacyStatus = (pharmacy) => {
    return renderEligibilityTag(
      props.eligibility[pharmacy.pharmacy_id].retail,
      props.eligibility[pharmacy.pharmacy_id].risk_expiration_date
    );
  };

  const renderPharmacySpecialtyStatus = (pharmacy) => {
    return renderEligibilityTag(
      props.eligibility[pharmacy.pharmacy_id] && props.eligibility[pharmacy.pharmacy_id].adempas,
      props.eligibility[pharmacy.pharmacy_id].risk_expiration_date
    );
  };

  const renderPharmacies = () => {
    return filterPharmacies().map((pharmacy, i) => {
      return (
        <tr key={i} className="sm">
          <td className="t--md">
            <div className="t--sm t--500">{pharmacy.pharmacy_name}</div>
            <div className="t--sm t--subtitle">{pharmacy.address}</div>
          </td>
          <td className="t--md">{pharmacy.dea}</td>
          <td className="t--md">{renderPharmacyStatus(pharmacy)}</td>
          <td className="t--md">{renderPharmacySpecialtyStatus(pharmacy)}</td>
        </tr>
      );
    });
  };

  return (
    <div>
      <div className="table__actionbar table__actionbar--spacebetween">
        <div className="table__actionbar__vertical_stack">
          <div className="section__title">Contracted Pharmacies</div>
          <PharmacySearch searchTerms={searchTerms} setSearchTerms={setSearchTerms} filterable={true} />
        </div>
        <ModalDesignationForm
          entity_id={props.entity.id}
          entity={props.entity}
          entity_designation={props.entity_designations_status}
          designations_path={props.designations_path}
        />
      </div>
      <div className="table__container" style={{ padding: "0 5px", marginBottom: 50 }}>
        <table className="primary">
          <thead>
            <tr className="sm">
              <th>Pharmacy</th>
              <th>DEA</th>
              <th>Status</th>
              <th>Adempas</th>
            </tr>
          </thead>

          <tbody>{renderPharmacies()}</tbody>
        </table>
      </div>
      <Notice details={notice} />
    </div>
  );
}
