import React, { useState, useEffect } from "react";
import _ from "lodash";
import dataImage from "~/images/data_illustration.png";
import submissionStore from "../../Submissions/Events";
import { columnSchema, columnDetails, required } from "../../Submissions/schemas/pharmacy_encounter_claim";
import XLSX from "xlsx";

const ColumnMapping = (props) => {
  const [fileAttached, setFileAttached] = useState(false);
  const [selectedColumn, setSelectedColumn] = useState("contracted_entity_id");
  const [attachedColumns, setAttachedColumns] = useState([]);
  const [mappings, setMappings] = useState(columnSchema);

  const keys = _.keys(columnSchema).sort();
  const requiredColumns = required;

  useEffect(() => {
    const subs = submissionStore.subscribe((value) => {
      handleFile(value);
      handleFiles(value);
    });
  });

  const canSubmit = () => {
    const columns = _.pick(mappings, requiredColumns);

    return !_.some(columns, _.isEmpty);
  };

  const handleFile = (file) => {
    setFileAttached(true);
  };

  const handleFiles = (file) => {
    var reader = new FileReader();

    reader.onload = function (e) {
      const workbook = XLSX.read(e.target.result, { type: "binary" });
      const sheet = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheet];

      const data = XLSX.utils.sheet_to_json(worksheet, {
        raw: false,
        header: 1,
      });

      setAttachedColumns(data[0]);
    };

    reader.readAsBinaryString(file);
  };

  const submitMappings = () => {
    if (!canSubmit()) return;

    fetch(props.submitPath, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
      },
      body: JSON.stringify({ field_mappings: mappings }),
    })
      .then((res) => res.json())
      .then(
        (data) => {
          location.href = props.path;
        },
        (error) => {
          location.reload();
        }
      );
  };

  const buildMappings = (field) => {
    setMappings({
      ...mappings,
      [selectedColumn]: field,
    });

    var position = keys.indexOf(selectedColumn);

    if (keys.length == position + 1) {
      setSelectedColumn(keys[0]);
    } else {
      setSelectedColumn(keys[position + 1]);
    }
  };

  const removeMapping = (field) => {
    _.findKey(mappings, (val) => val == field);

    setMappings({
      ...mappings,
      [_.findKey(mappings, (val) => val == field)]: null,
    });
  };

  const renderRequiredColumns = (column) => {
    return requiredColumns.includes(column) ? (
      <span>
        <span>{column}</span>
        <span style={{ color: "#f50057" }}>*</span>
      </span>
    ) : (
      column
    );
  };

  const renderMappingsForm = () => {
    return (
      <div>
        <div className="onboarding__content__form">
          <div className="onboarding__content__form__title">Map data fields</div>
          <p>
            Use the form below to indicate which fields in your sample data file correspond to the information that we
            collect. The fields in the column on the left represent the data elements that we collect. The fields in the
            column on the right represent the data elements in your sample file.
            <br />
            <br />
            Hover over a field in the left-hand column for a detailed description of information that we collect.
          </p>
          <div className="modal__body__columns__container">
            {_.keys(columnSchema)
              .sort()
              .map((column, i) => (
                <div
                  key={i}
                  className={
                    selectedColumn == column
                      ? "modal__body__columns__item__container modal__body__columns__item__container--selected"
                      : "modal__body__columns__item__container"
                  }
                  onClick={() => {
                    setSelectedColumn(column);
                  }}
                >
                  <div className="tooltip">
                    <div className="tooltip__container">
                      <div className="tooltip__container__title">{_.find(columnDetails, ["name", column]).title}</div>
                      <div className="tooltip__container__subtitle">
                        {_.find(columnDetails, ["name", column]).description}
                      </div>
                    </div>
                  </div>

                  <div className="modal__body__columns__item">{renderRequiredColumns(column)}</div>

                  {mappings[column] && (
                    <div
                      className="tag tag--square tag--light-blue"
                      onClick={() => {
                        removeMapping(mappings[column]);
                      }}
                    >
                      <div className="tag__with_icon_text">{mappings[column]}</div>
                      <i className="solid solid-budicon-cross-sign" />
                    </div>
                  )}
                </div>
              ))}
          </div>
          <div className="modal__body__fields__container">
            {attachedColumns &&
              _.difference(attachedColumns.sort(), _.values(mappings)).map((field, i) => (
                <div
                  key={i}
                  className="modal__body__fields__item__container"
                  onClick={() => {
                    buildMappings(field);
                  }}
                >
                  {field}
                </div>
              ))}
          </div>
          <div className="onboarding__content__form__actions onboarding__content__form__actions--margin-sm">
            <a href={props.path}>
              <div className="btn btn--outline btn--white">Cancel and Return</div>
            </a>
            <div
              className={canSubmit() ? "btn" : "btn btn--disabled"}
              disabled={canSubmit() ? false : true}
              style={{ flexGrow: 1, marginLeft: 30 }}
              onClick={() => {
                submitMappings();
              }}
            >
              Save Column Mappings
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderAttachForm = () => {
    return (
      <div>
        <input
          type="hidden"
          name="authenticity_token"
          value={document.querySelector('meta[name="csrf-token"]').getAttribute("content")}
        />
        <div className="onboarding__content__form">
          <div className="onboarding__content__form__image">
            <img src={dataImage} />
          </div>
          <div className="onboarding__content__form__title">Attach a data file</div>
          <p style={{ marginBottom: 30 }}>
            <strong style={{ color: "#0A162F" }}>NOTE: </strong>
            Please ensure the first row of your sample data file has column headers.
          </p>
          <AttachFile />
          <div className="onboarding__content__form__actions onboarding__content__form__actions--margin-sm">
            <a href={props.path}>
              <div className="btn btn--outline btn--white">Cancel and Return</div>
            </a>
            <button
              className={fileAttached ? "btn" : "btn btn--disabled"}
              style={{ flexGrow: 1, marginLeft: 30 }}
              disabled={fileAttached ? false : true}
              type="submit"
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    );
  };

  return fileAttached ? renderMappingsForm() : renderAttachForm();
};

const SheetJSFT = [
  ".xlsx",
  ".xlsb",
  ".xlsm",
  ".xls",
  ".xml",
  ".csv",
  ".txt",
  ".ods",
  ".fods",
  ".uos",
  ".sylk",
  ".dif",
  ".dbf",
  ".prn",
  ".qpw",
  ".123",
  ".wb*",
  ".wq*",
  ".html",
  ".htm",
];

const AttachFile = (props) => {
  const [files, setFiles] = useState([]);
  const [selected, setSelected] = useState(0);

  const handleChange = (e) => {
    const attachments = e.target.files;

    broadcastFile(attachments[0]);

    setFiles([...files, ...attachments]);

    setSelected(selected + 1);
  };

  const broadcastFile = (file) => {
    submissionStore.attachedFiles(file);
  };

  const handleSelectedFile = (file, index) => {
    broadcastFile(file);
    setSelected(index + 1);
  };

  return (
    <div>
      <div className="draw__section__header">
        <div className="draw__section__title">
          Attach a sample 340B claims data file.
          <br />
          <br />
          Please ensure this file has the same fields as the claims data files you intend to upload to 340B ESP™
        </div>
        <div className="draw__section__caddy" style={{ marginLeft: 30 }}>
          <div
            className="btn btn--small"
            onClick={() => {
              document.getElementById("submissionAttachments").click();
            }}
          >
            Attach
          </div>
          <input
            type="file"
            style={{ display: "none" }}
            className="form-control"
            id="submissionAttachments"
            accept={SheetJSFT}
            multiple={true}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
};

export default ColumnMapping;
