export const claimSchema = {
  date_of_service: null,
  date_prescribed: null,
  rx_number: null,
  fill_number: null,
  ndc: null,
  quantity: null,
  prescriber_id: null,
  prescriber_id_qualifier: null,
  service_provider_id: null,
  service_provider_id_qualifier: null,
  wholesaler_invoice_number: null,
  contracted_entity_id: null,
  claim_conforms_flag: null,
  formatted_rx_number: null,
  billing_service_provider_id: null,
  billing_service_provider_id_qualifier: null,
  encounter_date_of_service: null,
  rendering_physician_id: null,
  rendering_physician_id_qualifier: null,
  hcpcs_code: null,
  hcpcs_code_modifier_1: null,
  hcpcs_code_modifier_2: null,
  diagnosis_code: null,
  place_of_service_code: null,
};

export const claim = {
  original: claimSchema,
  errors: false,
  columns_with_errors: {},
  data: claimSchema,
};

export const vanityColumnSchema = {
  date_of_service: "Date of Service",
  date_prescribed: "Date Prescribed",
  rx_number: "Rx Number",
  fill_number: "Fill Number",
  ndc: "NDC",
  quantity: "Quantity",
  prescriber_id: "Prescriber ID",
  prescriber_id_qualifier: "Prescriber ID Qualifier",
  service_provider_id: "Service Provider ID",
  service_provider_id_qualifier: "Service Provider ID Qualifier",
  wholesaler_invoice_number: "Wholesaler Invoice Number",
  contracted_entity_id: "Contracted Entity ID",
  billing_service_provider_id: "Billing Service Provider ID",
  billing_service_provider_id_qualifier: "Billing Service Provider ID Qualifier",
  encounter_date_of_service: "Encounter Date of Service",
  rendering_physician_id: "Rendering Physician ID",
  rendering_physician_id_qualifier: "Rendering Physician ID Qualifier",
  hcpcs_code: "HCPCS Code",
  hcpcs_code_modifier_1: "HCPCS Code Modifier 1",
  hcpcs_code_modifier_2: "HCPCS Code Modifier 2",
  diagnosis_code: "Diagnosis Code",
  place_of_service_code: "Place of Service Code",
};
