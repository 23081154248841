import React, { Component } from "react";
import DayJS from "react-dayjs";
import _ from "lodash";
import moment from "moment";
import SubmissionsModal from "../modals/ClaimsModal";

const FileIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="31" viewBox="0 0 24 31">
    <g transform="translate(-0.223)">
      <path
        d="M1.068,0H16.646l7.375,7.481V32.562a1.068,1.068,0,0,1-1.068,1.068H1.068A1.068,1.068,0,0,1,0,32.562V1.068A1.068,1.068,0,0,1,1.068,0Z"
        transform="translate(2.541)"
        fill="#E2EAFF"
      />
      <path d="M0,0,7.473,7.473h-5.9A1.573,1.573,0,0,1,0,5.9Z" transform="translate(19.089)" fill="#9DB7FF" />
      <g transform="translate(0 14.401)">
        <rect width="21" height="10" rx="2" transform="translate(0.223 0.344)" fill="#3360D9" />
        <text
          transform="translate(5 7)"
          fill="#ffffff"
          fontSize="6"
          fontFamily="Roboto-Bold, Roboto"
          fontWeight="700"
          letterSpacing="0.007em"
        >
          <tspan x="0" y="0">
            FILE
          </tspan>
        </text>
      </g>
    </g>
  </svg>
);

const SubmissionDetailsCard = (props) => {
  const modalItems = [
    {
      heading: "Pharmacy Claims",
      content: `Pharmacy claims represent adjudicated prescription claims data originating from a retail, mail-order or specialty pharmacy dispense of a medication for subsequent self-administration by the patient.`,
      link: props.links.pharmacy_claim,
    },
    {
      heading: "Medical Claims",
      content: `Medical claims represent health care claims data originating from a physician's administration of a medication directly to a patient in an outpatient setting.  The physician administered drug may have been separately reimbursable (e.g. an infused oncology medication) or may have been reimbursed as part of an outpatient procedure (e.g. the administration of a blood thinner).`,
      link: props.links.medical_claim,
    },
    {
      heading: "Pharmacy Eligibility Data",
      content: `Pharmacy claims represent adjudicated prescription claims data originating from a retail, mail-order or specialty pharmacy dispense of a medication for subsequent self-administration by the patient.`,
      link: props.links.pharmacy_credit,
      // badge: "Credit",
    },
    {
      heading: "Medical Eligibility Data",
      content: `Medical claims represent health care claims data originating from a physician's administration of a medication directly to a patient in an outpatient setting. The physician administered drug may have been separately reimbursable (e.g. an infused oncology medication) or may have been reimbursed as part of an outpatient procedure (e.g. the administration of a blood thinner).`,
      link: props.links.medical_credit,
      // badge: "Credit",
    },
  ];

  const renderSubmissionNoticeCard = () => {
    return (
      <div className="draw__submission_card draw__submission_card--notice-orange draw__submission_card--fluid">
        <div className="draw__submission_card__content_container draw__submission_card__content_container--sm-pb">
          <div
            className="indicator indicator--lg indicator--white indicator--pulse--white"
            style={{ marginBottom: 8 }}
          ></div>
          <div>
            <div className="draw__submission_card__title">Incomplete Submissions</div>
            <div className="draw__submission_card__subtitle draw__submission_card__subtitle--lg">
              Based on a comparison of your 340B purchase volume and 340B claims submissions, your entity has been
              identified as having incomplete 340B claims submissions. To ensure your continued participation with
              pharmaceutical manufacturers' data integrity initiatives, please review each Entity Profile module.
              <br />
              <br />
              Please note that you may need to submit additional 340B claims data within the next two submission cycles.
            </div>
          </div>
        </div>
        <div className="draw__submission_card__footer">
          <a href={`/organization/${props.organization}/entities/${props.entity.id}`}>
            <div
              className="btn btn--ib btn--small btn--white btn--b-dark-orange btn--default-align"
              style={{ marginTop: 20 }}
            >
              View Details
            </div>
          </a>
          <div className="draw__submission_card__shape__container draw__submission_card__shape__container--small">
            <div className="draw__submission_card--half-cir-shape-2 draw__submission_card--half-cir-shape-2--orange draw__submission_card--half-cir-shape-2--move-up" />
            <div className="draw__submission_card--half-cir-shape-3 draw__submission_card--half-cir-shape-3--light-orange draw__submission_card--half-cir-shape-3--move-up" />
            <div className="draw__submission_card--half-cir-shape-4 draw__submission_card--half-cir-shape-4--orange draw__submission_card--half-cir-shape-4--move-up" />
            <div className="draw__submission_card--half-cir-shape-5 draw__submission_card--half-cir-shape-5--orange draw__submission_card--half-cir-shape-5--move-up" />
          </div>
        </div>
      </div>
    );
  };

  const renderDownloads = () => {
    const downloads = [
      {
        name: "Eligibility File",
        link: `/organization/${props.organization}/eligibility.xlsx`,
      },
      {
        name: "Designations",
        link: `/organization/${props.organization}/designations.xlsx`,
      },
      {
        name: "NDC list",
        link: `/organization/${props.organization}/ndcs.xlsx`,
      },
    ];

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: 20,
          borderTop: "1px solid #DEE5EB",
          borderBottom: "1px solid #DEE5EB",
          padding: "20px 0",
        }}
      >
        <div className="draw__tile__details__title">Downloads</div>

        <div style={{ display: "flex", gap: "11px" }}>
          {downloads.map((item) => (
            <a href={item.link} key={item.name}>
              <div
                className="draw__tile"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: 5,
                  background: "none",
                  border: "1px solid #DEE5EB",
                  padding: 13,
                }}
              >
                <div
                  className="draw__tile__icon"
                  style={{
                    width: 28,
                    height: 20,
                  }}
                >
                  <FileIcon />
                </div>
                <div className="draw__tile__details t--xsm" style={{ marginTop: 16 }}>
                  <div className="draw__tile__details__title t--xsm">{item.name}</div>
                  <div className="draw__tile__details__subtitle t--xsm t--grey">Last updated</div>
                  <div className="t--xsm t--grey">{moment(props.entity.updated_at).format("MM.D.YYYY")}</div>
                </div>
              </div>
            </a>
          ))}
        </div>
      </div>
    );
  };

  const renderSubmissionCard = () => {
    return (
      <div className="draw__submission_card">
        <div className="draw__submission_card--half-cir-shape-1" />
        <div className="draw__submission_card--half-cir-shape-2" />
        <div className="draw__submission_card--half-cir-shape-3" />
        <div className="draw__submission_card--half-cir-shape-4" />
        <div className="draw__submission_card--half-cir-shape-5" />
        <div className="draw__submission_card__content_container">
          <div>
            <div className="draw__submission_card__title" style={{ fontSize: 15 }}>
              Submissions
            </div>
            {/* <div className="draw__submission_card__subtitle">Date</div> */}
          </div>
          <div className="draw__submission_card__date_icon">
            <div className="draw__submission_card__date_icon__date">{moment().format("DD")}</div>
            <div className="draw__submission_card__date_icon__month">{moment().format("MMM")}</div>
          </div>

          <SubmissionsModal
            {...props}
            items={modalItems}
            buttonStyle="primary"
            style={{ padding: "10px 24px", margin: 0 }}
          />
        </div>
      </div>
    );
  };

  const renderCard = () => {
    if (props.atRisk) {
      return renderSubmissionNoticeCard();
    } else {
      return renderSubmissionCard();
    }
  };

  const render = () => {
    return (
      <div>
        {renderCard()}
        {renderDownloads()}
      </div>
    );
  };

  return render();
};

export default SubmissionDetailsCard;
