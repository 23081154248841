import React, { useState, useEffect, useRef } from "react";
import Notice from "../../components/Notice";
import _ from "lodash";
import { manufacturers } from "../details";
import StyledCheckbox from "../../components/utils/StyledCheckbox";
import { search, filterByStatus } from "../../components/Pharmacies/utils";
import PharmacySearch from "../../components/Pharmacies/PharmacySearch";
import ConfirmationModal from "../../components/ConfirmationModal";

import ModalDesignationForm from "./designations/ModalDesignationForm";

export default function NovoNordiskPharmacyTable(props) {
  const [editing, setEditing] = React.useState(false);
  const [displayConfirmation, setDisplayConfirmation] = React.useState(false);
  const [attestingPharmacies, setAttestingPharmacies] = React.useState([]);
  const [pharmacies, setPharmacies] = React.useState(props.pharmacies);
  const [notice, setNotice] = React.useState({
    kind: "error",
    open: false,
    message: "",
  });
  const [eligibility, setEligibility] = useState(props.eligibility);
  const [searchTerms, setSearchTerms] = useState({ searchQuery: "", filterQuery: [] });

  useEffect(() => {
    filterPharmacies();
  }, [searchTerms]);

  const filterPharmacies = () => {
    if (searchTerms.filterQuery.length > 0) {
      return filterByStatus(pharmacies, searchTerms, eligibility, ["retail"]);
    } else {
      return search(pharmacies, searchTerms);
    }
  };

  const submit = () => {
    fetch(props.submitPath, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
      },
      body: JSON.stringify({
        submission_attestation: {
          entity: props.entity.id,
          attesting_list: [
            {
              ids: attestingPharmacies,
              kind: "retail",
            },
          ],
          manufacturer_name: "novo_nordisk",
        },
      }),
    })
      .then((res) => {
        if (res.status >= 200 && res.status <= 299) {
          return res.json();
        } else {
          throw Error(res.statusText);
        }
      })
      .then((data) => {
        setPharmacies(data.pharmacies);
        setAttestingPharmacies([]);
        setEditing(false);
        setNotice({
          kind: "success",
          open: true,
          message: data.notice,
        });

        setDisplayConfirmation(false);
      })
      .catch((error) => {
        setNotice({
          kind: "error",
          open: true,
          message: "An Error occurred - please try again or contact support.",
        });

        setDisplayConfirmation(false);
      });
  };

  const setAllChecked = (checked) => {
    if (checked) {
      setAttestingPharmacies(
        _.map(
          _.filter(pharmacies, (cp) => cp.attesting.novo_nordisk?.has_retail_attestation === false),
          (pharmacy) => {
            return { pharmacy_id: pharmacy.id, pid: pharmacy.pharmacy_id };
          }
        )
      );
    } else {
      setAttestingPharmacies([]);
    }
  };

  const setChecked = (checked, pharmacy) => {
    var stateClone = _.cloneDeep(attestingPharmacies);

    if (checked) {
      stateClone.push(pharmacy);
    } else {
      _.remove(stateClone, pharmacy);
    }

    setAttestingPharmacies(stateClone);
  };

  const renderAttestationButton = () => {
    if (editing) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <div
            className={`${attestingPharmacies.length > 0 ? "btn" : "btn btn--disabled"}`}
            onClick={() => {
              if (attestingPharmacies.length > 0) {
                setDisplayConfirmation(true);
              }
            }}
          >
            Save Attestations
          </div>
          <div
            className="btn btn--outline btn--white"
            style={{ marginLeft: 20 }}
            onClick={() => {
              setAttestingPharmacies([]);
              setEditing(!editing);
            }}
          >
            Cancel
          </div>
        </div>
      );
    } else {
      return (
        <div
          className="btn btn--outline btn--white btn--default-align"
          onClick={() => {
            setEditing(!editing);
          }}
        >
          Make Attestations
        </div>
      );
    }
  };

  const renderCheckbox = (pharmacy) => {
    if (editing) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <span
            style={{
              color: "#718291",
              fontSize: 11,
              fontStyle: "italic",
              maxWidth: 500,
            }}
          >
            Check this box to attest your intent to submit 340B claims data on contract pharmacy transactions per Novo
            Nordisk policy
            <a href={_.find(manufacturers, { name: "novo_nordisk" }).policy_doc_url} target="_blank">
              <span style={{ fontWeight: 500, textDecoration: "underline" }}> View Policy</span>
            </a>
          </span>
          <StyledCheckbox
            name="novo_nordisk"
            color="primary"
            onClick={(e) => {
              setChecked(e.target.checked, {
                pharmacy_id: pharmacy.id,
                pid: pharmacy.pharmacy_id,
              });
            }}
            checked={_.map(attestingPharmacies, "pid").includes(pharmacy.pharmacy_id)}
          />
        </div>
      );
    }
  };

  const renderHeadCheckbox = () => {
    if (editing) {
      return (
        <StyledCheckbox
          disableRipple
          name="novo_nordisk"
          color="primary"
          onClick={(e) => {
            setAllChecked(e.target.checked);
          }}
        />
      );
    }
  };

  const renderEligibilityTag = (status, date) => {
    switch (status) {
      case "ELIGIBLE":
        return (
          <div className="badge">
            <div className="badge__indicator badge__indicator--green" />
            ELIGIBLE
          </div>
        );
      case "INELIGIBLE":
        return (
          <div className="badge">
            <div className="badge__indicator badge__indicator--red" />
            INELIGIBLE
          </div>
        );
      case "INELIGIBLE_MISSING_CLAIMS":
        return (
          <div className="badge">
            <div className="badge__indicator badge__indicator--red" />
            INELIGIBLE - MISSING CLAIMS
          </div>
        );
      case "IN_PROCESS":
        return (
          <div className="badge">
            <div className="badge__indicator badge__indicator--green" />
            IN PROCESS
          </div>
        );
      case "AT_RISK":
        return (
          <div className="badge">
            <div className="badge__indicator badge__indicator--orange" />
            AT RISK
          </div>
        );
    }
  };

  const renderPharmacyStatus = (pharmacy) => {
    return renderEligibilityTag(
      props.eligibility[pharmacy.pharmacy_id].retail,
      props.eligibility[pharmacy.pharmacy_id].risk_expiration_date
    );
  };

  const renderPharmacyAttested = (pharmacy) => {
    if (
      pharmacy.attesting.novo_nordisk &&
      pharmacy.attesting.novo_nordisk.attested &&
      _.find(pharmacy.attesting.novo_nordisk.attested, (a) => a.kind === "retail")
    ) {
      return <div className="tag  tag--small tag--with-tooltip tag--square tag--light-green">Attested</div>;
    } else {
      return renderCheckbox(pharmacy);
    }
  };

  const renderPharmacies = () => {
    return filterPharmacies().map((pharmacy, i) => {
      return (
        <tr key={i} className="sm">
          <td className="t--md">
            <div className="t--sm t--500">{pharmacy.pharmacy_name}</div>
            <div className="t--sm t--subtitle">{pharmacy.address}</div>
          </td>
          <td className="t--md">{pharmacy.dea}</td>
          <td className="t--md">{renderPharmacyStatus(pharmacy)}</td>
          <td>{renderPharmacyAttested(pharmacy)}</td>
        </tr>
      );
    });
  };

  return (
    <div>
      <div className="table__actionbar table__actionbar--spacebetween">
        <div className="table__actionbar__vertical_stack">
          <div className="section__title">Contracted Pharmacies</div>
          <PharmacySearch searchTerms={searchTerms} setSearchTerms={setSearchTerms} filterable={true} />
        </div>
        <div className="table__actions">
          <ModalDesignationForm
            entity_id={props.entity.id}
            entity={props.entity}
            entity_designation={props.entity_designations_status}
            designations_path={props.designations_path}
          />
          {renderAttestationButton()}
        </div>
      </div>

      <div className="table__container" style={{ padding: "0 5px", marginBottom: 50 }}>
        <table className="primary">
          <thead>
            <tr className="sm">
              <th>Pharmacy</th>
              <th>DEA</th>
              <th>Status</th>
              <th>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  Attestation
                  {renderHeadCheckbox()}
                </div>
              </th>
            </tr>
          </thead>

          <tbody>{renderPharmacies()}</tbody>
        </table>
      </div>

      <ConfirmationModal
        message={
          "By attesting your intent to submit 340B claims data on contract pharmacy transactions via 340B ESP™, your contract pharmacy location(s) will become eligible for 340B pricing within 14 business days.  As per Novo Nordisk policy, 340B claims must be submitted within 30 days of the initial purchase date and within 45 days of the date of dispense in order for the contract pharmacy location to remain eligible to receive 340B purchased drugs."
        }
        displayModal={displayConfirmation}
        setModalState={setDisplayConfirmation}
        action={submit}
      />
      <Notice details={notice} />
    </div>
  );
}
