import { sortedColumnDetails as pharmacyColumnDetails } from "./pharmacy_claim";
import { sortedColumnDetails as medicalColumnDetails } from "./medical_claim";
import { sortedColumnDetails as encounterColumnDetails } from "./pharmacy_encounter_claim";

export const columnDetails = (kind) => {
    switch (kind) {
        case "pharmacy":
        return pharmacyColumnDetails;
        case "medical":
        return medicalColumnDetails;
        case "encounter":
        return encounterColumnDetails;
        default:
        return [];
    }
}
