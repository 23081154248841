import React, { useState } from "react";
import _ from "lodash";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const FileCard = ({ attachment, handleRemoveFile }) => {
  const [displayFileHeaders, setDisplayFileHeaders] = useState(true);

  const renderfileHeaders = () => {
    if (displayFileHeaders) {
      const orderedfileHeaders = _.keys(attachment.mapping).sort((a, b) => a.localeCompare(b));

      return (
        <Droppable droppableId="attachedFile">
          {(provided, snapshot) => (
            <div ref={provided.innerRef} className="attachments__info__container">
              {orderedfileHeaders.map((title, i) => (
                <Draggable key={title} draggableId={title} index={i}>
                  {(provided, snapshot) => (
                    <div
                      key={i}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className="tag tag--outline-bg-white tag--square tag--font-md tag--lowercase"
                    >
                      {title}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      );
    }
  };

  const renderAttachmentStatus = (attachment) => {
    if (attachment.hasErrors) {
      return <div className="attachments__tile__status-tag attachments__tile__status-tag--errors">Errors</div>;
    } else {
      return <div className="attachments__tile__status-tag">Good</div>;
    }
  };

  const renderAttachmentHighlight = (attachment) => {
    if (attachment.hasErrors) {
      return "attachments__tile attachments__tile--space attachments__tile--selected--errors";
    } else {
      return "attachments__tile attachments__tile--space attachments__tile--selected";
    }
    // else {
    //   return "attachments__tile attachments__tile--space";
    // }
  };

  return (
    <div
      className={renderAttachmentHighlight(attachment)}
      // onClick={() => handleSelectedFile(attached.position)}
    >
      {renderAttachmentStatus(attachment)}
      <div className="attachments__tile__icon attachments__tile__icon--push-bottom">
        <svg xmlns="http://www.w3.org/2000/svg" width="26.339" height="33.63" viewBox="0 0 26.339 33.63">
          <g transform="translate(-0.223)">
            <path
              d="M1.068,0H16.646l7.375,7.481V32.562a1.068,1.068,0,0,1-1.068,1.068H1.068A1.068,1.068,0,0,1,0,32.562V1.068A1.068,1.068,0,0,1,1.068,0Z"
              transform="translate(2.541)"
              fill="#def8ea"
            />
            <path d="M0,0,7.473,7.473h-5.9A1.573,1.573,0,0,1,0,5.9Z" transform="translate(19.089)" fill="#2bd67b" />
            <g transform="translate(0 14.401)">
              <rect width="23" height="10" rx="2" transform="translate(0.223 0.344)" fill="#24b668" />
              <text
                transform="translate(6.223 7.344)"
                fill="#fff"
                fontSize="6"
                fontFamily="Roboto-Bold, Roboto"
                fontWeight="700"
                letterSpacing="0.007em"
              >
                <tspan x="0" y="0">
                  XLS
                </tspan>
              </text>
            </g>
          </g>
        </svg>
      </div>
      <div className="attachments__tile__remove__container">
        <div className="attachments__tile__remove" onClick={() => handleRemoveFile()}>
          Remove
        </div>
      </div>
      <div className="attachments__tile__details attachments__tile__details--block">
        <div className="attachments__tile__details__filename">{attachment.name}</div>
        <div className="attachments__tile__details__mapping-name">
          <span style={{ color: "#718291" }}>Selected mapping - </span>
          <span style={{ fontWeight: 700 }}>{attachment.mappingName}</span>
        </div>
      </div>

      <div className="attachments__tile__actions__container">
        <div
          className="attachments__tile__actions"
          onClick={() => {
            setDisplayFileHeaders((displayFileHeaders) => !displayFileHeaders);
          }}
        >
          {displayFileHeaders ? "Hide Details" : "Show Details"}
        </div>
      </div>
      {renderfileHeaders()}
    </div>
  );
};

export default FileCard;
