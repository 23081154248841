import React, { useState, useEffect, useRef } from "react";
import Notice from "./Notice";
import illustration from "~/images/invite_or_find_illustration.png";
import _ from "lodash";
import Select from "react-select";

const customSelectStyles = {
  container: (base) => ({
    ...base,
    margin: "0 0 0 0",
  }),
  input: (base) => ({
    ...base,
    fontSize: 14,
  }),
  singleValue: (base) => ({
    ...base,
    fontSize: 12,
  }),
  menu: (base) => ({
    ...base,
    fontSize: 12,
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: 138,
  }),
  placeholder: (base) => ({
    ...base,
    fontSize: 12,
    fontStyle: "italic",
    color: "#718291",
  }),
  control: (base) => ({
    ...base,
    minHeight: 36,
    borderColor: "#718291",
  }),
};

const AssignOneContractPharmacies = (props) => {
  const [editing, setEditing] = useState(false);
  const [selectedManufacturer, setSelectedManufacturer] = useState("boehringer_ingelheim");
  const [loading, setLoading] = useState(false);
  const [entities, setEntities] = useState(props.entities);
  const [confirmations, setConfirmations] = useState(props.confirmations);
  const [assignedCount, setAssignedCount] = useState(props.count);
  const [designationsToSubmit, setDesignationsToSubmit] = useState([]);
  const [manufacturers, setManufacturers] = useState([
    {
      name: "boehringer_ingelheim",
      label: "Boehringer Ingelheim",
      hin: true,
      confirmedPolicy: false,
      policy_doc_url: "/Boehringer Ingelheim - Letter to 340B Covered Entities.pdf",
      policy_doc_name: "Boehringer Ingelheim - Letter to 340B Covered Entities",
    },
    {
      name: "merck",
      label: "Merck",
      hin: true,
      confirmedPolicy: false,
      policy_doc_url: "/Merck 340B Program Integrity Initiative Letter - July 31 2023.pdf",
      policy_doc_name: "Merck 340B Program Integrity Initiative Letter - July 31 2023.pdf",
    },
    {
      name: "sanofi",
      label: "Sanofi",
      hin: true,
      confirmedPolicy: false,
      policy_doc_url: "/340B Sanofi Covered Entity Letter.pdf",
      policy_doc_name: "340B Sanofi Covered Entity Letter.pdf",
    },
  ]);
  const [notice, setNotice] = React.useState({
    kind: "error",
    open: false,
    message: "",
  });

  const postConfirmPolicy = () => {
    if (loading) return;

    setLoading(true);
    fetch(props.confirmPolicyPath, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
      },
      body: JSON.stringify({ manufacturer: selectedManufacturer }),
    })
      .then((res) => {
        if (res.status >= 200 && res.status <= 299) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((data) => {
        setConfirmations(data.confirmations);
        setNotice({
          kind: "success",
          open: true,
          message: data.notice,
        });
        setLoading(false);
      })
      .catch((response) => {
        response.json().then((res) => {
          setNotice({
            kind: "error",
            open: true,
            message: "error please refresh and try again",
          });
        });
      });
  };

  const postDesignations = () => {
    if (loading) return;

    if (!designationsToSubmit.length) {
      setEditing(false);
    } else {
      setLoading(true);
      fetch(props.designationsPath, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
        },
        body: JSON.stringify({
          designations: designationsToSubmit,
        }),
      })
        .then((res) => {
          if (res.status >= 200 && res.status <= 299) {
            return res.json();
          } else {
            throw res;
          }
        })
        .then((data) => {
          setEntities(data.entities);
          setAssignedCount(data.count);
          setEditing(false);
          setDesignationsToSubmit([]);
          if (data.invalidDesignations) {
            document.getElementById("noticeBannerHin").style.display = null;
          } else {
            document.getElementById("noticeBannerHin").style.display = "none";
          }
          setNotice({
            kind: "success",
            open: true,
            message: data.notice,
          });
          setLoading(false);
        })
        .catch((response) => {
          setLoading(false);

          response.json().then((res) => {
            setNotice({
              kind: "error",
              open: true,
              message: res.error,
            });
          });
        });
    }
  };

  const canEdit = () => {
    return confirmations["manufacturers"][selectedManufacturer] == true;
  };

  const assignDesignations = (selection, entity) => {
    var designations = _.cloneDeep(designationsToSubmit);
    var designation = _.find(designations, { manufacturer: selectedManufacturer, id340B: entity.id340B });

    if (designation) {
      designation.pharmacy_name = selection.label;
      designation.pharmacy_id = selection.value;
      designation.hin = selection.hin;
    } else {
      designations.push({
        manufacturer: selectedManufacturer,
        id340B: entity.id340B,
        pharmacy_name: selection.label,
        pharmacy_id: selection.value,
        hin: selection.hin,
      });
    }

    setDesignationsToSubmit(designations);
  };

  const renderLoader = () => {
    if (loading) {
      return <div className="loading-indicator" style={{ margin: "0 10px 0 0" }}></div>;
    } else {
      return <i className="solid solid-budicon-pencil-writing" />;
    }
  };

  const renderActionBtn = () => {
    if (editing) {
      return (
        <div>
          <div className="btn btn--outline-blue btn--small btn--ib btn--align-end" onClick={() => postDesignations()}>
            {renderLoader()}
            Save Selections
          </div>
          <div
            className="btn btn--label btn--small btn--ib btn--align-end"
            style={{ marginLeft: 10 }}
            onClick={() => setEditing(false)}
          >
            Cancel
          </div>
        </div>
      );
    } else {
      return (
        <div className="btn btn--outline-blue btn--small btn--ib btn--align-end" onClick={() => setEditing(true)}>
          <i className="solid solid-budicon-pencil-writing" />
          Edit
        </div>
      );
    }
  };

  const renderConfirmation = () => {
    if (editing) {
      return (
        <div className="stat-cards__confirmation__footer">
          <span>
            <div className="indicator indicator--red indicator--pulse" style={{ marginTop: 3 }}></div>
            Please confirm policy before assigning designation
          </span>
          <span>
            <div
              className="btn btn--ib btn--tiny btn--label"
              style={{ marginRight: 10 }}
              onClick={() => postConfirmPolicy()}
            >
              Confirm
            </div>
            <div className="btn btn--ib btn--tiny btn--label color--subtitle" onClick={() => setEditing(false)}>
              Cancel
            </div>
          </span>
        </div>
      );
    }
  };

  const policies = () => {
    switch (selectedManufacturer) {
      case "sanofi":
        if (confirmations["manufacturers"]["sanofi"] == false) {
          return (
            <div className="stat-cards__confirmation stat-cards__confirmation--disabled">
              <span>
                Sanofi’s 340B integrity initiative permits any 340B covered entity that does not have the ability to
                dispense 340B purchased drugs to its patients to designate a single contract pharmacy for this purpose,
                irrespective of whether the covered entity provides the data Sanofi requests. By designating a contract
                pharmacy in this web form you are acknowledging that your 340B covered entity does not have an in-house
                pharmacy that is capable of dispensing 340B purchased medicines to eligible patients.
              </span>
              {renderConfirmation()}
            </div>
          );
        }
        break;
      case "boehringer_ingelheim":
        if (confirmations["manufacturers"]["boehringer_ingelheim"] == false) {
          return (
            <div className="stat-cards__confirmation stat-cards__confirmation--disabled">
              <span>
                Boehringer Ingelheim's (BI) contract pharmacy policy permits any 340B covered entity that does not have
                the ability to dispense 340B purchased drugs to its patients to designate a single contract pharmacy for
                this purpose. By designating a contract pharmacy in this web form you are acknowledging that your 340B
                covered entity does not have an in-house pharmacy that is capable of dispensing 340B purchased medicines
                to eligible patients.
                <br />
                <br />
                Your contract pharmacy designation will be valid for 12 months from the date of your first designation.
                You must resubmit your designation after the 12 - month period has expired for your designation to
                remain in effect. You may only change your designation within a 12 - month period if the designated
                contract pharmacy relationship is terminated from the HRSA OPAIS database.
              </span>
              {renderConfirmation()}
            </div>
          );
        }
        break;
      case "merck":
        if (confirmations["manufacturers"]["merck"] == false) {
          return (
            <div className="stat-cards__confirmation stat-cards__confirmation--disabled">
              <span>
                Merck’s 340B integrity initiative permits any 340B covered entity that does not have the ability to
                dispense 340B purchased drugs to its patients to designate a single contract pharmacy for this purpose,
                irrespective of whether the covered entity provides the data Merck requests. By designating a contract
                pharmacy in this web form you are acknowledging that your 340B covered entity does not have an in-house
                pharmacy that is capable of dispensing 340B purchased medicines to eligible patients.
              </span>
              {renderConfirmation()}
            </div>
          );
        }
        break;
    }
  };

  const getDesignations = (entity) => {
    var title;
    var id;
    var hin;

    var designation = _.find(entity.designations, { manufacturer: selectedManufacturer, id340B: entity.id340B });
    if (editing) {
      var selected = _.find(designationsToSubmit, { manufacturer: selectedManufacturer, id340B: entity.id340B });
      designation = selected ? selected : designation;
    }
    if (designation) {
      title = designation.label || designation.pharmacy_name;
      id = designation.value || designation.pharmacy_id;
      hin = designation.hin;
    } else {
      title = "Not Assigned";
      id = "--";
      hin = "--";
    }

    return { title: title, id: id, hin: hin };
  };

  const renderIndicator = (manufacturer) => {
    var designations = _.filter(props.entities, { designations: [{ manufacturer: manufacturer }] });
    if (designations.length == 0 || designations.length == entities.length) {
      return <div className="stat-cards__tab__indicator" />;
    } else {
      return <div className="stat-cards__tab__indicator stat-cards__tab__indicator--red" />;
    }
  };

  const customOption = (props) => {
    return (
      <div
        {...props.innerProps}
        style={{ margin: "5px 10px", paddingBottom: 5, borderBottom: "1px solid #F2F5F8", cursor: "pointer" }}
      >
        <div style={{ fontSize: 11, fontWeight: 500 }}>{props.data.label}</div>
        <div style={{ color: "#718291", fontSize: 11 }}>{props.data.address ? props.data.address : "--"}</div>
      </div>
    );
  };

  const renderSelectInput = (entity) => {
    return (
      <Select
        openMenuOnFocus={false}
        openMenuOnClick={true}
        name={`pharmacy[${selectedManufacturer}][${entity.id340B}]`}
        placeholder={"Search Pharmacies"}
        styles={customSelectStyles}
        options={entity.pharmacies}
        components={{ Option: customOption }}
        value={{
          label: getDesignations(entity).title == "Not Assigned" ? "Search Pharmacies" : getDesignations(entity).title,
          value: getDesignations(entity).id,
        }}
        onChange={(selection) => {
          assignDesignations(selection, entity);
        }}
        isClearable={false}
      />
    );
  };

  const renderEditDesignationWithHin = (entity) => {
    return (
      <div>
        <div className="stat-cards__form__fieldset__details">
          <div className="stat-cards__form__fieldset__details__title stat-cards__form__fieldset__details__title--mt">
            Designated pharmacy
          </div>
          {renderSelectInput(entity)}
        </div>
        <div className="stat-cards__form__fieldset__values">
          <div
            className="stat-cards__form__fieldset__details__title stat-cards__form__fieldset__details__title--mt"
            style={{ textAlign: "right" }}
          >
            Health Industry Number (HIN)
          </div>
          <div className="stat-cards__form__fieldset__values__subtitle" style={{ textAlign: "right" }}>
            {getDesignations(entity).hin}
          </div>
        </div>
      </div>
    );
  };

  const renderEditDesignationWithOutHin = (entity) => {
    return (
      <div className="stat-cards__form__fieldset__details stat-cards__form__fieldset__details--no-br">
        <div className="stat-cards__form__fieldset__details__title stat-cards__form__fieldset__details__title--mt">
          Designated pharmacy
        </div>
        {renderSelectInput(entity)}
      </div>
    );
  };

  const renderDesignationWithHin = (entity) => {
    return (
      <div>
        <div className="stat-cards__form__fieldset__details">
          <div className="stat-cards__form__fieldset__details__title stat-cards__form__fieldset__details__title--mt color--subtitle">
            Designated pharmacy
          </div>
          <div
            className={
              getDesignations(entity).title == "Not Assigned"
                ? "stat-cards__form__fieldset__details__title color--subtitle"
                : "stat-cards__form__fieldset__details__title color--blue"
            }
          >
            {getDesignations(entity).title}
          </div>
        </div>
        <div className="stat-cards__form__fieldset__values">
          <div
            className="stat-cards__form__fieldset__values__title stat-cards__form__fieldset__details__title--mt color--subtitle"
            style={{ textAlign: "right" }}
          >
            Health Industry Number (HIN)
          </div>
          <div className="stat-cards__form__fieldset__values__subtitle color--subtitle" style={{ textAlign: "right" }}>
            {getDesignations(entity).hin}
          </div>
        </div>
      </div>
    );
  };

  const renderDesignationWithOutHin = (entity) => {
    return (
      <div className="stat-cards__form__fieldset__details stat-cards__form__fieldset__details--no-br">
        <div className="stat-cards__form__fieldset__details__title stat-cards__form__fieldset__details__title--mt color--subtitle">
          Designated pharmacy
        </div>
        <div
          className={
            getDesignations(entity).title == "Not Assigned"
              ? "stat-cards__form__fieldset__details__title color--subtitle"
              : "stat-cards__form__fieldset__details__title color--blue"
          }
        >
          {getDesignations(entity).title}
        </div>
      </div>
    );
  };

  const renderDesignation = (entity) => {
    var manufacturerDetails = _.find(manufacturers, { name: selectedManufacturer });

    if (editing && canEdit()) {
      if (manufacturerDetails.hin) {
        return renderEditDesignationWithHin(entity);
      } else {
        return renderEditDesignationWithOutHin(entity);
      }
    } else {
      if (manufacturerDetails.hin) {
        return renderDesignationWithHin(entity);
      } else {
        return renderDesignationWithOutHin(entity);
      }
    }
  };

  const renderHinNotice = (entity) => {
    var healthIndustryNumber = getDesignations(entity).hin;
    if (healthIndustryNumber && healthIndustryNumber != "--") return;

    var manufacturerDetails = _.find(manufacturers, { name: selectedManufacturer });

    if (editing && manufacturerDetails.hin && getDesignations(entity).title != "Not Assigned") {
      return (
        <div className="stat-cards__notice" style={{ lineHeight: 1.5 }}>
          but our records indicate the selected pharmacy does not have a HIN. You must register a HIN through
          <a href="https://www.hibcc.org/" target="_blank">
            <span className="color--orange" style={{ fontWeight: 500, textDecoration: "underline" }}>
              {" "}
              HIBCC{" "}
            </span>
          </a>
          in order to ship 340B purchased drugs to this pharmacy.
          <a href="http://help.340besp.com/en/articles/4816982-hin-registration-overview" target="_blank">
            <span className="color--orange" style={{ fontWeight: 500, textDecoration: "underline" }}>
              {" "}
              Learn more.
            </span>
          </a>
        </div>
      );
    }
  };

  const renderProhibitedNotice = (entity) => {
    if (entity.designationPermitted) return;

    switch (entity.designationPermittedStatus[selectedManufacturer]) {
      case "has_grantee_exemption":
        if (selectedManufacturer == "sanofi" && entity.entity_type_code == "CH") {
          return;
        }

        return (
          <div
            className="stat-cards__notice stat-cards__notice--red stat-cards__notice--mb"
            style={{ lineHeight: 1.5 }}
          >
            The selected entity is exempted from this policy and is not required to make a single contract pharmacy
            designation. Any designation you make will not impact your access to 340B pricing.
            <a
              href={_.find(manufacturers, { name: selectedManufacturer }).policy_doc_url}
              download={_.find(manufacturers, { name: selectedManufacturer }).policy_doc_name}
            >
              <span style={{ fontWeight: 500, textDecoration: "underline", margin: "0px 5px" }}>
                Download Policy document
              </span>
            </a>
            Please reach out to
            <span style={{ fontWeight: 500 }}> support@340besp.com </span>
            if you have any additional questions or if you do not have access to 340B pricing for your contract
            pharmacies.
          </div>
        );
      case "has_wholly_owned_exemption":
        return (
          <div
            className="stat-cards__notice stat-cards__notice--red stat-cards__notice--mb"
            style={{ lineHeight: 1.5 }}
          >
            Our records indicate the selected entity has already been granted a wholly owned contract pharmacy
            exemption. As a result of this exemption, the entity is not eligible to designate a single contract pharmacy
            arrangement and any designation you make will not impact access to 340B pricing.
            <a
              href={_.find(manufacturers, { name: selectedManufacturer }).policy_doc_url}
              download={_.find(manufacturers, { name: selectedManufacturer }).policy_doc_name}
            >
              <span style={{ fontWeight: 500, textDecoration: "underline", margin: "0px 5px" }}>
                Download Policy document
              </span>
            </a>
            Please reach out to
            <span style={{ fontWeight: 500 }}> support@340besp.com </span>
            if you have any additional questions or if you do not have access to 340B pricing for your contract
            pharmacies.
          </div>
        );
      case "has_inhouse_pharmacy":
        return (
          <div
            className="stat-cards__notice stat-cards__notice--red stat-cards__notice--mb"
            style={{ lineHeight: 1.5 }}
          >
            Our records indicate the selected entity has a pharmacy capable of purchasing and dispensing 340B drugs to
            its patients. As a result, the entity is not eligible to designate a single contract pharmacy arrangement
            and any designation you make will not impact access to 340B pricing.
            <a
              href={_.find(manufacturers, { name: selectedManufacturer }).policy_doc_url}
              download={_.find(manufacturers, { name: selectedManufacturer }).policy_doc_name}
            >
              <span style={{ fontWeight: 500, textDecoration: "underline", margin: "0px 5px" }}>
                Download Policy document
              </span>
            </a>
            Please reach out to
            <span style={{ fontWeight: 500 }}> support@340besp.com </span>
            for more information or to clarify the entity’s ability to dispense 340B purchased drugs to patients.
          </div>
        );
    }
  };

  const displayWarning = (entity) => {
    var hinRequired = _.find(manufacturers, { name: selectedManufacturer }).hin == true;

    if (getDesignations(entity).title == "Not Assigned") {
      return false;
    } else {
      if (hinRequired) {
        var healthIndustryNumber = getDesignations(entity).hin;
        return !healthIndustryNumber || healthIndustryNumber == "--";
      } else {
        return false;
      }
    }
  };

  const renderRemainingDesignationCount = (count) => {
    var total = 2;
    var remaining = total - count;

    if (remaining < 0) {
      return 0;
    } else {
      return remaining;
    }
  };

  const renderEntities = () => {
    return _.map(entities, (entity, index) => {
      return (
        <div
          key={index}
          className={
            displayWarning(entity)
              ? "stat-cards__form__fieldset stat-cards__form__fieldset--warning"
              : "stat-cards__form__fieldset"
          }
        >
          <div className="stat-cards__form__fieldset__details stat-cards__form__fieldset__details--no-br">
            <div className="stat-cards__form__fieldset__details__title">{entity.entityName}</div>
            <div className="stat-cards__form__fieldset__details__subtitle">{entity.id340B}</div>
          </div>
          <div className="stat-cards__form__fieldset__values">
            <div className="stat-cards__form__fieldset__values__note">Available Updates</div>
            <div className="stat-cards__form__fieldset__values__subtitle align-right">
              {renderRemainingDesignationCount(entity.designationsCount[selectedManufacturer])} remaining
            </div>
          </div>
          {renderProhibitedNotice(entity)}
          {renderDesignation(entity)}
          {renderHinNotice(entity)}
        </div>
      );
    });
  };

  const renderManufacturers = () => {
    return _.map(manufacturers, (manufacturer, index) => {
      return (
        <div
          key={index}
          className={
            selectedManufacturer == manufacturer.name ? "stat-cards__tab stat-cards__tab--active" : "stat-cards__tab"
          }
          onClick={() => setSelectedManufacturer(manufacturer.name)}
        >
          {renderIndicator(manufacturer.name)}
          {manufacturer.label}
        </div>
      );
    });
  };

  return (
    <div className="stat-cards__form" style={editing ? { borderColor: "#24B668" } : null}>
      <img src={illustration} style={{ width: "100%", maxWidth: 120 }} />
      <div className="stat-cards__card__details__subtitle color--blue">One Contract Pharmacies</div>
      <div className="stat-cards__card__details__value">{`${assignedCount} of ${props.total} assigned`}</div>
      <div className="stat-cards__tab__container">{renderManufacturers()}</div>

      <div className="stat-cards__form__fieldset__container">
        {policies()}
        <div className="stat-cards__form__fieldset__details stat-cards__form__fieldset__details--no-br">
          {/* <div className="stat-cards__form__fieldset__details__title">
            1 of 2 designated
          </div> */}
        </div>
        {renderEntities()}
        <div className="stat-cards__form__fieldset__container__footer">{renderActionBtn()}</div>
      </div>
      <Notice details={notice} />
    </div>
  );
};

export default AssignOneContractPharmacies;
