import React, { useState, useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import inviteImage from "~/images/invite_or_find_illustration.png";
const CustomTextField = withStyles({
  root: {
    backgroundColor: "#ffffff",
    borderRadius: 8,
    "& label.Mui-focused": {
      color: "#3D71FF",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
      "& fieldset": {
        borderColor: "#DEE7EE",
      },
      "&:hover fieldset": {
        borderColor: "#DEE7EE",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#3D71FF",
      },
    },
  },
})(TextField);

const CreateCustomEntity = (props) => {
  const [displayModal, setDisplayModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = () => {
    setSubmitting(true);
  };

  const renderModal = () => {
    if (displayModal) {
      return (
        <div className="modal__background">
          <div className="modal__body modal__body--no-side-padding" style={{ paddingTop: 30, paddingBottom: 30 }}>
            <div className="modal__body__header-container">
              <div className="modal__body__header-text-container">
                <div className="modal__body__title">Create a Custom Entity</div>
                <div className="modal__body__subtitle" style={{ marginBottom: 30 }}>
                  Enter details below to create a custom covered entity for approved TPAs to register.
                </div>
              </div>
              <div className="modal__body__header-image">
                <img src={inviteImage} width={150} />
              </div>
            </div>
            <form
              method="POST"
              id="customEntityForm"
              action={`/organization/${props.organization}/super_admin/entities`}
              onSubmit={handleSubmit}
            >
              <div className="modal__body__form_container" style={{ paddingTop: 34, paddingBottom: 50 }}>
                <div className="register__form__dual-row register__form__dual-row--pad-right">
                  <input type="hidden" name="authenticity_token" value={props.authToken} />
                  <CustomTextField
                    label="Entity Name"
                    margin="normal"
                    variant="outlined"
                    id="entityName"
                    name="entity[name]"
                    autoFocus
                    fullWidth
                    required
                  />
                </div>
                <div className="register__form__dual-row register__form__dual-row--pad-left">
                  <CustomTextField
                    label="Address"
                    margin="normal"
                    variant="outlined"
                    id="entityAddress"
                    name="entity[address]"
                    fullWidth
                    required
                  />
                </div>
                <div className="register__form__dual-row register__form__dual-row--pad-right">
                  <CustomTextField
                    label="City"
                    margin="normal"
                    variant="outlined"
                    id="entityCity"
                    name="entity[city]"
                    fullWidth
                    required
                  />
                </div>
                <div className="register__form__dual-row register__form__dual-row--pad-left">
                  <CustomTextField
                    label="State"
                    margin="normal"
                    variant="outlined"
                    id="entityState"
                    name="entity[state]"
                    fullWidth
                    required
                  />
                </div>

                <div className="register__form__dual-row register__form__dual-row--pad-right">
                  <CustomTextField
                    label="Zip Code"
                    margin="normal"
                    variant="outlined"
                    id="entityZip"
                    name="entity[zip]"
                    fullWidth
                    required
                  />
                </div>
                <div className="register__form__dual-row register__form__dual-row--pad-left">
                  <CustomTextField
                    label="Validation Email"
                    margin="normal"
                    variant="outlined"
                    id="entityEmail"
                    name="entity[email]"
                    type="email"
                    fullWidth
                    required
                  />
                </div>
              </div>
              <div className="modal__body__footer">
                <div
                  className="btn btn--white btn--outline"
                  onClick={() => {
                    setDisplayModal(false);
                  }}
                >
                  Cancel and return
                </div>
                <button type="submit" className="btn" disabled={submitting} style={{ flexGrow: 1, marginLeft: 30 }}>
                  Create Entity
                </button>
              </div>
            </form>
          </div>
        </div>
      );
    }
  };

  return (
    <div>
      <div
        className="btn"
        onClick={() => {
          setDisplayModal(true);
        }}
      >
        Create Custom Entity
      </div>
      {renderModal()}
    </div>
  );
};

export default CreateCustomEntity;
