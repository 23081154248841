import React, { useRef, useState, useEffect } from "react";
import _ from "lodash";
import Validations from "../../processors/Validations";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getExpandedRowModel,
} from "@tanstack/react-table";
import { useVirtual } from "react-virtual";

const DataOutputOriginal = (props) => {
  const tableContainerRef = useRef();

  const validationsNeeded = (requiredColumn, value, row) => {
    var isValid = false;
    switch (requiredColumn) {
      case "date_of_service":
        isValid =
          Validations.isValidDateOfService(value) &&
          !Validations.isQuestionableDateOfService(value, row[props.fieldMapping["date_prescribed"]]);
        break;
      case "date_prescribed":
        isValid =
          Validations.isValidDatePrescribed(value) &&
          !Validations.isQuestionableDatePrescribed(value, row[props.fieldMapping["date_of_service"]]);
        break;
      case "rx_number":
        isValid = Validations.isValidRXNumber(value);
        break;
      case "ndc":
        isValid = Validations.isValidNDC(value);
        break;
      case "quantity":
        isValid = Validations.isValidQuantity(value) && !Validations.isQuestionableQuantity(value);
        break;
      case "wholesaler_invoice_number":
        isValid = Validations.isValidWholesalerInvoiceNumber(value);
        break;
      case "prescriber_id_qualifier":
        isValid = Validations.isValidPrescriberIDQualifier(value);
        break;
      case "prescriber_id":
        isValid = Validations.isValidPrescriberID(row[props.fieldMapping["prescriber_id_qualifier"]], value);
        break;
      case "service_provider_id_qualifier":
        isValid = Validations.isValidServiceProviderIDQualifier(value);
        break;
      case "service_provider_id":
        isValid = Validations.isValidServiceProviderID(row[props.fieldMapping["service_provider_id_qualifier"]], value);
        break;
      case "contracted_entity_id":
        isValid = Validations.isValidContractedEntityID(value);
        break;
      default:
        isValid = false;
    }

    return isValid;
  };

  const renderHeaders = () => {
    return (
      <thead>
        <tr className="virtual__table__sticky-header">
          {props.columns.map((header, i) => {
            return (
              <th
                key={i}
                className={
                  _.values(props.fieldMapping).includes(header)
                    ? "data-table__head__column data-table__head__column--mapped"
                    : "data-table__head__column"
                }
              >
                {header}
              </th>
            );
          })}
        </tr>
      </thead>
    );
  };

  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: props.data.length,
    overscan: 10,
  });

  const { virtualItems: virtualRows, totalSize } = rowVirtualizer;

  const renderVirtualRows = () => {
    return virtualRows.map((virtualRow, i) => {
      const row = props.data[virtualRow.index];

      return (
        <tr key={i} className="data-table__row">
          {_.map(row, (column, key) => {
            return (
              <td key={key} className={renderSelectedRows(row, key, i)}>
                {column}
              </td>
            );
          })}
        </tr>
      );
    });
  };

  // TODO: Investigate why this is needed?
  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
  const paddingBottom = virtualRows.length > 0 ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0) : 0;

  const renderSelectedRows = (row, key, index) => {
    if (!props.fieldMapping || !row[props.fieldMapping["ndc"]]) {
      return "data-table__row__item";
    }

    const ndcs = _.map(props.ndcList, (list) => list.code);

    if (ndcs.includes(row[props.fieldMapping["ndc"]]) && _.values(props.fieldMapping).includes(key)) {
      var schemaColumnName = _.findKey(props.fieldMapping, (val) => val == key);
      return validationsNeeded(schemaColumnName, row[key], row)
        ? "data-table__row__item data-table__row__item--mapped"
        : "data-table__row__item data-table__row__item--invalid";
    } else if (ndcs.includes(row[props.fieldMapping["ndc"]])) {
      return "data-table__row__item data-table__row__item--selected";
    } else {
      return "data-table__row__item";
    }
  };

  const renderOriginalData = () => {
    if (props.data.length) {
      return (
        <>
          <div ref={tableContainerRef} className="virtual__table__claims__container" style={{ overflowAnchor: "none" }}>
            <table>
              {renderHeaders()}
              <tbody>
                {paddingTop > 0 && (
                  <tr>
                    <td style={{ height: `${paddingTop}px` }} />
                  </tr>
                )}
                {renderVirtualRows()}
                {paddingBottom > 0 && (
                  <tr>
                    <td style={{ height: `${paddingBottom}px` }} />
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </>
      );
    }
  };

  return renderOriginalData();
};

export default DataOutputOriginal;
