import React, { useState } from "react";
import _ from "lodash";
import { ChevronRight } from "@material-ui/icons";
import AssignmentIcon from "@material-ui/icons/AssignmentOutlined";
import CommentIcon from "@material-ui/icons/CommentOutlined";

const Credit = (props) => {
  return (
    <div className="announcements">
      <div className="t--md t--500">Credits</div>
      <div className="announcements__container">
        <div className="tile p--8 flex justify-between t--left">
          <CommentIcon style={{ padding: 2, marginRight: 5 }} />
          <div className="flex flex-col item-start">
            <div className="t--md t--500">Disputable Claims</div>
            <div className="t--sm pt-1">1,625</div>
          </div>
          <div className="btn btn--sqr">
            <ChevronRight fontSize="small" />
          </div>
        </div>

        <div className="tile p--8 flex justify-between t--left">
          <AssignmentIcon style={{ padding: 2, marginRight: 5 }} />
          <div className="flex flex-col item-start">
            <div className="t--md t--500">Address Claim Issues</div>
            <div className="t--sm pt-1">Click here to learn how</div>
          </div>
          <div className="btn btn--sqr">
            <ChevronRight fontSize="small" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Credit;
