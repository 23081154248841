import React, { useState, useRef, useEffect, Fragment, useMemo } from "react";
import { ExpandMore, GetApp, ChevronRight, UnfoldMore } from "@material-ui/icons";
import _ from "lodash";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getExpandedRowModel,
  getSortedRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import { useVirtual } from "react-virtual";
import TableLoadingSkeleton from "../TableLoadingSkeleton";
import { TableRowSubComponent } from "./TableRowSubComponent";
import { IssuesToolTip } from "../../IssuesTooltip";

export default function ClaimsTable(props) {
  const tableContainerRef = useRef();

  const [sorting, setSorting] = useState([]);

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor("expander", {
      enableSorting: false,
      header: () => null,
      cell: ({ row }) => {
        return row.getCanExpand() ? (
          <div
            className="btn btn--outline btn--align-end btn--white"
            {...{
              onClick: row.getToggleExpandedHandler(),
            }}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: 0,
              margin: 0,
              width: 30,
              height: 30,
            }}
          >
            <i
              className="solid solid-budicon-chevron-bottom"
              style={{
                fontWeight: 800,
                marginRight: 0,
                transform: row.getIsExpanded() ? `rotate(180deg)` : 0,
              }}
            />
          </div>
        ) : (
          ""
        );
      },
    }),

    columnHelper.accessor((row) => row.claim_id, {
      header: "ESP ID",
      enableSorting: false,
      cell: ({ renderValue }) => <div className="t--sm">{renderValue()}</div>,
    }),
    columnHelper.accessor((row) => row.id_340b, {
      header: "340B ID",
      enableSorting: false,
      cell: ({ renderValue }) => <div className="t--sm">{renderValue()}</div>,
    }),
    columnHelper.accessor("claim_number", {
      header: "Claim Number",
      enableSorting: false,
      cell: ({ renderValue }) => <div className="t--sm">{_.toUpper(renderValue())}</div>,
    }),
    columnHelper.accessor((row) => row.service_provider_id, {
      header: "Pharmacy",
      enableSorting: false,
      cell: ({ renderValue }) => <div className="t--sm">{renderValue()}</div>,
    }),
    columnHelper.accessor("date_of_service", {
      header: "DOS",
      enableSorting: false,
      cell: ({ renderValue }) => <div className="t--sm">{_.toUpper(renderValue())}</div>,
    }),
    columnHelper.accessor((row) => row.ndc, {
      header: "Ndc",
      enableSorting: false,
      cell: ({ row, getValue }) => {
        return (
          <div className="flex flex-col item-start">
            <div className="t--sm t--500 t--left">{row.original.product_name}</div>
            <div className="t--sm">{getValue()}</div>
          </div>
        );
      },
    }),
    columnHelper.accessor((row) => row.quantity, {
      enableSorting: false,
      header: "Qty",
      cell: ({ renderValue }) => <div className="t--sm">{renderValue()}</div>,
    }),
    columnHelper.accessor((row) => row.quality, {
      header: "Result",
      enableSorting: false,
      cell: ({ getValue }) => {
        return (
          <div className="badge">
            <div
              className={`badge__indicator ${
                getValue().toLowerCase() === "conforming" ? "badge__indicator--green" : "badge__indicator--red"
              }`}
            />
            {getValue()}
          </div>
        );
      },
    }),
    columnHelper.accessor((row) => row.status, {
      header: "Status",
      enableSorting: false,
      cell: ({ row, getValue }) => {
        return (
          <div className="badge">
            <div className={`badge__indicator badge__indicator--${getIndicatorName(getValue())}`} />
            {getValue()}
          </div>
        );
      },
    }),
    // columnHelper.accessor((row) => row.payment_details, {
    //   header: "Credit",
    //   cell: ({ getValue }) => {
    //     return <div className="t--sm">{getValue() ? getValue().amount : ""}</div>;
    //   },
    // }),
    columnHelper.accessor((row) => row.issues, {
      header: "Issues",
      enableSorting: false,
      cell: ({ ...props }) => {
        if (props.row.original.issues?.length > 0) {
          return <IssuesToolTip {...props} />;
        }
        return "--";
      },
    }),
    columnHelper.accessor((row) => row.date_submitted, {
      header: "Submitted",
      enableSorting: false,
      cell: ({ renderValue, row }) => (
        <div className="table__text__stack">
          <div className="t--sm t--500">{row.original.submitted_by}</div>
          <div className="t--sm t--subtitle">{renderValue()}</div>
        </div>
      ),
    }),
  ];

  const getIndicatorName = (name) => {
    var status = {
      paid: "green",
      expired: "red",
      "attempts exceeded": "red",
    };

    if (name.includes("days to conform")) {
      return "orange";
    }

    return status[name];
  };

  const claims = useMemo(() => props.claims, [props.claims]);

  const table = useReactTable({
    data: claims,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getExpandedRowModel: getExpandedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getRowCanExpand: () => true,
    initialState: { pagination: { pageSize: 50 } },
    getPaginationRowModel: getPaginationRowModel(),
  });

  const renderPaginationControls = () => {
    return (
      <div className="flex__container" style={{ justifyContent: "flex-end", marginTop: 10 }}>
        <button className="btn btn--small" onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}>
          Prev
        </button>
        <button
          className="btn btn--small"
          style={{ marginLeft: 10 }}
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          Next
        </button>
      </div>
    );
  };

  const renderRows = () => {
    if (props.isLoading) return <TableLoadingSkeleton rows={20} columns={12} />;

    return table.getRowModel().rows.map((row) => {
      return (
        <Fragment key={row.id}>
          <tr className="sm">
            {row.getVisibleCells().map((cell) => (
              <td key={cell.id} className="t--md">
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
          {row.getIsExpanded() && (
            <tr>
              <td colSpan={row.getVisibleCells().length}>
                <TableRowSubComponent row={row} />
              </td>
            </tr>
          )}
        </Fragment>
      );
    });
  };

  return (
    <>
      <div ref={tableContainerRef}>
        <table className="primary">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="sm">
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {header.isPlaceholder ? null : header.id === "Claim Status" ? (
                      <span className="flex flex-col">
                        <span>Claim</span>
                        <span>Status</span>
                      </span>
                    ) : header.id === "Serv Prov ID" ? (
                      <span className="flex flex-col">
                        <span>Serv</span>
                        <span>Prov ID</span>
                      </span>
                    ) : (
                      flexRender(header.column.columnDef.header, header.getContext())
                    )}
                    {header.column.getCanSort() && (
                      <span
                        style={{
                          fontSize: 12,
                          display: "inline-flex",
                          verticalAlign: "top",
                        }}
                        onClick={() => header.column.toggleSorting()}
                      >
                        <UnfoldMore fontSize="inherit" color="inherit" />
                      </span>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>{renderRows()}</tbody>
        </table>

        {renderPaginationControls()}
      </div>
    </>
  );
}
