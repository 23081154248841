import React, { useState, useEffect, Fragment } from "react";
import { Pie } from "@nivo/pie";
import { ExpandMore, GetApp, ChevronRight } from "@material-ui/icons";
import ChipSelector from "../Draw/ChipSelector";
import _, { set } from "lodash";

export default function SubmissionSummaryChart(props) {
  const [selected, setSelected] = React.useState({value: "conforming", label: "Conforming"});
  const [selectedManufacturer, setSelectedManufacturer] = React.useState(null)
  const [selectedPharmacy, setSelectedPharmacy] = React.useState(null)
  const [selectedNdc, setSelectedNdc] = React.useState(null)
  const [currentValue, setCurrentValue] = React.useState(null);
  const [filterQuery, setFilterQuery] = React.useState({});
  const [conformingRows, setConformingRows] = React.useState(props.row.original.conforming.details);
  const [nonConformingRows, setNonConformingRows] = React.useState(props.row.original.non_conforming.details);
  const [conformingCount, setConformingCount] = React.useState(0);
  const [nonConformingCount, setNonConformingCount] = React.useState(0);

  const items = [{value: "conforming", label: "Conforming"}, {value:"non-conforming", label:"Non-Conforming"}];
  const colors = ["#3246D3", "#FF681F"];

  useEffect(() => {
    setConformingCount(props.row.original.conforming.count);
    setNonConformingCount(props.row.original.non_conforming.count);
  }, []);

  const handleSelectManufacturer = (item) => {
    if (item == selectedManufacturer) {
      setSelectedManufacturer(null);
      handleFilter("manufacturer", null);
    }

    setSelectedManufacturer(item);
    handleFilter("manufacturer", item);
  };

  const handleSelectPharmacy = (item) => {
    if (item == selectedPharmacy) {
      setSelectedPharmacy(null);
      handleFilter("pharmacy", null);
    }

    setSelectedPharmacy(item);
    handleFilter("pharmacy", item);
  };

  const handleSelectNdc = (item) => {
    if (item == selectedNdc) {
      setSelectedNdc(null);
      handleFilter("ndc", null);
    }

    setSelectedNdc(item);
    handleFilter("ndc", item);
  };

  const handleFilter = (name, value) => {
    setFilterQuery({ ...filterQuery, [name]: value });
  };

  const getFilters = () => {
    var filters = {};

    _.each(filterQuery, (value, key) => {
      if (value && value.length) _.assign(filters, { [key]: value });
    });

    return filters;
  };

  useEffect(() => {
    setConformingRows(filterRows(props.row.original.conforming.details));
    setNonConformingRows(filterRows(props.row.original.non_conforming.details));
  }, [filterQuery]);

  const filterRows = (rows) => {
    return _.filter(rows, getFilters());
  };

  const getIndicatorName = (value) => {
    switch (value) {
      case "conforming":
        return "blue";
      case "non-conforming":
        return "orange";
    }
    return "blue"
  }


  const renderTable = () => {
    switch (selected.value) {
      case "non-conforming":
        return renderTableItems(
          nonConformingRows
        );
      case "conforming":
        return renderTableItems(
          conformingRows
        );
    }
  };

  const renderTableItems = (items) => {
    return items.map(item => {
      return (
        <tr className="sm">
          <td>{item.manufacturer}</td>
          <td>{item.pharmacy}</td>
          <td>{item.ndc}</td>
          <td>{item.count}</td>
        </tr>
      )
    });
  };



  const renderChevron = (val) => {
    if (selected.value == val) {
      return <ChevronRight style={{ fontSize: 20 }} />;
    }
  };

  const renderItem = () => {
    return items.map((item) => {
      return (
        <div
          className={
            item.value == selected.value
              ? "chart__component__submission__summary__info__item chart__component__submission__summary__info__item--active"
              : "chart__component__submission__summary__info__item"
          }
          onClick={() => setSelected(item)}
        >
          <div
            className={`chart__component__submission__summary__info__item__indicator chart__component__submission__summary__info__item__indicator--${getIndicatorName(
              item.value
            )}`}
          ></div>
          <div className="chart__component__submission__summary__info__item__title">{`${item.label}`}</div>
          <div className="chart__component__submission__summary__info__item__icon">
            {renderChevron(item.value)}
          </div>
        </div>
      );
    });
  };

  const CenteredMetric = ({ dataWithArc, centerX, centerY }) => {
    return (
      <>
          <text
            x={centerX}
            y={centerY}
            textAnchor="middle"
            dominantBaseline="central"
            className="t--500 t--lg"
          >
            {currentValue ? Math.round(currentValue * 100 / (props.row.original.conforming.count + props.row.original.non_conforming.count))
                         : selected.value == "conforming" ? Math.round(props.row.original.conforming.count * 100 / (props.row.original.conforming.count + props.row.original.non_conforming.count))
                                                          : Math.round(props.row.original.non_conforming.count * 100 / (props.row.original.conforming.count + props.row.original.non_conforming.count))}%
          </text>
      </>
    );
  };

  const renderPieChart = () => {
    return (
      <Pie
        onMouseEnter={(node) => {
          setCurrentValue(node.value);
        }}
        onMouseLeave={() => setCurrentValue(null)}
        data={[
          {
            label: "Conforming",
            value: conformingCount,
            color: colors[0],
            id: "Conforming",
          },
          {
            label: "Non-Conforming",
            value: nonConformingCount,
            color: colors[1],
            id: "Non Conforming",
          },
        ]}
        theme={{tooltip: { fontSize: 12 }}}
        startAngle={-180}
        width={200}
        height={150}
        margin={{ top: 5, bottom: 5 }}
        innerRadius={0.5}
        activeInnerRadiusOffset={4}
        activeOuterRadiusOffset={4}
        enableArcLabels={false}
        enableArcLinkLabels={false}
        colors={colors}
        layers={["arcs", "legends", CenteredMetric]}
      />
    );
  };

  return (
    <div className="row">
      <div className="col-3 d-none d-xl-block">
        <div className="row" style={{ height: "100%" }}>
          <div
            className="col-12 d-flex flex-column justify-content-between"
            style={{ paddingRight: 0 }}
          >
            

            <div className="card__filter" style={{ marginTop: 10 }}>
              <div className="card__filter__title card__filter__title--mb-sm t--upcase t--sm">
                Manufacturers
              </div>
              <div className="chip__well chip__well__max-height">
                <ChipSelector
                  items={props.row.original.manufacturers ? props.row.original.manufacturers : []}
                  selected={selectedManufacturer}
                  setSelected={handleSelectManufacturer}
                  required={true}
                />
              </div>
            </div>
            <div className="card__filter" style={{ marginTop: 10 }}>
              <div className="card__filter__title card__filter__title--mb-sm t--upcase t--sm">
                Pharmacies
              </div>
              <div className="chip__well chip__well__max-height">
                <ChipSelector
                  items={props.row.original.pharmacies ? props.row.original.pharmacies : []}
                  selected={selectedPharmacy}
                  setSelected={handleSelectPharmacy}
                  required={true}
                />
              </div>
            </div>
            <div className="card__filter" style={{ marginTop: 10 }}>
              <div className="card__filter__title card__filter__title--mb-sm t--upcase t--sm">
                NDCs
              </div>
              <div className="chip__well chip__well__max-height">
                <ChipSelector
                  items={props.row.original.ndcs ? props.row.original.ndcs : []}
                  selected={selectedNdc}
                  setSelected={handleSelectNdc}
                  required={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-xl-9">
        <div className="chart__component__submission__summary__container chart__component__submission__summary__container__credit">
          <div className="chart__component__submission__summary__info">
            <div className="chart__component__submission__summary__info__header">
              Submission Summary
            </div>
            <div className="chart__component__submission__summary__info__chart">
              {renderPieChart()}
            </div>
            <div className="chart__component__submission__summary__info__item__container">
              {renderItem()}
            </div>
          </div>
          <div className="chart__component__submission__summary__details">
            <table className="secondary">
              <thead>
                <tr className="sm">
                  <th>Mfr</th>
                  <th>Pharmacy</th>
                  <th>NDC</th>
                  <th>Claim Count</th>
                </tr>
              </thead>
              <tbody>{renderTable()}</tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
