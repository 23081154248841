import React, { useState, useEffect, Fragment } from "react";
import { ExpandMore } from "@material-ui/icons";
import SubmissionSummaryChart from "./SubmissionSummaryChart";
import _ from "lodash";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getExpandedRowModel,
} from "@tanstack/react-table";
import { useOnOutsideClick } from "../RebateClaimsData/RebateClaimsTable";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

const columnHelper = createColumnHelper();

const columns = [
  columnHelper.accessor("download", {
    id: "download",
    header: "",
    cell: ({ row, getValue }) => {
      return (
        <>
          <div
            id="dropdown"
            className="btn btn--sqr"
            style={{ marginRight: 18, verticalAlign: "middle" }}
            onClick={() => row.toggleExpanded()}
          >
            <ExpandMore fontSize="small" />
          </div>
        </>
      );
    },
  }),
  columnHelper.accessor("file_name", {
    header: "Submissions",
    cell: ({ getValue }) => {
      return (
        <>
          <span className="t--500">{getValue()}</span>
        </>
      );
    },
  }),
  columnHelper.accessor((row) => row.status, {
    header: "Status",
    cell: ({ row, getValue }) => {
      return (
        <div className="badge">
          <div className="badge__indicator" />
          {getValue()}
        </div>
      );
    },
  }),
  columnHelper.accessor("row_count", {
    header: "Claims",
    cell: (info) => <div className="t--500">{info.renderValue()}</div>,
  }),
  columnHelper.accessor("conforming_percent", {
    header: "Conforming",
    cell: ({ row, getValue }) => {
      return (
        <div className="progress_bar__wrapper">
          <div className="progress_bar__container progress_bar__container--t-fixed">
            <div
              className="progress_bar__fill"
              style={{ width: `${getValue()}%` }}
            />
          </div>
          <div className="progress_bar__value">{`${getValue()}%`}</div>
        </div>
      );
    },
  }),
  columnHelper.accessor("date", {
    header: "Date",
  }),
  columnHelper.accessor("links", {
    id: "links",
    header: "",
    cell: ({ row, getValue }) => {
      return <ViewMore row={row} value={getValue()} />;
    },
  }),
];

const ViewMore = ({ value, row }) => {
  const [displayViewMore, setDisplayViewMore] = useState(false);
  const { containerRef } = useOnOutsideClick(() => setDisplayViewMore(false));

  return (
    <>
      <button
        className="btn btn--sqr"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={() => setDisplayViewMore(true)}
      >
        <MoreHorizIcon style={{ cursor: "pointer", fontSize: 14 }} />
      </button>

      {displayViewMore && (
        <div
          className="header__notifications__container animated delay-1 fadeInDown--small"
          style={{ left: "-100px", height: "auto", width: "auto" }}
          ref={containerRef}
        >
          <div className="header__notifications__container__item">
            <div className="header__notifications__container__item__content">
              <div className="flex flex-col item-start">
                <a className="t--md t--hover t--full" href={value.download}>
                  Download Submission
                </a>
                <a className="t--md t--hover t--full" href={value.report}>
                  View Report
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default function ClaimsTable(props) {
  const [data, setData] = React.useState(() => props.submissions);
  const parentRef = React.useRef(null);

  const table = useReactTable({
    data,
    columns,
    getExpandedRowModel: getExpandedRowModel(),
    getCoreRowModel: getCoreRowModel(),
  });

  const renderSubComponent = (row) => {
    return <SubmissionSummaryChart row={row} />;
  };

  const renderRows = () => {
    return table.getRowModel().rows.map((row) => {
      return (
        <Fragment key={row.id}>
          <tr className="sm sm--hover" style={{ cursor: "pointer" }}>
            {row.getVisibleCells().map((cell) => (
              <td
                key={cell.id}
                className="t--md"
                onClick={(e) => {
                  if (
                    cell.column.id === "download" ||
                    cell.column.id === "links"
                  ) {
                    return;
                  } else {
                    window.location.href = row
                      .getVisibleCells()
                      [row.getVisibleCells().length - 1].getValue().report;
                  }
                }}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
          {row.getIsExpanded() && (
            <tr>
              <td colSpan={row.getVisibleCells().length}>
                {renderSubComponent(row)}
              </td>
            </tr>
          )}
        </Fragment>
      );
    });
  };

  return (
    <div>
      <table className="primary">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className="sm">
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>{renderRows()}</tbody>
      </table>
    </div>
  );
}
