import React, { useState, useRef, useEffect } from "react";
import _ from "lodash";
import NovoNordisk from "~/images/NovoNordisk.png";

const AgreementNovoNordisk = (props) => {
  const renderAgreement = () => {
    if (props.selectedManufacturer == "novo_nordisk") {
      return (
        <div>
          <div className="logo__container">
            <div className="logo">
              <img src={NovoNordisk} />
            </div>
            <div className="logo__info">
              <div className="logo__info__title">Novo Nordisk</div>
              <div className="logo__info__subtitle">Wholly Owned Contract Pharmacy Agreement</div>
            </div>
          </div>
          <p>
            A hospital covered entity<sup>1</sup> may seek additional wholly owned contract pharmacy<sup>2</sup>{" "}
            designations beyond the permitted designations (i.e., one retail and one specialty pharmacy) if the covered
            entity submits contract pharmacy claims level data via the ESP platform within 45 days of the date of
            dispense. Wholly owned contract pharmacies designated by covered entities must be registered and active in
            HRSA's 340B OPAIS database. The wholly owned contract pharmacy designation shall apply to the parent and all
            related child sites collectively.
          </p>
          <p>
            Covered entities must complete the wholly owned contract pharmacy exemption form on the 340B ESP website to
            apply for this additional wholly owned exemption. Proof of ownership documents are required in order to
            submit your request. Examples of proof of ownership documents include:
          </p>
          <ul>
            <li>Certificate of liability insurance</li>
            <li>State business licenses</li>
          </ul>
          <p>
            Novo Nordisk will process this form within fourteen (14) business days of receipt of the completed form.
            Failure to populate all required fields will delay the processing of the Contract Pharmacy Selection Form
            and may require resubmission.
          </p>
          <p>
            The covered entity acknowledges that the covered entity and contract pharmacy identified on this form have
            the same organizational and/or corporate ownership, and that the covered entity either wholly owns or is
            owned by the corporation/organization as the identified contract pharmacy and qualifies for the exception as
            described in this selection form.<sup>3</sup> If there are any changes to the ownership structure of any of
            the identified contract pharmacies, the covered entity agrees to notify Novo Nordisk at support@340besp.com
            within two (2) business days of such change.
          </p>
          <p style={{ fontSize: 12 }}>
            <sup>1</sup>Children's Hospitals (PED), Critical Access Hospitals (CAH), Disproportionate Share Hospitals
            (DSH), Free Standing Cancer Hospitals (CAN), Rural Referral Centers (RRC), and Sole Community Hospitals
            (SCH).
          </p>
          <p style={{ fontSize: 12 }}>
            <sup>2</sup>Novo Nordisk reserves the right to request proof of ownership and to terminate contract pharmacy
            selection and/or deny a designation request for a wholly owned contract pharmacy if the information is not
            provided within three (3) business days.
          </p>
          <p style={{ fontSize: 12 }}>
            <sup>3</sup>Novo Nordisk reserves the right to deny and/or terminate any contract pharmacy
            designation/request that is deemed to be non-compliant with the policy outlined and declarations attested to
            within this application.
          </p>
          <div className="register__divider" />
        </div>
      );
    } else {
      return null;
    }
  };

  return renderAgreement();
};

export default AgreementNovoNordisk;
