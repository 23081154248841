import React, { useRef, useEffect, useState } from "react";
import AccountsTable from "./AccountsTable";
import Draw from "./AccountsDraw";
import AccountsModal from "./AccountsModal";
import ProductBalanceHistoryChart from "./Charts/ProductBalanceHistoryChart";
import _ from "lodash";
import Http from "./http";
import { numberWithCommas } from "../utils/NumberFormatter";
import Notice from "../Notice";
import debounce from "debounce-promise";
import moment from "moment";
import { Pie } from "@nivo/pie";

export default function AccountsIndex(props) {
  const [selectedAccount, setSelectedAccount] = useState();
  const [displayModal, setDisplayModal] = useState(false);
  const [entity, setEntity] = useState();
  const [statusCounts, setStatusCounts] = useState({ pos: 0, neg: 0 });
  const [accounts, setAccounts] = useState([]);
  const [productHistory, setProductHistory] = useState([]);
  const [notice, setNotice] = React.useState({
    kind: "error",
    open: false,
    message: "",
  });
  const [filterQuery, setFilterQuery] = useState({ status: null });
  const [isAccountsTableLoading, setIsAccountsTableLoading] = useState(false);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const entityID = urlParams.get("id");

  const [searchQuery, setSearchQuery] = useState({
    product_name: null,
    status: null,
    pid: null,
    grouping: "product_name",
    enable_exclusions: null,
    date_range: { start: "2020-10-01", end: moment().format("YYYY-MM-DD") },
    id_340b: entityID ? entityID : props.entities[0].value,
    manufacturer: props.manufacturers[0].value,
    pharmacy_type: "contract",
  });

  const isMounted = useRef(false);

  useEffect(() => {
    fetch();
  }, [searchQuery]);

  useEffect(() => {
    if ((isMounted.current && !accounts) || (isMounted.current && accounts.length == 0)) {
      setNotice({
        kind: "success",
        open: true,
        message: "The selected entity has no Accounts for the current filters",
      });
    }

    isMounted.current = true;
  }, [accounts]);

  const fetch = async () => {
    setIsAccountsTableLoading(true);
    var path = searchQuery.pharmacy_type === "inhouse" ? props.inhouse_query_path : props.query_path;
    var monthly_path =
      searchQuery.pharmacy_type === "inhouse" ? props.inhouse_monthly_balance_path : props.monthly_balance_path;

    await Http.fetchAccounts(path, searchQuery).then((res) => {
      setAccounts(res.accounts);
      setEntity(res.entity);
      setStatusCounts({ pos: res.pos, neg: res.neg });
    });
    Http.fetchBalanceByMonth(monthly_path, searchQuery).then((res) => setProductHistory(res));
    setIsAccountsTableLoading(false);
  };

  const debouncedFetchHandler = debounce(fetch, 300);

  const handleFilter = (name, value) => {
    setFilterQuery({ ...filterQuery, [name]: value });
  };

  const handleSearch = (name, value) => {
    if (name == "details") {
      setSearchQuery({
        ...searchQuery,
        manufacturer: value.manufacturer,
        product_name: value.product_name,
        pharmacy_type: value.pharmacy_type,
      });
    } else {
      setSearchQuery({ ...searchQuery, [name]: value });
    }
  };

  const getFilters = () => {
    var filters = {};

    _.each(filterQuery, (value, key) => {
      if (value && value.length) _.assign(filters, { [key]: value });
    });

    return filters;
  };

  const filteredAccounts = () => {
    if (!accounts) {
      return [];
    }

    var filtered_accounts = [];

    _.each(accounts, (acc) => {
      var results = _.filter(acc.accounts, getFilters());

      if (results) {
        filtered_accounts.push({ ...acc, accounts: results });
      }
    });

    return filtered_accounts;
  };

  const getBalance = () => {
    if (productHistory.data && productHistory.data[0]) {
      return numberWithCommas(productHistory.data[0].data[productHistory.data[0].data.length - 1].y);
    } else {
      return 0;
    }
  };

  return (
    <div style={{ display: "flex" }}>
      <div className="content__container">
        <div className="page-details__container">
          <div className="page-details__container__info">
            <div className="page-details__title">Submission Balances</div>
          </div>
        </div>
        <div className="flex__container flex__container--gutter">
          <div className="tile">
            <div className="tile__header">
              <div className="tile__header__details">
                <div className="tile__header__details__title">Account Details</div>
                <div className="tile__header__details__subtitle">
                  Updated - {moment().startOf("month").format("YYYY-MM-DD")}
                </div>
              </div>
            </div>
            <div className="flex__container flex__container--align-center">
              <div className="t--xl">{statusCounts.neg}</div>
              <div className="t--sm ml-2">Negative Balance Accounts</div>
            </div>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: 180 }}>
              <Pie
                data={
                  accounts && accounts.length
                    ? [
                        {
                          id: "positive",
                          label: "Positive",
                          value: statusCounts.pos,
                        },
                        {
                          id: "negative",
                          label: "Negative",
                          value: statusCounts.neg,
                        },
                      ]
                    : []
                }
                width={200}
                height={150}
                margin={{ top: 5, right: 30, bottom: 30, left: 30 }}
                innerRadius={0.8}
                activeInnerRadiusOffset={4}
                activeOuterRadiusOffset={4}
                enableArcLabels={false}
                enableArcLinkLabels={false}
                colors={["#3246D3", "#FF681F"]}
                legends={[
                  {
                    anchor: "bottom",
                    direction: "row",
                    justify: false,
                    translateX: 0,
                    translateY: 25,
                    itemWidth: 70,
                    itemHeight: 10,
                    symbolSpacing: 4,
                    symbolSize: 6,
                    symbolShape: "circle",
                  },
                ]}
              />
            </div>
          </div>
          <div className="tile" style={{ flexGrow: 1 }}>
            <div className="tile__header">
              <div className="tile__header__details">
                <div className="tile__header__details__title">Unit Balance History</div>
                <div className="tile__header__details__subtitle">
                  Updated - {moment().startOf("month").format("YYYY-MM-DD")}
                </div>
              </div>
            </div>
            <div className="flex__container flex__container--align-center">
              <div className="t--xl">{getBalance()}</div>
              <div className="t--sm ml-2">Balance</div>
            </div>
            <div style={{ height: 170, marginTop: 10 }}>
              <ProductBalanceHistoryChart data={productHistory.data} />
            </div>
          </div>
        </div>
        <div style={{ marginTop: 20 }}>
          <AccountsTable
            accounts={filteredAccounts()}
            setDisplayModal={setDisplayModal}
            setSelectedAccount={setSelectedAccount}
            isLoading={isAccountsTableLoading}
            reviews_path={props.reviews_path}
            isContractPharmacy={searchQuery.pharmacy_type === "contract"}
          />
        </div>
      </div>
      <div className="draw__container draw__container--scroll">
        <Draw
          accounts={accounts}
          products={props.products}
          entities={props.entities}
          manufacturers={props.manufacturers}
          setSearchQuery={setSearchQuery}
          searchQuery={searchQuery}
          handleSearch={handleSearch}
          handleFilter={handleFilter}
          downloadPath={`${props.download_path}?${new URLSearchParams(
            _.omit(searchQuery, "date_range")
          ).toString()}&${new URLSearchParams(searchQuery.date_range).toString()}`}
          inhouseDownloadPath={`${props.inhouse_download_path}?${new URLSearchParams(
            _.omit(searchQuery, "date_range")
          ).toString()}&${new URLSearchParams(searchQuery.date_range).toString()}`}
        />
      </div>
      {displayModal && (
        <AccountsModal
          displayModal={displayModal}
          setDisplayModal={setDisplayModal}
          entity={entity}
          selectedAccount={selectedAccount}
          searchQuery={searchQuery}
          ledgerPath={props.ledger_path}
          inhouseLedgerPath={props.inhouse_ledger_path}
          statusHistoryPath={props.status_history_path}
          balanceHistoryPath={props.monthly_balance_path}
          inhouseBalanceHistoryPath={props.inhouse_monthly_balance_path}
          submissionsPath={props.submissions_path}
          submissionDetailsPath={props.submission_details_path}
        />
      )}
      <Notice details={notice} />
    </div>
  );
}
