import React, { useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import inviteImage from "~/images/invite_or_find_illustration.png";
import _ from "lodash";
import Notice from "../Notice";

const CustomTextField = withStyles({
  root: {
    backgroundColor: "#ffffff",
    borderRadius: 8,
    "& label.Mui-focused": {
      color: "#3D71FF",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
      "& fieldset": {
        borderColor: "#DEE7EE",
      },
      "&:hover fieldset": {
        borderColor: "#DEE7EE",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#3D71FF",
      },
    },
  },
})(TextField);

const UserInvitesForm = (props) => {
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [notice, setNotice] = React.useState({
    kind: "error",
    open: false,
    message: "",
  });
  const [invites, setInvites] = React.useState([]);

  const handleInvites = () => {
    setInvites([...invites, { email: email }]);
    setNotice({
      kind: "success",
      open: true,
      message: "User invite was successfully sent",
    });

    setFirstName("");
    setLastName("");
    setEmail("");
  };

  const sendInvite = () => {
    fetch(props.submitPath, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
      },
      method: "POST",
      body: JSON.stringify({
        user: {
          first_name: firstName,
          last_name: lastName,
          email: email.toLocaleLowerCase(),
          role: "user",
        },
      }),
    })
      .then((res) => {
        if (res.status >= 200 && res.status <= 299) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((data) => {
        handleInvites();
      })
      .catch((response) => {
        response.json().then((res) => {
          setNotice({
            kind: "error",
            open: true,
            message: res.error,
          });
        });
      });
  };

  const listInvites = () => {
    return _.map(_.reverse(invites), (invite) => (
      <div key={email} className={"onboarding__content__form__scrollbody__item"}>
        <div className={"onboarding__content__form__scrollbody__item__info"}>
          <div className={"onboarding__content__form__scrollbody__item__info__title"}>{invite.email}</div>
        </div>
      </div>
    ));
  };

  return (
    <div>
      <Notice details={notice} />
      <div className="onboarding__content__form">
        <div className="onboarding__content__form__image">
          <img src={inviteImage} />
        </div>
        <div className="onboarding__content__form__title">Invite other users</div>
        <p>Enter details below to send a registration invitation to other members of your team.</p>
        <div className="register__form__dual-row register__form__dual-row--pad-right">
          <CustomTextField
            label="First Name"
            margin="normal"
            variant="outlined"
            id="firstName"
            name="user[first_name]"
            value={firstName}
            onChange={(event) => {
              setFirstName(event.target.value);
            }}
            autoFocus
            fullWidth
            required
          />
        </div>
        <div className="register__form__dual-row register__form__dual-row--pad-left">
          <CustomTextField
            label="Last Name"
            margin="normal"
            variant="outlined"
            id="lastName"
            name="user[last_name]"
            value={lastName}
            onChange={(event) => {
              setLastName(event.target.value);
            }}
            fullWidth
            required
          />
        </div>
        <CustomTextField
          label="Work Email Address"
          margin="normal"
          variant="outlined"
          id="email"
          name="user[email]"
          value={email}
          onChange={(event) => {
            setEmail(event.target.value);
          }}
          fullWidth
          required
        />
        <div className="onboarding__content__form__actionbar onboarding__content__form__actionbar--margin-sm onboarding__content__form__actionbar--space-between">
          <div className="onboarding__content__form__actionbar__note">
            Invitation emails are only valid for 7 days but you can always invite new users and manage your account
            online.
          </div>
          <div className="btn btn--ib btn--wide" onClick={sendInvite}>
            {invites.length > 0 ? "Send Another Invite" : "Send Invite"}
          </div>
        </div>
        <div className="onboarding__content__form__scrollbody">
          <div className={"onboarding__content__form__scrollbody__header"}>
            <div className={"onboarding__content__form__scrollbody__header__info"}>Users Invited</div>
          </div>
          {listInvites()}
        </div>
        <div className="register__form__actions">
          <a href={props.path}>
            <div className="btn btn--outline btn--white">Skip and continue</div>
          </a>
          <a href={props.path} style={{ display: "flex", flexGrow: 1 }}>
            <button className="btn" style={{ flexGrow: 1, marginLeft: 30 }} type="submit">
              Done!
            </button>
          </a>
        </div>
      </div>
      <div className="onboarding__content__footer">
        <div className="onboarding__content__footer__note">
          <a href="/logout">Cancel and return home</a>
        </div>
      </div>
    </div>
  );
};

export default UserInvitesForm;
