export const claimSchema = {
    plan_id_qualifier: null,
    plan_id_code: null,
    plan_name: null,
    service_provider_id_qualifier: null,
    service_provider_id: null,
    product_service_id_qualifier: null,
    product_service_id: null,
    billed_hcpcs_quantity: null,
    billed_hcpcs_unit_of_measure: null,
    prescription_type: null,
    date_of_service: null,
    prescriber_id_qualifier: null,
    prescriber_id: null,
    claim_number: null,
    hcpcs_code: null,
    hcpcs_code_modifier_1: null,
    hcpcs_code_modifier_2: null,
  };
  
  export const claim = {
    original: claimSchema,
    errors: false,
    columns_with_errors: {},
    data: claimSchema,
  };
  
  export const vanityColumnSchema = {
    plan_id_qualifier: "Plan ID Qualifier",
    plan_id_code: "Plan ID Code",
    plan_name: "Plan Name",
    service_provider_id_qualifier: "Service Provider ID Qualifier",
    service_provider_id: "Service Provider ID",
    product_service_id_qualifier: "Product Service ID Qualifier",
    product_service_id: "Product Service ID",
    billed_hcpcs_quantity: "Billed HCPCS Quantity",
    billed_hcpcs_unit_of_measure: "Billed HCPCS Unit of Measure",
    prescription_type: "Prescription Type",
    date_of_service: "Date of Service",
    prescriber_id_qualifier: "Prescriber ID Qualifier",
    prescriber_id: "Prescriber ID",
    claim_number: "Claim Number",
    hcpcs_code: "HCPCS Code",
    hcpcs_code_modifier_1: "HCPCS Code Modifier 1",
    hcpcs_code_modifier_2: "HCPCS Code Modifier 2",
  };
  