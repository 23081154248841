import React, { useRef, useEffect } from "react";
import _ from "lodash";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
  getExpandedRowModel,
  flexRender,
} from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual";

import TableCell from "./TableCell";
import HeaderCell from "./HeaderCell";
import EmptyFilters from "./empty_filters";

const SubmissionTable = (props) => {
  const tableContainerRef = useRef(null);

  const columnHelper = createColumnHelper();

  const columns = _.map(props.headers, (header) => {
    return columnHelper.accessor(header, {
      header: ({}) => {
        if (viewOriginal) {
          return header;
        }
        return _.find(props.columnDetails, { name: header }).title;
      },
      id: header,
      cell: ({ row, cell }) => {
        return (
          <TableCell key={cell.id} row={row.original} column={cell.column.id} isOriginalData={props.viewOriginal} />
        );
      },
    });
  });

  const table = useReactTable({
    data: props.tableData,
    columns,
    getExpandedRowModel: getExpandedRowModel(),
    getCoreRowModel: getCoreRowModel(),
  });

  const { rows } = table.getRowModel();

  const rowVirtualizer = useVirtualizer({
    count: props.tableData.length,
    getScrollElement: () => tableContainerRef.current,
    estimateSize: () => 80,
    overscan: 10,
  });

  const renderEmpty = () => {
    return (
      <table>
        {renderColumnHeaders()}
        <tbody>{_.times(15, (i) => generateEmptyRow(i))}</tbody>
      </table>
    );
  };

  const generateEmptyRow = (index) => {
    return (
      <tr key={index} className="data-table__row">
        <td colSpan={props.headers.length} />
      </tr>
    );
  };

  const renderData = () => {
    if (props.tableData.length > 0) {
      return (
        <div ref={tableContainerRef} className="virtual__table__claims-output__container">
          <div style={{ height: `${rowVirtualizer.getTotalSize()}px` }}>
            <table>
              {renderColumnHeaders()}
              <tbody>{renderVirtualRows()}</tbody>
            </table>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <table>{renderColumnHeaders()}</table>
          <EmptyFilters type={props.type} />
        </div>
      );
    }
  };

  const renderColumnHeaders = () => {
    return (
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr className="virtual__table__sticky-header" key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              if (["claim_conforms_flag", "formatted_rx_number"].includes(header.id)) {
                return;
              }

              return (
                <HeaderCell
                  key={header.id}
                  header={header}
                  title={props.viewOriginal ? header.id : props.columnDetails.find((h) => h.name === header.id)?.title}
                  viewOriginal={props.viewOriginal}
                  warnings={props.columnMappingWarnings}
                  mapping={props.mapping}
                />
              );
            })}
          </tr>
        ))}
      </thead>
    );
  };

  const renderVirtualRows = () => {
    return rowVirtualizer.getVirtualItems().map((virtualRow, index) => {
      const row = rows[virtualRow.index];

      return (
        <tr
          key={row.id}
          className="data-table__row"
          style={{
            height: `${virtualRow.size}px`,
            transform: `translateY(${virtualRow.start - index * virtualRow.size}px)`,
          }}
        >
          {renderCells(row)}
        </tr>
      );
    });
  };

  const renderCells = (row) => {
    return row
      .getVisibleCells()
      .filter((cell) => {
        return !["formatted_rx_number", "claim_conforms_flag"].includes(cell.column.id);
      })
      .map((cell) => {
        // const style = !inValid ? "data-table__row__item" : "data-table__row__item data-table__row__item--invalid";

        return flexRender(cell.column.columnDef.cell, cell.getContext());
      });
  };

  if (!props.tableData.length) {
    return renderEmpty();
  }

  return renderData();
};

export default SubmissionTable;
