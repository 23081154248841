import React from "react";
import Button from "./Button";

const ToggleButton = ({ buttons, activeButton, setActiveButton }) => {
  return (
    <div className="btn__option__group btn__option__group--mb btn__option__group--p-5">
      {buttons.map((btn) => (
        <Button
          variant="link"
          key={btn}
          className={`${
            btn === activeButton
              ? "btn btn--sm btn--even-width btn--selected"
              : "btn btn--sm btn--even-width"
          }`}
          onClick={() => setActiveButton(btn)}
        >
          {btn}
        </Button>
      ))}
    </div>
  );
};

export default ToggleButton;
