import React, { useState, useEffect, useRef } from "react";

import { FilterList } from "@material-ui/icons";

const filterOptions = {
  originalView: [
    { label: "Display All", filter: "all" },
    { label: "Display only rows with errors", filter: "errors" },
    { label: "Display only rows selected for submission", filter: "selected" },
    { label: "Display only rows not selected for submission", filter: "not_selected" },
  ],
  submissionView: [
    { label: "Display All", filter: "all" },
    { label: "Display only rows with errors", filter: "errors" },
  ],
};

const SubmissionTableFilter = ({ filter, setFilter, viewOriginal }) => {
  const [open, setOpen] = useState(false);
  const filterRef = useRef();
  const options = viewOriginal ? filterOptions.originalView : filterOptions.submissionView;

  useEffect(() => {
    function handleClickOutside(event) {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  useEffect(() => {
    setFilter("all");
  }, [viewOriginal]);

  const handleClick = (option) => {
    setFilter(option.filter);
  };

  const renderOption = (option, index) => {
    if (option.filter === filter) {
      return (
        <div
          key={index}
          className="filterbox__menu__item filterbox__menu__item--selected"
          onClick={() => handleClick(option)}
        >
          <div>{option.label}</div>
          <div className="filterbox__menu__item__selected__icon">
            <div className="solid solid-budicon-check-ui" />
          </div>
        </div>
      );
    } else {
      return (
        <div className="filterbox__menu__item" key={index} onClick={() => handleClick(option)}>
          {option.label}
        </div>
      );
    }
  };

  const renderOptions = () => {
    if (open) {
      return <div className="filterbox__menu">{options.map((option, i) => renderOption(option, i))}</div>;
    } else {
      return null;
    }
  };

  return (
    <div
      className={`filterbox__container filterbox--ml ${open && "filterbox__container--open"}`}
      style={{ width: "47px" }}
      ref={filterRef}
      onClick={() => setOpen(!open)}
    >
      <FilterList fontSize="small" />
      {renderOptions()}
    </div>
  );
};

export default SubmissionTableFilter;
