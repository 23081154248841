import React, { useState, useRef, useEffect } from "react";
import _ from "lodash";
import PharmacySelect from "./PharmacySelect";
import TextField from "@material-ui/core/TextField";
import { withStyles, makeStyles } from "@material-ui/core/styles";

const CustomTextField = withStyles({
  root: {
    backgroundColor: "transparent",
    borderRadius: 8,
    "& label.Mui-focused": {
      color: "#3D71FF",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiFormHelperText-root": {
      width: "100%",
      fontSize: 12,
      margin: "10px 0 0 0",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
      backgroundColor: "#ffffff",
      "& fieldset": {
        borderColor: "#DEE5EB",
      },
      "&:hover fieldset": {
        borderColor: "#DEE5EB",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#3D71FF",
      },
    },
  },
})(TextField);

const ImidDesignationForm = (props) => {
  const [specialtyDesignation, setSpecialtyDesignation] = useState(false);
  const [confirmedPolicy, setConfirmedPolicy] = useState(false);
  const [hasSelectedPharmacy, setHasSelectedPharmacy] = useState(false);
  const [primaryContactEmail, setPrimaryContactEmail] = useState(null);
  const [secondaryContactEmail, setSecondaryContactEmail] = useState(null);
  const [selectedPharmacy, setSelectedPharmacy] = useState(false);

  const invalidEmailDomains = ["gmail", "outlook", "yahoo", "hotmail"];

  useEffect(() => {
    var formDetails = _.cloneDeep(props.manufacturerFormDetails);

    // set form details for completed status - obj name as class
    formDetails[props.manufacturer].specialtyDesignationForm = {
      open: specialtyDesignation,
      completed: hasSelectedPharmacy,
    };
    props.setManufacturerFormDetails(formDetails);
  }, [specialtyDesignation, hasSelectedPharmacy]);

  useEffect(() => {
    if (primaryContactEmail) {
      if (isInvalidEmail()) {
        setConfirmedPolicy(false);
      } else {
        setConfirmedPolicy(true);
      }
    }
  }, [primaryContactEmail]);

  const reset = () => {
    if (hasSelectedPharmacy) {
      setHasSelectedPharmacy(false);
    }

    if (primaryContactEmail) {
      setPrimaryContactEmail(null);
      secondaryContactEmail(null);
    }
  };

  const isInvalidEmail = () => {
    if (primaryContactEmail) {
      if (
        primaryContactEmail.includes("@") &&
        !_.map(invalidEmailDomains, (domain) => primaryContactEmail.includes(domain)).includes(true)
      ) {
        return false;
      } else {
        return true;
      }
    }

    if (secondaryContactEmail) {
      if (
        secondaryContactEmail.includes("@") &&
        !_.map(invalidEmailDomains, (domain) => secondaryContactEmail.includes(domain)).includes(true)
      ) {
        return false;
      } else {
        return true;
      }
    }
  };

  const notPermitted = () => {
    switch (props.manufacturer) {
      case "bristol_myers_squibb":
        return (
          <div
            className={
              confirmedPolicy
                ? "designation_form__section__group__step__toggle_container designation_form__section__group__step__toggle_container--closed"
                : "designation_form__section__group__step__toggle_container"
            }
          >
            <div className="stat-cards__confirmation stat-cards__confirmation--disabled">
              <span>Please reach out to support at BMS340B@bms.com for assistance.</span>
              {renderNotPermitted()}
            </div>
          </div>
        );
    }
  };

  const renderNotPermitted = () => {
    return (
      <div className="stat-cards__confirmation__footer">
        <span>
          <div className="indicator indicator--red indicator--pulse" style={{ marginTop: 3 }}></div>
          Contact support
        </span>
      </div>
    );
  };

  const policies = () => {
    if (props.isBlocked) {
      reset();
      return notPermitted();
    }

    return (
      <div>
        <div
          className="stat-cards__confirmation stat-cards__confirmation--disabled"
          style={{ maxHeight: 250, overflowY: "scroll" }}
        >
          <div className="designation_form__section__group__step__subtitle" style={{ marginTop: 5, color: "#718291" }}>
            IMiD DISTRIBUTION THROUGH THIRD PARTY PHARMACIES SUBMITTED DATA TERMS OF USE
            <br />
            <br />
            1. Purchaser. These Terms of Use apply to data provided by an entity ("Purchaser") that is not part of the
            limited dispensing network for Revlimid®, Pomalyst®, and/or Thalomid® (separately and collectively "IMiDs"),
            when, by special exception granted by BMS, it elects to dispense through a third-party pharmacy that is part
            of the IMiD limited dispensing network ("Contract Pharmacy").
            <br />
            <br />
            2. Data Submission Start Date. A Purchaser that designates an IMiD-dispensing Contract Pharmacy will submit
            claims level data (as defined in Section 5) to Bristol Myers Squibb Company ("BMS"), beginning 10-15 days
            after the Contract Pharmacy designation process is completed ("Designation Effective Date").
            <br />
            <br />
            3. HIPAA Compliance. To the extent applicable, BMS shall comply with all provisions of the Health Insurance
            Portability and Accountability Act of 1996 relating to the privacy and security of individually identifiable
            health information, and all regulations adopted in connection therewith (collectively, "HIPAA"). Purchaser
            discloses the data elements listed in Section 5 to BMS for payment and healthcare operations purposes in
            compliance with HIPAA and guidance issued by the Department of Health and Human Services. Nothing in these
            Terms of Use should be construed as creating a Business Associate relationship between Purchaser and BMS.
            BMS will maintain information it receives from Purchaser in accordance with these Terms of Use, its
            policies, and applicable law. BMS will not attempt to re-identify individuals who are the subject of the
            data elements provided.
            <br />
            <br />
            4. Confidentiality. Purchaser will utilize BMS's designated point-to-point encryption process, to ensure
            transmission confidentiality. BMS will use commercially reasonable security measures to protect data
            received pursuant to these Terms of Use. BMS will maintain in confidence and not disclose to third parties
            any non-public information it may receive as a result of the submission of claims level data by Purchaser,
            except as may be required by law or these Terms of Use. BMS may disclose the data received under these Terms
            of Use to its contractors performing services to BMS so long as such contractors are under obligations of
            confidentiality similar to those in these Terms of Use.
            <br />
            <br />
            5. Claims Level Data. Purchaser will provide the following data elements with each order:
            <br />
            <br />
            <ul>
              <li style={{ color: "#718291" }}>340B ID number,</li>
              <li style={{ color: "#718291" }}>Purchaser Name,</li>
              <li style={{ color: "#718291" }}>Prescription ID number,</li>
              <li style={{ color: "#718291" }}>Dispense Date,</li>
              <li style={{ color: "#718291" }}>NDC-11,</li>
              <li style={{ color: "#718291" }}>Quantity,</li>
              <li style={{ color: "#718291" }}>Contract dispensing pharmacy name,</li>
              <li style={{ color: "#718291" }}>Contract dispensing pharmacy HIN,</li>
              <li style={{ color: "#718291" }}>Contract dispensing pharmacy DEA number.</li>
            </ul>
            BMS will use the claims level data to determine whether the IMiD Contract Pharmacy order complies with BMS’
            distribution practices.
            <br />
            <br />
            QUESTIONS:
            <br />
            <br />
            Mail:
            <br />
            <br />
            Bristol Myers Squibb Company
            <br />
            Attn: Director, Medicaid and PHS Operations
            <br />
            3401 Princeton Pike, Lawrenceville, NJ 08648
            <br />
            Email: bms340B@bms.com
            <br />
            <br />
            <br />
            Highly confidential, not subject to distribution or disclosure under FOIA
          </div>
        </div>
      </div>
    );
  };

  const renderDesignationQuestion = () => {
    switch (props.manufacturer) {
      case "bristol_myers_squibb":
        return `Would you like to make a single contract pharmacy designation for ${props.manufacturerName}'s IMiD products?`;
    }
  };

  const renderSpecialtyDesignationForm = () => {
    return (
      <div className="designation_form__section__group">
        <div className="designation_form__section__group__row">
          <div className="designation_form__section__group__question">
            <div className="designation_form__section__group__question__subtitle">Designation for IMiD Products</div>
            {renderDesignationQuestion()}
          </div>
          <div className="designation_form__section__group__value">
            <div className="btn__option__group">
              <div
                className={specialtyDesignation ? "btn btn--small btn--selected" : "btn btn--small"}
                onClick={() => setSpecialtyDesignation(true)}
              >
                yes
              </div>
              <div
                className={specialtyDesignation ? "btn btn--small" : "btn btn--small btn--selected"}
                onClick={() => setSpecialtyDesignation(false)}
              >
                no
              </div>
            </div>
          </div>
        </div>
        {renderPolicy()}
        {renderEmailContact()}
        {renderStep()}
      </div>
    );
  };

  const renderStepIndicator = (step) => {
    if (step) {
      return (
        <div className="designation_form__section__group__step__indicator designation_form__section__group__step__indicator--complete">
          <i className="solid solid-budicon-check-ui" />
        </div>
      );
    } else {
      return <div className="designation_form__section__group__step__indicator"></div>;
    }
  };

  const renderPolicy = () => {
    if (specialtyDesignation) {
      return (
        <div className="designation_form__section__group__step">
          <div className="designation_form__section__group__step__indicator designation_form__section__group__step__indicator--complete">
            <i className="solid solid-budicon-check-ui" />
          </div>
          <div className="designation_form__section__group__step__title">Terms of Use</div>
          <div className="designation_form__section__group__step__subtitle"></div>
          {policies()}
        </div>
      );
    }
  };

  const renderEmailContact = () => {
    if (props.isBlocked) {
      reset();
      return false;
    }

    if (specialtyDesignation) {
      return (
        <div className="designation_form__section__group__step">
          {renderStepIndicator(confirmedPolicy)}
          <div className="designation_form__section__group__step__title">IMiDs Rx Data Submission Email</div>
          <div className="designation_form__section__group__step__subtitle">Please provide contact details.</div>
          <div className="stat-cards__confirmation stat-cards__confirmation--disabled">
            <div
              className="designation_form__section__group__step__subtitle"
              style={{ marginTop: 5, color: "#718291" }}
            >
              Please provide the email address of the person who will submit IMiDs Rx data on behalf of the 340B covered
              entity. BMS will register this email and provide BMS IMiDs Rx Data Collection template and Instructions to
              this email address. If the contact or email address provided in this form changes at any point in the
              future, you must notify support@340besp.com.
            </div>
            <CustomTextField
              label="Enter email address"
              margin="normal"
              variant="outlined"
              id="email"
              name="external_contact_email"
              type="email"
              fullWidth
              required
              error={isInvalidEmail()}
              helperText={
                isInvalidEmail()
                  ? "Email is not valid - Please provide a valid Work Email Address for this covered entity."
                  : null
              }
              onChange={(event) => {
                setPrimaryContactEmail(event.target.value);
              }}
            />
            <CustomTextField
              label="Enter secondary email address (optional)"
              margin="normal"
              variant="outlined"
              id="secondary_contact_email"
              name="secondary_contact_email"
              type="email"
              fullWidth
              error={isInvalidEmail()}
              helperText={
                isInvalidEmail()
                  ? "Email is not valid - Please provide a valid Work Email Address for this covered entity."
                  : null
              }
              onChange={(event) => {
                setSecondaryContactEmail(event.target.value);
              }}
            />
          </div>
        </div>
      );
    }
  };

  const renderNoticeHasExistingDesignation = (entity) => {
    var designation = entity.designations_count[props.manufacturer];

    if (designation && designation[props.specialtyKeyName] > 0) {
      return (
        <div className="stat-cards__notice stat-cards__notice--blue stat-cards__notice--mb" style={{ lineHeight: 1.5 }}>
          The selected covered entity already made a contract pharmacy designation, please login to or register an
          account with 340B ESP to view the details of this designation. If you have questions, please reach out to
          support@340besp.com
        </div>
      );
    }
  };

  const filterPharmacies = (entity) => {
    return _.filter(entity.pharmacies.details, { [props.manufacturer]: [props.specialtyKeyName] });
  };

  const renderPharmacySelect = () => {
    if (props.isBlocked) {
      reset();
      return false;
    }

    // hasSelectedSpecialtyPharmacy is passed in to render notice
    // You can't select two specialty pharmacies across designation kinds per manufacturer

    // retailSelectedSpecialtyPharmacy for duplicate input values if single designation has selected specialty CP

    return _.map(props.entities, (entity, index) => (
      <div key={index}>
        {renderNoticeHasExistingDesignation(entity)}
        <PharmacySelect
          kind={props.specialtyKeyName}
          entity={entity}
          pharmacies={filterPharmacies(entity)}
          manufacturer={props.manufacturer}
          disableGranteeNotice={true}
          disableWhollyOwnedNotice={true}
          disableInHouseSpecialtyNotice={true}
          disableInHouseNotice={true}
          displayImidPharmacyList={true}
          externalEmailContact={primaryContactEmail}
          secondaryContactEmail={secondaryContactEmail}
          selectedPharmacy={selectedPharmacy}
          setSelectedPharmacy={setSelectedPharmacy}
          setHasSelectedPharmacy={setHasSelectedPharmacy}
          retailSelectedSpecialtyPharmacy={props.retailSelectedSpecialtyPharmacy}
          retailHasSelectedSpecialtyPharmacy={props.retailHasSelectedSpecialtyPharmacy}
        />
      </div>
    ));
  };

  const renderStep = () => {
    if (specialtyDesignation && confirmedPolicy) {
      return (
        <div className="designation_form__section__group__step">
          {renderStepIndicator(hasSelectedPharmacy)}
          <div className="designation_form__section__group__step__title">Select Contract Pharmacy</div>
          <div className="designation_form__section__group__step__subtitle">
            Please select a contract pharmacy to designate.
          </div>
          {renderPharmacySelect()}
        </div>
      );
    }
  };

  return renderSpecialtyDesignationForm();
};

export default ImidDesignationForm;
