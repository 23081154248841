import React, { useState, useEffect } from "react";
import verifyImage from "~/images/verify_account_illustration.png";

const SuccessModal = (props) => {
  const [displayModal, setDisplayModal] = useState(true);

  const renderModal = () => {
    if (displayModal) {
      return (
        <div className="modal__background">
          <div
            className="modal__body modal__body--no-side-padding animated fadeInUp"
            style={{ paddingTop: 30, paddingBottom: 30 }}
          >
            <div className="modal__body__title">Success!</div>
            <div className="modal__body__subtitle" style={{ marginBottom: 30 }}>
              You have successfully submited a wholly owned contract pharmacy exemption.
              <br />
              <br />
              Your application is currently in review. You will receive additional emails when your application status
              changes.
            </div>
            <div className="modal__body__spotlight">
              <img src={verifyImage} width={220} />
            </div>
            <div className="modal__body__container">
              <div className="modal__body__container__note">
                Please download a copy of you signed agreement below.
                <br />
                <br />
                If you have any question please contact our support team at - 1.888.398.5520 or via email
                support@340besp.com
              </div>
            </div>
            <div className="modal__body__footer">
              <a href="/">
                <div className="btn btn--white btn--outline">Done</div>
              </a>
              <a href={props.downloadPath} style={{ display: "flex", flexGrow: 1 }}>
                <div className="btn" style={{ flexGrow: 1, marginLeft: 30 }}>
                  Download Signed Agreement
                </div>
              </a>
            </div>
          </div>
        </div>
      );
    }
  };

  return renderModal();
};
export default SuccessModal;
