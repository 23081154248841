import React from "react";

const ColumnMappingNotice = ({ isVisible }) => {
  if (!isVisible) {
    return null;
  }

  return (
    <div className="notify__banner">
      <div className="notify__banner__icon">
        <i className="solid solid-budicon-notification"> </i>
      </div>
      <div className="notify__banner__notice__title">
        <strong>Missing Column Mapping</strong>
      </div>
      <div className="notify__banner__notice">Please create create a column mapping.</div>
    </div>
  );
};

export default ColumnMappingNotice;
