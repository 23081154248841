import React, { useState, useRef, useEffect } from "react";
import _ from "lodash";
import Exelixis from "~/images/Exelixis.png";

const AgreementExelixis = (props) => {
  const renderAgreement = () => {
    if (props.selectedManufacturer == "exelixis") {
      return (
        <div>
          <div className="logo__container">
            <div className="logo">
              <img src={Exelixis} />
            </div>
            <div className="logo__info">
              <div className="logo__info__title">Exelixis</div>
              <div className="logo__info__subtitle">Wholly Owned Contract Pharmacy Form</div>
            </div>
          </div>
          <p>
            Contract pharmacies that are wholly-owned by, or under common ownership with, a covered entity and that
            dispense products only to patients of the covered entity will be subject to Exelixis' 340B contract pharmacy
            program integrity initiative. These pharmacies must be registered within the HRSA database as a contract
            pharmacy of the 340B covered entity and will need to submit limited claims-level data for COMETRIQ® and
            CABOMETYX® through 340B ESP™ for all utilization dispensed through their wholly owned contract pharmacy.
            Covered entities submitting limited claims-level data will need to register an account with 340B ESP™.
          </p>
          <p>
            Covered entities with contract pharmacies that meet this criteria can self-identify in the 340B ESP™
            platform, by visiting https://www.340besp.com/wholly_owned_application. Proof of ownership documents are
            required to complete the process. Examples of proof of ownership documents include:
          </p>
          <ul>
            <li>Certificate of liability insurance</li>
            <li>State business licenses</li>
          </ul>
          <p>
            Wholly Owned Contract Pharmacy Designations will be processed within five (5) business days. Please note
            that failure to populate all required fields will delay the processing of Wholly Owned Contract Pharmacy
            designations. Designations will be valid for twelve (12) months from approval date and after the initial
            12-month period, Exelixis may require covered entities to resubmit proof of ownership documents to validate
            the continued eligibility of existing designations.
          </p>
          <p>
            By signing this form, the authorized representative of the covered entity certifies that the covered entity
            and contract pharmacy(ies) included in this form: (i) have the same corporate ownership, OR (ii) that the
            covered entity wholly owns the identified contract pharmacy(ies); AND (iii) the covered entity qualifies for
            the designation as described in this form. If there are any changes to the ownership structure of any of the
            contract pharmacies identified in this form, the covered entity agrees to notify Exelixis at
            support@340besp.com within five (5) business days of such change of ownership.
          </p>
          <div className="register__divider" />
        </div>
      );
    } else {
      return null;
    }
  };

  return renderAgreement();
};

export default AgreementExelixis;
